import React from 'react';
import './FormTitle.scss';
import { useNavigate } from 'react-router-dom';

interface PropTypes {
  title: string;
  icon: string;
}

const FormTitle = (props: PropTypes) => {
  const { icon, title } = props;
  const navigate = useNavigate();
  return (
    <div className="order-title-container">
      <img
        className="order-icon"
        src={icon}
        alt="icon"
        onClick={() => navigate(-1)}
      />
      <div className="order-title">{title}</div>
    </div>
  );
};

export default FormTitle;
