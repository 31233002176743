import React, { useMemo } from 'react';
import './SummaryTotal.scss';
import { IConfig } from '@/entities/Config/config.model';
import { withConfig } from '@/shared/hoc/withConfig';
import { formatNumber } from '@/shared/helpers/formatNumber';

interface PropTypes {
  subtotal: number;
  tax?: number;
  taxPercentage?: number;
  total?: number;
  freePosition?: boolean;
  config: IConfig;
}

const SummaryTotal = (props: PropTypes) => {
  const { subtotal, tax, total, freePosition, taxPercentage } = props;
  const { config } = props;
  const calculated = useMemo(() => {
    return {
      subtotal: subtotal,
      tax: tax ? tax : (subtotal * config?.tax) / 100,
      taxPercentage: taxPercentage ? taxPercentage : config?.tax,
      total: total
        ? total
        : Math.floor(subtotal + (subtotal * config?.tax) / 100),
    };
  }, [config, subtotal, tax, total]);

  return (
    <div className="summary-total">
      <div className="summary-item">
        <div className="summary-label">Subtotal</div>
        <div className="summary-data">
          SAR {freePosition ? 0 : formatNumber(calculated.subtotal)}
        </div>
      </div>
      <div className="summary-item">
        <div className="summary-label">
          Tax <b>{freePosition ? 0 : calculated.taxPercentage}%</b>
        </div>
        <div className="summary-data">
          SAR {freePosition ? 0 : formatNumber(calculated.tax)}
        </div>
      </div>
      <div className="summary-item">
        <div className="summary-label">Total</div>
        <div className="summary-data data-total">
          SAR {freePosition ? 0 : formatNumber(calculated.total)}
        </div>
      </div>
    </div>
  );
};

export default withConfig(SummaryTotal);
