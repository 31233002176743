import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styles from './OrderFilters.module.scss';
import { InputLabel } from '@mui/material';
import FormInput from '@/shared/components/Input/FormInput/FormInput';
import { useGetOrganizationsQuery } from '@/entities/Organization/api/organizationApi';
import { IOrderStatusesTypes } from '@/entities/Order/order.models';
import { MultiSelect } from '@/shared/components/Select/MultiSelect/MultiSelect';
import { useGetAdminsQuery } from '@/entities/Admin/api/adminApi';

export const initialSearchQuery = {
  genericSearch: '',
  organization: [] as string[],
  relationshipManager: [] as number[],
  status: [] as string[],
};

export type FiltersFormData = {
  genericSearch: string;
  organization: string[];
  relationshipManager: number[];
  status: string[];
};

type Props = {
  handleInputChange: (value: string) => void;
  handleOrganizationChange: (values: string[]) => void;
  handleRelationshipManagerChange: (value: number[]) => void;
  handleStatusChange: (value: string[]) => void;
};

export const OrderFilters = ({
  handleInputChange,
  handleOrganizationChange,
  handleRelationshipManagerChange,
  handleStatusChange,
}: Props) => {
  const { control, watch } = useForm<FiltersFormData>({
    defaultValues: initialSearchQuery,
  });

  const { data: orgData } = useGetOrganizationsQuery({
    take: 100,
    page: 1,
  });

  const orgOptions = orgData
    ? orgData.data.map((org) => ({
        label: org.name,
        value: org.name,
      }))
    : [];

  const { data: adminData } = useGetAdminsQuery({
    take: 100,
    page: 1,
  });

  const adminOptions = adminData
    ? adminData.data.map((admin) => ({
        label: admin.name,
        value: admin.id,
      }))
    : [];

  const organization = watch('organization') || [];
  const relationshipManager = watch('relationshipManager') || [];
  const status = watch('status') || [];

  useEffect(() => {
    handleOrganizationChange(organization);
  }, [organization]);

  useEffect(() => {
    handleStatusChange(status);
  }, [status]);

  useEffect(() => {
    handleRelationshipManagerChange(relationshipManager);
  }, [relationshipManager]);

  return (
    <>
      <hr className={styles.hr} />
      <div className={styles.container}>
        <div className={styles.wrapInput}>
          <InputLabel className={styles.label}>Search Order</InputLabel>
          <FormInput
            type="search"
            id="search-form"
            placeholder="Search"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.value)
            }
          />
        </div>
        <div className={styles.wrapInput}>
          <InputLabel className={styles.label}>Organisation</InputLabel>
          <MultiSelect
            control={control}
            name="organization"
            selectOptions={orgOptions}
            label="Please select"
          />
        </div>

        <div className={styles.wrapInput}>
          <InputLabel className={styles.label}>Relationship Manager</InputLabel>
          <MultiSelect
            control={control}
            name="relationshipManager"
            selectOptions={adminOptions}
            label="Please select"
          />
        </div>

        <div className={styles.wrapInput}>
          <InputLabel className={styles.label}>Status</InputLabel>
          <MultiSelect
            control={control}
            name="status"
            selectOptions={IOrderStatusesTypes}
            label="Please select"
          />
        </div>
      </div>
      <hr className={styles.hr} />
    </>
  );
};
