import TemplatePage from '@/pages/TemplatePage/TemplatePage';
import React from 'react';
import { CreateContactForm } from '../CreateContactForm/CreateContactForm';

export const CreateContact = () => {
  return (
    <TemplatePage>
      <CreateContactForm />
    </TemplatePage>
  );
};
