import React from 'react';
import './BranchInput.scss';

interface PropTypes {
  label?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  error?: string;
}

const StatusInput = (props: PropTypes) => {
  const { label, placeholder, onChange, value, error } = props;

  return (
    <div className="status_enum">
      <select onChange={(e) => onChange && onChange(e)} value={value || ''}>
        <option value="" disabled hidden>
          {placeholder}
        </option>
        <option value="super">Product Manager</option>
        <option value="ordinary">Relationship Manager</option>
        <option value="accountant">Financial Controller</option>
      </select>
      <label>{label}</label>
      {error && <div className="branch-error">{error}</div>}
    </div>
  );
};

export default StatusInput;
