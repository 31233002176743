import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../shared/api/api';
import { IDataWithPagination, IOrder } from '../Order/order.models';
import { RootState } from '../../store/store';
import { buildUrlParams } from '../transactEntities/Payment/helper';

export type RefundItem = {
  SKU: number;
  name: string;
  positionsAmount: number;
  refundableAmount: number;
  type: string;
  pricePerUnit: number;
  skuName: string;
};

export type RefundPolicy = 'Payout' | 'HoldOnBalance';

export enum RequestStatuses {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  DECLINED = 'Declined',
}

export enum CancelationReason {
  CANCELED_BY_CLIENT = 'CanceledByClient',
  POSITION_UNFULFILLED = 'PositionUnfulfilled',
}

export type ICancelationRequest = {
  refundItems: RefundItem[];
  createdAt: string;
  updatedUp: string;
  id: number;
  refundPolicy: RefundPolicy;
  refundableAmount: number;
  status: RequestStatuses;
  reason: CancelationReason;
  order?: IOrder;
};

export const cancelationRequestApi = createApi({
  reducerPath: 'cancelationApi',
  tagTypes: ['Refunds'],
  baseQuery: fetchBaseQuery({
    ...baseQueryConfig,
    baseUrl: baseQueryConfig.baseUrl + '/refunds',
    prepareHeaders: async (headers: Headers, { getState }) => {
      // If we have a token set in state, let's assume that we should be passing it.
      const token = await (getState() as RootState).user.user?.getIdToken();
      token && headers.set('Authorization', token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCancelationRequests: builder.query<
      IDataWithPagination<ICancelationRequest>,
      { relations?: string[]; page: number; take: number }
    >({
      query: ({ relations = [], page, take }) => ({
        url: '',
        params: {
          ...(relations.length ? { relations } : {}),
          page,
          take,
        },
      }),
      providesTags: ['Refunds'],
    }),

    getCancelationRequestsWithRelation: builder.query<
      any,
      { relations?: string[]; status?: string[]; sort: string }
    >({
      query: ({ relations = [], status, sort }) => {
        const urlParams = buildUrlParams(relations, status, sort);
        const url = `${urlParams}`;
        return { url };
      },
      providesTags: ['Refunds'],
    }),

    getCanсelationRequestWithFilters: builder.query<
      IDataWithPagination<ICancelationRequest>,
      {
        sort?: string;
        take?: number;
        page?: number;
        status?: string;
        orgId: number;
      }
    >({
      providesTags: ['Refunds'],
      query: ({ sort, take, page, status, orgId }) =>
        `?organization.id=${orgId}${
          (sort ? '&sort=' + sort : '') +
          (take ? '&take=' + take : '') +
          (page ? '&page=' + page : '') +
          (status ? `&status=${status}` : '')
        }`,
    }),

    getCancelationRequestById: builder.query<ICancelationRequest, number>({
      query: (id) => ({
        url: `/${id}?relations=order`,
      }),
      providesTags: ['Refunds'],
    }),

    declineCancelationRequest: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/${id}/decline`,
        method: 'POST',
      }),
      invalidatesTags: ['Refunds'],
    }),

    approveCancelationRequest: builder.mutation<
      void,
      { id: number; refundPolicy: string; file?: File }
    >({
      query: ({ id, refundPolicy, file }) => {
        const formData = new FormData();
        formData.append('refundPolicy', refundPolicy);
        if (refundPolicy === 'Payout' && file) {
          formData.append('file', file);
        }

        return {
          url: `/${id}/approve`,
          method: 'POST',
          body: formData,
          headers: {},
        };
      },
      invalidatesTags: ['Refunds'],
    }),
    updateCancelationRequest: builder.mutation<
      void,
      { id: number; paymentMethod: string; file: File }
    >({
      query: ({ id, paymentMethod, file }) => {
        const formData = new FormData();
        formData.append('paymentMethod', paymentMethod);
        formData.append('file', file);
        return {
          url: `/${id}/upload-doc`,
          method: 'POST',
          body: formData,
          headers: {},
        };
      },
      invalidatesTags: ['Refunds'],
    }),
    getRefundById: builder.query<
      ICancelationRequest,
      {
        id: string;
        relations?: string[];
      }
    >({
      query: ({ id, relations = [] }) => {
        const urlParams = buildUrlParams(relations);
        const url = `${id}${urlParams}`;
        return { url };
      },
      providesTags: ['Refunds'],
    }),
  }),
});

export const {
  useGetCancelationRequestsQuery,
  useGetCancelationRequestsWithRelationQuery,
  useDeclineCancelationRequestMutation,
  useGetCancelationRequestByIdQuery,
  useApproveCancelationRequestMutation,
  useGetCanсelationRequestWithFiltersQuery,
  useUpdateCancelationRequestMutation,
  useGetRefundByIdQuery,
} = cancelationRequestApi;
