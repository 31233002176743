import React, { useEffect, useState } from 'react';
import TemplatePage from '../TemplatePage/TemplatePage';
import { Info } from './components/Info/Info';
import styles from './Dashboard.module.scss';
import { Stats } from './components/Stats/Stats';
import { OrdersTable } from './components/Stats/components/OrdersTable/OrdersTable';
import { StatisticsTime } from '@/entities/Statistics/models';
import moment from 'moment';
import { getStartAndEndDate } from '@/shared/helpers/getStartAndEndDate';
import { IAdminFireBase } from '@/entities/Admin/admin.models';
import { withAdmin } from '../Organizations/RegisterOrg/withAdmin/withAdmin';

const DashboardCmp: React.FC<{ admin: IAdminFireBase }> = ({ admin }) => {
  const [time, setTime] = useState(StatisticsTime.THIS_WEEK);
  const [startDate, setStartDate] = useState(() =>
    moment().startOf('isoWeek').toISOString(),
  );
  const [endDate, setEndDate] = useState(moment().toISOString());
  const [relationshipManager, setRelationshipManager] =
    useState<IAdminFireBase | null>(admin);

  useEffect(() => {
    const { newStartDate, newEndDate } = getStartAndEndDate(time);

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [time]);

  return (
    <TemplatePage>
      <div className={styles.base}>
        <Info />
        <Stats
          time={time}
          setTime={setTime}
          startDate={startDate}
          endDate={endDate}
          relationshipManager={relationshipManager}
          setRelationshipManager={setRelationshipManager}
        />
        <OrdersTable
          startDate={startDate}
          endDate={endDate}
          relationshipManager={relationshipManager}
        />
      </div>
    </TemplatePage>
  );
};

export const Dashboard = withAdmin(DashboardCmp);
DashboardCmp.displayName = 'DashboardCmp';
