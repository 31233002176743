import { IOrder } from '../../Order/order.models';

export enum PaymentMethod {
  WIRETRANSFER = 'WireTransfer',
  CARDTRANSFER = 'CardPayment',
}

export interface IPayment {
  id?: number;
  amount: number;
  createdAt: Date;
  date: Date;
  status: string;
  paymentMethod: PaymentMethod;
  order: IOrder;
}
