import React from 'react';
import './Stage.scss';

interface PropTypes {
  number: number;
  label: string;
  active?: boolean;
  isBold?: boolean;
  secondLine?: string;
  onClick: () => void;
}
const Stage = (props: PropTypes) => {
  const { number, label, active, isBold, secondLine } = props;
  return (
    <div
      onClick={props.onClick}
      className={`stage ${active ? 'active' : ''} ${
        isBold ? 'bold' : 'not-bold'
      }`}>
      <div className="stage-number-container">
        <div className="stage-number">{number}</div>
      </div>
      <div className={`stage-label ${secondLine ? 'has-second-line' : ''}`}>
        {label}
        <div className="stage-second-line">{secondLine}</div>
      </div>
    </div>
  );
};

export default Stage;
