import React from 'react';
import styles from './RefundsFilter.module.scss';
import { FilterStatus } from '../RefundsFilterBlock';
import { MenuItem, Select } from '@mui/material';

export type ISelectOptions = {
  label: string;
  value: string;
};

type PropTypes = {
  label: string;
  searchQuery: FilterStatus;
  name: string;
  type: 'select';
  options: ISelectOptions[];
  handleInputChange: (value: string, category: string) => void;
};

export const RefundsFilter = (props: PropTypes) => {
  const { label, name, searchQuery, handleInputChange } = props;

  const handleChange = (value: string) => {
    handleInputChange(value, name);
  };

  return (
    <div className={styles.ordersFilter}>
      <div className={styles.ordersFilterLabel}>{label}</div>
      <Select
        style={{ width: '400px' }}
        value={searchQuery[name as keyof FilterStatus]}
        onChange={(e) => {
          handleChange(e.target.value);
        }}>
        <MenuItem value="all">ALL</MenuItem>
        {props.options.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
