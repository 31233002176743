import React from 'react';
import { IOrderCard } from '../../../../entities/Order/order.models';

export const OrderIdContext = React.createContext<{
  productCard: IOrderCard;
  handleChangeCard: (card: IOrderCard) => void;
}>({
  productCard: {
    product: '',
    quantity: 0,
    price: 0,
    interviewShifts: [],
    tags: [],
    ordInv: 0,
    id: 0,
  },
  handleChangeCard: () => {},
});

export const OrderContactLocationStateContext = React.createContext<{
  orgId: number;
  branchId: number;
  freePosition: string;
  positionNumber: number;
}>({
  orgId: 0,
  branchId: 0,
  freePosition: 'no',
  positionNumber: 0,
});
