import React from 'react';
import './ContactUpdateModal.scss';
import { useParams } from 'react-router-dom';
import { useGetContactByIdQuery } from '@/entities/Contact/api/contactApi';
import TemplatePage from '@/pages/TemplatePage/TemplatePage';
import { Skeleton } from '@mui/material';
import { UpdateContactForm } from './UpdateContactForm';

const UpdateContact = () => {
  const { id } = useParams();
  if (!id) {
    return null;
  }

  const { data, isLoading, isSuccess } = useGetContactByIdQuery({ id });

  return (
    <TemplatePage>
      {isLoading && <Skeleton variant="rectangular" height={400} />}
      {isSuccess && <UpdateContactForm data={data} />}
    </TemplatePage>
  );
};

export default UpdateContact;
