import React, { useState, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useCancelOrderWithRefundPolicyMutation,
  useConfirmOrderByAdminMutation,
  useCreateOrderMutation,
  useDeleteOrderMutation,
  useGetOrderByIdWithRelationsQuery,
} from '../../../../entities/Order/api/orderApi';
import { useGetOrganizationsQuery } from '../../../../entities/Organization/api/organizationApi';
import { useGetSkuQuery } from '../../../../entities/SKU/api/SKUApi';

import {
  IOrderCard,
  IOrderSchedule,
} from '../../../../entities/Order/order.models';
import { IOrganization } from '../../../../entities/Organization/org.models';

import TemplatePage from '../../../TemplatePage/TemplatePage';
import OrderTitle from '../../../../shared/components/Title/OrderTitle/OrderTitle';
import BasicOrderButton from '../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import NextOrderButton from '../../../../shared/components/Button/OrderButtons/NextOrderButton/NextOrderButton';
import OrderStagesPanel from '../../../../widgets/Order/OrderStagesPanel/OrderStagesPanel';
import ClientSummary from '../../../../widgets/Order/ClientSummary/ClientSummary';
import TotalQuantity from '../../../../widgets/Order/TotalQuantity/TotalQuantity';
import SummaryTotal from '../../../../widgets/Order/SummaryTotal/SummaryTotal';
import SummaryOrderCard from '../../../../entities/Order/SummaryOrderCard/SummaryOrderCard';

import money from '../../../../assets/icons/orderPage/money.svg';
import {
  defaultIcon,
  mapProductNameToIcon,
} from '../SelectProduct/components/ProductCard/helper';

import './SummaryOrder.scss';
import { CancelModal } from '../CancelModal/CancelModal';
import { RepeatOrderButton } from '../../../../shared/components/RepeatOrderButton/RepeatOrderButton';
import { DEFAULT_DELIVERY_DATE } from './deliveryDate.const';

const convertToIso = (schedule: IOrderSchedule) => {
  if (!schedule.date && schedule.startTime) {
    return schedule;
  }
  const inputDate = `${schedule.date} ${schedule.startTime}`;
  const dateObject = new Date(inputDate);

  let isoString;
  if (!isNaN(dateObject.getTime())) {
    isoString = dateObject.toISOString();
  }

  return {
    startTime: isoString,
    positionsNumber: schedule.positionsNumber,
  };
};

const SummaryOrder = () => {
  const location = useLocation();
  const {
    contactData,
    orderProductCards: orderProductCardsInit,
    currOrderCardList,
    id: orderListId,
  } = location.state || {};
  const {
    selectedOrg: organizationName,
    selectedBranch: branchName,
    selectedContact: contactName,
    freePosition,
  } = contactData || {};

  const { orderStatus } = currOrderCardList || {};

  const [confirmOrderByAdmin] = useConfirmOrderByAdminMutation();

  //initialize the state for product cards (registration flow) and skuToEdit (editing flow)
  const [orderProductCards, setOrderProductCards] = useState<IOrderCard[]>(
    orderProductCardsInit,
  );

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const { isLoading: isSkuLoading } = useGetSkuQuery({});
  const [createOrder, { isLoading: isLoadingCreate }] = useCreateOrderMutation(
    {},
  );
  const [deleteOrder] = useDeleteOrderMutation();
  const [cancelOrder] = useCancelOrderWithRefundPolicyMutation();

  const navigate = useNavigate();
  const { data: orderData } = useGetOrderByIdWithRelationsQuery({
    id: orderListId,
  });

  const orderContact = orderData?.contact;

  const listContactName = `${orderContact?.firstName} ${orderContact?.lastName}`;
  const listBranchName = orderContact?.branch?.name;
  const listOrgName = orderContact?.branch?.organization?.name;
  const listTaxReg = orderContact?.branch?.organization?.taxReg;
  const currentFulfillRate = orderData?.fulfillmentRatePercent;

  const productNames = orderProductCards?.map(
    (card: IOrderCard) => card.product,
  );

  const productNamesString = useMemo(() => {
    return productNames?.join(', ');
  }, [productNames]);

  const { data: orgDataRaw } = useGetOrganizationsQuery({
    relations: ['branches.contacts'],
  });
  const orgData = orgDataRaw?.data;

  const currentOrg = orgData?.find(
    (org: IOrganization) => org.name === organizationName,
  );
  const currentTaxReg = currentOrg?.taxReg;

  const totalCost = orderProductCards.reduce(
    (total, product) => total + product.price * product.quantity,
    0,
  );

  const totalQuantity = orderProductCards?.reduce(
    (total, card) => total + card.quantity,
    0,
  );

  const subtotalPrice = currOrderCardList?.price ?? totalCost;

  const handleCreateOrder = useCallback(async () => {
    const skuRequestsArr = orderProductCards?.map((card: IOrderCard) => {
      const {
        contract,
        quantity,
        interviewShifts,
        id: skuId,
        userNotes,
      } = card;
      const contractType = contract?.contractType;
      const maxSalary = Number(contract?.maxSalary);
      const housing = contract?.isHousing;
      const femalesPercentage = contract?.femalesPercentage;
      const localsPercentage = contract?.localsPercentage;
      const interviewShiftsData = interviewShifts?.map((schedule) =>
        convertToIso(schedule),
      );

      const tags = card.tags?.map(({ id }) => ({ id }));

      return {
        contract: {
          contractType,
          maxSalary,
          isHousing: housing,
        },
        crewsNumber: quantity,
        femalesPercentage,
        localsPercentage,
        contact: contactData.contact.id,
        interviewShifts: interviewShiftsData,
        sku: skuId,
        tags,
        userNotes,
        useFreePositions: freePosition,
      };
    });
    const newOrder = {
      skuRequests: skuRequestsArr,
      contact: contactData.contact.id,
      useFreePositions: freePosition,
    };
    try {
      const { id } = await createOrder(newOrder).unwrap();
      navigate('/order-created', {
        state: {
          orderId: id,
          contactData,
          orderProductCards,
          totalQuantity,
          currOrderCardList,
          freePosition,
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [navigate]);

  const handleDeleteOrder = useCallback(
    (orderId: number) => {
      deleteOrder(orderId)
        .unwrap()
        .then(() => navigate('/orders'));
    },
    [deleteOrder],
  );
  const isEdit = orderListId;

  const toggleCancelModal = () => {
    setIsCancelModalVisible((isCancelModalVisible) => !isCancelModalVisible);
  };

  const handleCancelOrder = useCallback(
    async (refundPolicy: string) => {
      await cancelOrder({ id: orderListId, refundPolicy });
      toggleCancelModal();
    },
    [deleteOrder, toggleCancelModal, orderListId],
  );

  const handleDeleteProductCard = (id: number) => {
    setOrderProductCards((prevCards) => {
      const newProductCards = prevCards?.filter((card) => {
        return card.id !== id;
      });

      return newProductCards;
    });
  };

  const productsSelected = orderProductCards?.reduce((sum, el) => {
    return el.quantity + sum;
  }, 0);
  const stagesData = useMemo(
    () => [
      {
        label: `${productsSelected} Products Selected`,
        secondLine: productNamesString,
        active: true,
        bold: true,
        link: '/select-product',
        state: {
          contactData,
          orderProductCards,
          id: orderListId,
        },
      },
      {
        label: 'Contract & Schedule',
        active: true,
        link: '/contract-schedule',
        state: {
          contactData,
          orderProductCards,
          totalQuantity,
          currOrderCardList,
          id: orderListId,
        },
      },
      { label: 'Review Summary Order', link: '/summary-order', active: true },
    ],
    [productNamesString],
  );

  const taxReg = currentTaxReg || listTaxReg || 0;

  const handleConfirmOrder = useCallback(() => {
    confirmOrderByAdmin({ id: orderListId });
    navigate('/orders');
  }, [orderListId]);

  if (isSkuLoading) return <div></div>;
  return (
    <div className="summary-order-container">
      <TemplatePage>
        <div className="summary-order">
          <div className="summary-order-header">
            <OrderTitle icon={money} title="Summary Order" />
            {currentFulfillRate !== undefined && (
              <div className="summary-order-rate">{`Fulfillment rate: ${currentFulfillRate}%`}</div>
            )}
            <div className="summary-btns">
              {!orderStatus && (
                <BasicOrderButton
                  label="Cancel"
                  onClick={() => navigate('/orders')}
                />
              )}
              <BasicOrderButton
                label="Back"
                onClick={() =>
                  navigate('/contract-schedule', {
                    state: { contactData, orderProductCards },
                  })
                }
              />
              <div className="summary-btns-next">
                {!isEdit && (
                  <NextOrderButton
                    label={'Place order'}
                    onClick={handleCreateOrder}
                    isDisabled={isLoadingCreate}
                  />
                )}
              </div>
            </div>
          </div>
          {!orderStatus && <OrderStagesPanel data={stagesData} />}
          <div className="hr"></div>
          <div className="summary-order-content">
            <div className="summary-client-status">
              <ClientSummary
                contact={contactName || listContactName}
                org={organizationName || listOrgName}
                branch={branchName || listBranchName}
                taxReg={taxReg}
              />
              {!orderProductCards && (
                <div className="summary-status">
                  Status: <span className="status-data">{orderStatus}</span>
                </div>
              )}
              <div className="confirm-order-button">
                {orderStatus === 'CONFIRMED_BY_CLIENT' && (
                  <BasicOrderButton
                    label="Confirm order"
                    isFilled
                    type="button"
                    onClick={handleConfirmOrder}
                  />
                )}
              </div>
              {orderListId && (
                <div className="confirm-order-button">
                  <RepeatOrderButton orderId={orderListId} />
                </div>
              )}
              <div className="delete-order-button">
                <BasicOrderButton
                  label="Delete Order"
                  isFilled
                  onClick={() => handleDeleteOrder(currOrderCardList?.id || 0)}
                />
              </div>
            </div>
            <div className="summary-total-cards">
              <div className="summary-order-cards">
                {orderProductCards?.map((card) => (
                  <SummaryOrderCard
                    orderData={orderData}
                    orderId={orderListId}
                    orderStatus={orderStatus}
                    id={card.id || 0}
                    key={card.id}
                    icon={mapProductNameToIcon[card.product] || defaultIcon}
                    position={card.product}
                    grade={card.grade}
                    price={card.price * card.quantity}
                    deliveryDate={
                      card.deliveryDate
                        ? card.deliveryDate
                        : DEFAULT_DELIVERY_DATE
                    }
                    priceSingle={card.price}
                    finalQuantity={card.quantity}
                    contract={card.contract}
                    femalesPerc={card.femalesPerc}
                    tags={card.tags || []}
                    interviewShifts={card.interviewShifts}
                    contact={contactData.contact}
                    userNotes={card.userNotes}
                    handleDeleteProductCard={(id) =>
                      handleDeleteProductCard(id)
                    }
                  />
                ))}
              </div>
              <TotalQuantity quantity={totalQuantity} />
              <div className="summary-order-footer">
                <SummaryTotal
                  subtotal={subtotalPrice}
                  freePosition={freePosition}
                />
              </div>
            </div>
          </div>
        </div>
      </TemplatePage>
      {isCancelModalVisible && (
        <CancelModal
          isCancelModalVisible={isCancelModalVisible}
          toggleModal={toggleCancelModal}
          handleCancelOrder={handleCancelOrder}
        />
      )}
    </div>
  );
};

export default SummaryOrder;
