import React from 'react';
import './MarketSalaryItem.scss';
import OrderSubtitle from '../../../shared/components/Title/OrderSubtitle/OrderSubtitle';
import star from '../../../assets/icons/orderPage/star.svg';
import { formatNumber } from '@/shared/helpers/formatNumber';

interface PropTypes {
  icon?: string;
  position: string;
  grade?: number;
  minimumAsk?: number;
  averageAsk?: number;
}

const MarketSalaryItem = (props: PropTypes) => {
  const { icon, position, grade, minimumAsk, averageAsk } = props;
  return (
    <div className="market-salary-item">
      <div className="item-content">
        {icon && <img src={icon} className="card-icon" alt="icon" />}
        <div className="item-info">
          <div className="item-info-person">
            <OrderSubtitle title={position} />
          </div>
          <div className="item-info-details">
            <div className="item-star">
              <img src={star} alt="*" />
              <div className="star-label">{grade} Stars</div>
            </div>
          </div>
        </div>
      </div>
      <div className="item-salaries">
        <div className="item-minimum-ask">
          <div>Minimum Ask</div>
          <div className="minimum-ask-amount ask-amount">
            SAR {formatNumber(minimumAsk ? minimumAsk : 0)}
          </div>
        </div>
        {averageAsk && (
          <div className="item-average-ask">
            <div>Average Ask</div>
            <div className="average-ask-amount ask-amount">
              SAR {formatNumber(averageAsk)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketSalaryItem;
