import { AdminTypes, IAdmin } from '@/entities/Admin/admin.models';
import React, { useState } from 'react';
import { AdminTypeToTile, YupSchemaType, adminYupSchema } from '../../helper';
import EmailLink from '@/shared/components/Link/EmailLink/EmailLink';
import SectionButton from '@/shared/components/Button/SectionButton/SectionButton';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  useDeleteAdminMutation,
  useUpdateAdminMutation,
} from '@/entities/Admin/api/adminApi';
import { yupResolver } from '@hookform/resolvers/yup';
import StatusInput from '@/shared/components/Input/BranchInput/StatusInput';
import BranchInput from '@/shared/components/Input/BranchInput/BranchInput';
import PhoneInput from 'react-phone-input-2';
import styles from './AdminEditForm.module.scss';

export type Props = {
  admin: IAdmin;
};

export const AdminEditForm = ({ admin }: Props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [updateAdmin] = useUpdateAdminMutation();
  const [deleteAdmin] = useDeleteAdminMutation();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(adminYupSchema),
    defaultValues: {
      name: admin.name,
      status: admin.adminType,
      email: admin.email,
      phone: admin.phone,
    },
  });

  const handleDeleteAdmin = () => {
    deleteAdmin(admin.id);
  };

  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (formData) => {
    updateAdmin({
      adminType: formData.status as AdminTypes,
      id: admin.id,
      name: formData.name,
      phone: '+' + formData.phone,
      email: formData.email,
    })
      .unwrap()
      .then(() => {
        setIsEdit(false);
      });
  };

  return (
    <form className="settings-admin">
      <div className={styles.columns}>
        <Controller
          control={control}
          name="name"
          render={({ field: { value, onChange }, fieldState: { error } }) =>
            isEdit ? (
              <BranchInput
                placeholder="Admin name"
                onChange={(e) => onChange(e.target.value)}
                value={value}
                error={error?.message}
              />
            ) : (
              <div className="settings-admin-name">{admin.name}</div>
            )
          }
        />
        <Controller
          control={control}
          name="status"
          render={({ field: { value, onChange }, fieldState: { error } }) =>
            isEdit ? (
              <StatusInput
                placeholder="Status"
                onChange={(e) => onChange(e.target.value)}
                value={value}
                error={error?.message}
              />
            ) : (
              <div className="settings-admin-role">
                {AdminTypeToTile[admin.adminType]}
              </div>
            )
          }
        />
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange }, fieldState: { error } }) =>
            isEdit ? (
              <BranchInput
                placeholder="Email"
                onChange={(e) => onChange(e.target.value)}
                value={value}
                error={error?.message}
              />
            ) : (
              <EmailLink email={admin.email} className="settings-admin-email" />
            )
          }
        />
        <Controller
          control={control}
          name="phone"
          render={({ field: { value, onChange }, formState: { errors } }) =>
            isEdit ? (
              <div>
                <PhoneInput
                  country={'sa'}
                  value={value}
                  onChange={onChange}
                  containerClass={styles.phoneInput}
                />
                {errors.phone && (
                  <div className={styles.error}>{errors.phone.message}</div>
                )}
              </div>
            ) : (
              <div className="settings-admin-phone">{admin.phone}</div>
            )
          }
        />
      </div>
      <div className="settings-admin-btns">
        <div className="btn-edit-admin">
          {isEdit ? (
            <SectionButton
              type="button"
              label="Save admin"
              onClick={handleSubmit(onSubmitHandler)}
              isFilled={true}
            />
          ) : (
            <SectionButton
              type="button"
              label="Edit admin"
              onClick={() => {
                setIsEdit(true);
              }}
            />
          )}
        </div>
        <SectionButton
          label="Remove admin"
          isFilled={true}
          className="btn-remove-admin"
          type="button"
          onClick={handleDeleteAdmin}
        />
      </div>
    </form>
  );
};
