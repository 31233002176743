import React, { useState } from 'react';
import styles from './OrdersTable.module.scss';
import { useGetOrdersWithFiltersQuery } from '@/entities/Order/api/orderApi';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import { OrderStatuses } from '@/entities/Order/order.models';
import { formatNumber } from '@/shared/helpers/formatNumber';
import { Pagination } from '@/shared/components/Pagination/Pagination';
import { IAdminFireBase } from '@/entities/Admin/admin.models';

export const statusDisplayName: Record<OrderStatuses, string> = {
  [OrderStatuses.PENDING_CONFIRMATION]: 'Pending Confirmation',
  [OrderStatuses.FULFILLED]: 'Completed',
  [OrderStatuses.PENDING_PAYMENT]: 'Pending Payment',
  [OrderStatuses.PENDING_FULFILLMENT]: 'Pending Fulfillment',
  [OrderStatuses.CANCELED_DECLINED]: 'Canceled Declined',
  [OrderStatuses.CANCELED_NOT_CONFIRMED]: 'Canceled Not Confirmed',
  [OrderStatuses.CANCELED_NOT_PAID]: 'Canceled Not Paid',
};

type Props = {
  startDate: string;
  endDate: string;
  relationshipManager: IAdminFireBase | null;
};

export const OrdersTable = ({
  startDate,
  endDate,
  relationshipManager,
}: Props) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data, isSuccess, isLoading } = useGetOrdersWithFiltersQuery({
    page,
    take: rowsPerPage,
    sort: 'id:desc',
    startDate,
    endDate,
    status: [
      OrderStatuses.PENDING_CONFIRMATION,
      OrderStatuses.PENDING_PAYMENT,
      OrderStatuses.PENDING_FULFILLMENT,
    ],
    relationshipManagerId: relationshipManager
      ? relationshipManager.id
      : undefined,
  });

  return (
    <div className={styles.base}>
      <div className={styles.info}>
        <span>Orders</span>
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Requested</TableCell>
              <TableCell>RM</TableCell>
              <TableCell>Order #</TableCell>
              <TableCell>Organisation</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              Array(10)
                .fill(0)
                .map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton variant="rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rounded" />
                    </TableCell>
                  </TableRow>
                ))}
            {isSuccess &&
              data.data.length > 0 &&
              data.data.map((order, index) => (
                <TableRow
                  key={order.id}
                  className={`${styles.row} ${
                    index % 2 === 0 ? styles.colorRow : styles.whiteRow
                  }`}
                  component={Link}
                  to={`/view-order/${order.id}`}>
                  <TableCell
                    sx={{
                      color: '#141A7F',
                    }}>
                    {moment(order.createdAt).format('DD MMM')}
                  </TableCell>
                  <TableCell>
                    {
                      order.contact.branch?.organization?.relationshipManager
                        .name
                    }
                  </TableCell>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>
                    {order.contact.branch?.organization?.name}
                  </TableCell>
                  <TableCell>{order.contact.branch?.name}</TableCell>
                  <TableCell>
                    {order.skuRequests.reduce<number>(
                      (acc, item) => acc + item.crewsNumber,
                      0,
                    )}
                  </TableCell>
                  <TableCell>SAR {formatNumber(order.total)}</TableCell>
                  <TableCell>{statusDisplayName[order.orderStatus]}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isSuccess && (
        <Pagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalItems={data.meta.itemCount}
        />
      )}
    </div>
  );
};
