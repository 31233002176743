import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../shared/api/api';
import { getToken } from '../../Admin/admin.models';
import { IDataWithPagination } from '../../Order/order.models';
import {
  IContact,
  IContactCreateRequest,
  IContactUpdate,
} from '../contact.models';

const contactConfig = {
  ...baseQueryConfig,
  baseUrl: baseQueryConfig.baseUrl + '/contacts',
  prepareHeaders: (headers: Headers) => {
    const session = getToken();
    headers.set('Authorization', `${session.token}`);
    return headers;
  },
};

export const contactApi = createApi({
  reducerPath: 'contactApi',
  tagTypes: ['Contact'],
  baseQuery: fetchBaseQuery(contactConfig),
  endpoints: (builder) => ({
    getContacts: builder.query<
      IDataWithPagination<IContact>,
      {
        relations?: string[];
        page: number;
        take: number;
        firstName?: string;
        lastName?: string;
        relationshipManager?: number[];
        organizationSize?: string[];
      }
    >({
      query: ({
        relations = [],
        page,
        take,
        firstName,
        lastName,
        relationshipManager,
        organizationSize,
      }) => {
        const params = new URLSearchParams();
        relations.forEach((relation) => params.append('relations', relation));
        params.append('take', take.toString());
        params.append('page', page.toString());
        if (firstName) params.append('firstName', firstName);
        if (lastName) params.append('lastName', lastName);
        if (relationshipManager) {
          relationshipManager.forEach((id) =>
            params.append(
              'branch.organization.relationshipManager.id',
              id.toString(),
            ),
          );
        }
        if (organizationSize) {
          organizationSize.forEach((size) =>
            params.append('branch.organization.organizationSize', size),
          );
        }

        return { url: `?${params.toString()}` };
      },
      providesTags: ['Contact'],
    }),
    getContactsByBranchId: builder.query<
      IDataWithPagination<IContact>,
      { id: number; take: number; page: number }
    >({
      query: ({ id, page, take }) => ({
        url: `/`,
        params: {
          'branch.id': id,
          page,
          take,
        },
      }),
      providesTags: ['Contact'],
    }),
    getContactById: builder.query<any, { id: string; relations?: string[] }>({
      query: ({ id, relations }) => {
        let url = `/${id}`;
        if (relations && relations.length > 0) {
          url += `?relations=${relations.join('&relations=')}`;
        }
        return { url };
      },
      providesTags: ['Contact'],
    }),
    createContact: builder.mutation<any, IContactCreateRequest>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Contact'],
    }),
    editContact: builder.mutation<any, IContactUpdate>({
      query: (data) => ({
        url: `/${data.id}`,
        method: 'PATCH',
        body: data,
        id: undefined,
      }),
      invalidatesTags: ['Contact'],
    }),
    deleteContact: builder.mutation<any, number>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Contact'],
    }),
  }),
});

export const {
  useGetContactsQuery,
  useCreateContactMutation,
  useDeleteContactMutation,
  useGetContactByIdQuery,
  useEditContactMutation,
  useGetContactsByBranchIdQuery,
} = contactApi;
