import React from 'react';
import './RegisterOrg.module.scss';
import RegOrgSection from '../../../features/RegOrgSection/RegOrgSection';
import { branchInputs, contactInputs, orgInputs } from './regOrgInputs';
import * as yup from 'yup';
import 'yup-phone-lite';
import {
  FieldValues,
  SubmitHandler,
  UseFormRegister,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useCreateOrganizationMutation } from '../../../entities/Organization/api/organizationApi';
import TemplatePage from '@/pages/TemplatePage/TemplatePage';
import { Steps } from './Steps/Steps';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IAdminFireBase } from '@/entities/Admin/admin.models';
import { withAdmin } from './withAdmin/withAdmin';

const yupSchema = yup
  .object({
    firstName: yup.string().required('Required field'),
    lastName: yup.string().required('Required field'),
    phone: yup.string().required('Required field'),
    email: yup.string().email('Invalid email format'),
    name: yup.string().required('Required field'),
    relationshipManager: yup
      .object({
        id: yup.number().required('Required field'),
        label: yup.string(),
      })
      .required('Required field'),
    employeesAmount: yup.number().required('Required field'),
    taxReg: yup.string().test('len', 'Must be exactly 15 characters', (val) => {
      if (!val?.length) return true;
      return val.length === 15;
    }),
    branchName: yup.string().required('Required field'),
    country: yup.string().required('Required field'),
    city: yup.string().required('Required field'),
    district: yup.string().required('Required field'),
    locationType: yup.string().required('Required field'),
    workplaceType: yup.string().required('Required field'),
    industry: yup.string().required('Required field'),
    googleMapsLink: yup
      .string()
      .url('Invalid URL format')
      .required('Required field'),
  })
  .required();

export type YupSchemaType = yup.InferType<typeof yupSchema>;

const RegisterOrgCmp: React.FC<{ admin: IAdminFireBase }> = ({ admin }) => {
  const navigate = useNavigate();
  const [createOrg] = useCreateOrganizationMutation();

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      relationshipManager: {
        ...admin,
        id: admin.id,
        label: admin.name,
      },
    },
  });

  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (orgInputs) => {
    createOrg({
      name: orgInputs.name,
      relationshipManager: orgInputs.relationshipManager.id,
      taxReg: orgInputs.taxReg ? Number(orgInputs.taxReg) : undefined,
      employeesAmount: orgInputs.employeesAmount,
      creditLimit: 0,
      industry: orgInputs.industry,
      branches: [
        {
          name: orgInputs.branchName,
          country: orgInputs.country,
          city: orgInputs.city,
          locationType: orgInputs.locationType,
          workplaceType: orgInputs.workplaceType,
          district: orgInputs.district,
          googleMapsLink: orgInputs.googleMapsLink,
          contacts: [
            {
              email: orgInputs.email,
              firstName: orgInputs.firstName,
              lastName: orgInputs.lastName,
              phone: orgInputs.phone,
            },
          ],
        },
      ],
    })
      .unwrap()
      .then(() => {
        navigate('/organizations');
      });
  };

  return (
    <TemplatePage>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Steps
          onCancel={() => navigate(-1)}
          onComplete={handleSubmit(onSubmitHandler)}>
          <RegOrgSection
            title="Add Contact"
            inputEntries={contactInputs}
            register={register as unknown as UseFormRegister<FieldValues>}
            errors={errors}
            control={control}
            validate={() => {
              return trigger(['firstName', 'lastName', 'phone', 'email']);
            }}
            confirmButtonTitle="To organization"
            ButtonIcon={ArrowForwardIcon}
          />
          <RegOrgSection
            title="Add new Organization"
            inputEntries={orgInputs}
            register={register as unknown as UseFormRegister<FieldValues>}
            errors={errors}
            control={control}
            validate={() => {
              return trigger([
                'name',
                'relationshipManager',
                'employeesAmount',
                'taxReg',
                'industry',
              ]);
            }}
            confirmButtonTitle="To branch"
            ButtonIcon={ArrowForwardIcon}
          />
          <RegOrgSection
            title="Add Branch"
            inputEntries={branchInputs}
            register={register as unknown as UseFormRegister<FieldValues>}
            errors={errors}
            control={control}
            validate={() => {
              return trigger([
                'branchName',
                'country',
                'city',
                'district',
                'locationType',
                'workplaceType',
                'googleMapsLink',
              ]);
            }}
            confirmButtonTitle="Finish"
          />
        </Steps>
      </form>
    </TemplatePage>
  );
};

export const RegisterOrg = withAdmin(RegisterOrgCmp);
RegisterOrgCmp.displayName = 'RegisterOrgCmp';
