import React, { useCallback } from 'react';
import { CircularProgress } from '@mui/material';
import arrowRight from '../../../../../assets/icons/orderPage/arrow-right.svg';
import classNames from 'classnames';
import styles from './NextOrderButton.module.scss';

interface PropTypes {
  label: string;
  isDisabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | undefined;
  classMix?: string;
}

const NextOrderButton = (props: PropTypes) => {
  const { label, onClick, type } = props;
  const handleClick = useCallback(
    () => !props.isDisabled && onClick && onClick(),
    [props.isDisabled, onClick],
  );

  return (
    <button
      className={classNames(
        styles.container,
        props.isDisabled && styles.button__disabled,
        props.classMix,
      )}
      onClick={handleClick}
      type={type || 'button'}>
      {label}
      {props.isDisabled ? (
        <CircularProgress
          className={classNames('loader', styles.icon)}
          size={'2rem'}
        />
      ) : (
        <img className={styles.icon} src={arrowRight} alt="arrow" />
      )}
    </button>
  );
};

export default NextOrderButton;
