import React, { useState } from 'react';
import TemplatePage from '../TemplatePage/TemplatePage';
import OrderCards from '../../widgets/Order/OrderCards/OrderCards';
import ordersIcon from '@/assets/icons/orderPage/order.svg';
import { Link } from 'react-router-dom';
import plusIcon from '@/assets/icons/plus-white.svg';
import styles from './OrdersContainer.module.scss';
import SectionButton from '@/shared/components/Button/SectionButton/SectionButton';
import { DatePicker } from '@/shared/components/DatePicker/DatePicker';
import { useForm } from 'react-hook-form';

const OrdersContainer = () => {
  const { control } = useForm();
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  return (
    <div className={styles.ordersContainer}>
      <TemplatePage>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <img src={ordersIcon} alt="icon" />
            <div className={styles.headerTextContainer}>
              <span className={styles.headerTitle}>Orders</span>
              <span className={styles.headerSecondaryText}>
                Manage and track the order here
              </span>
            </div>
          </div>

          <div className={styles.headerRight}>
            <div className={styles.wrapDate}>
              <span className={styles.dateLabel}>Date Range</span>
              <DatePicker
                control={control}
                name="dateRange"
                label={'All the time'}
                onChange={handleDateChange}
              />
            </div>
            <Link to="/create-order">
              <SectionButton
                label="New Order"
                path="/create-order"
                icon={plusIcon}
                isFilled
              />
            </Link>
          </div>
        </div>
        <OrderCards selectedDate={selectedDate} />
      </TemplatePage>
    </div>
  );
};

export default OrdersContainer;
