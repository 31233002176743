import React from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  FormControl,
  Checkbox,
  TextField,
  Chip,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Option = {
  label: string;
  value: any;
};

type Props = {
  name: string;
  selectOptions: Array<{ label: string; value: any }>;
  control: Control<any>;
  label?: string;
  classMix?: string;
};

export const MultiSelect: React.FC<Props> = ({
  name,
  selectOptions,
  control,
  label,
  classMix,
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
      <FormControl sx={{ width: '300px' }} className={classMix} error={!!error}>
        <Autocomplete
          multiple
          options={selectOptions}
          onChange={(event, val: Option[] | null) => {
            onChange(val ? val.map((option) => option.value) : []);
          }}
          value={selectOptions.filter((option) => value.includes(option.value))}
          disableCloseOnSelect
          onBlur={onBlur}
          getOptionLabel={(option: Option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          style={{ width: 286, height: 44 }}
          sx={{
            '& .MuiAutocomplete-inputRoot': {
              flexWrap: 'nowrap',
            },
          }}
          renderTags={(value: Option[], getTagProps) => {
            const renderedTags = value
              .slice(0, 2)
              .map((option: Option, index: number) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  label={option.label}
                  {...getTagProps({ index })}
                  style={{
                    maxWidth: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    marginRight: '4px', // add some spacing between chips
                  }}
                />
              ));

            const remainingCount = value.length - 2;

            return (
              <>
                {renderedTags}
                {remainingCount > 0 && (
                  <Typography variant="body2" component="span">
                    +{remainingCount}
                  </Typography>
                )}
              </>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              InputProps={{
                ...params.InputProps,
                sx: {
                  backgroundColor: 'white',
                  borderRadius: '12px',
                  width: '283px',
                  height: '44px',

                  '& .MuiAutocomplete-input': {
                    fontSize: '14px',
                    color: '#B2B5C8',
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  color: '#B2B5C8',
                  fontFamily: 'IBM Plex Sans',
                  lineHeight: '12px',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            />
          )}
        />
      </FormControl>
    )}
  />
);
