import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import emptyFolder from '@/assets/images/empty-folder.png';
import { organizationApi } from '../api/organizationApi';
import { IOrganization } from '../org.models';
import SectionButton from '@/shared/components/Button/SectionButton/SectionButton';
import styles from './OrgTable.module.scss';
import {
  FiltersFormData,
  OrgFilter,
  initialSearchQuery,
} from '../OrgFilter/OrgFilter';
import { Pagination } from '@/shared/components/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import orgIcon from '@/assets/icons/org-grey.svg';
import manager from '@/assets/icons/user.svg';
import { getEmployeesAmountText } from '@/shared/helpers/helperOrgSize';

const OrgTable = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState<FiltersFormData>(initialSearchQuery);
  const navigate = useNavigate();

  const [trigger, { data: orgData, isLoading }] =
    organizationApi.endpoints.getOrganizations.useLazyQuery();

  useEffect(() => {
    trigger({
      relations: ['branches.contacts.orders', 'relationshipManager'],
      page,
      take: rowsPerPage,
      searchByName: filters.orgName,
      sort: 'createdAt:desc',
      relationshipManager: filters.relationshipManager,
      organizationSize: filters.orgSize,
    });
  }, [page, rowsPerPage, filters]);

  const handleInputChange = (value: string): void => {
    setFilters((prevFilters) => ({ ...prevFilters, orgName: value }));
  };

  const handleRelationshipManagerChange = (values: string[]): void => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      relationshipManager: values,
    }));
  };

  const handleOrgSizeChange = (values: string[]): void => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      orgSize: values,
    }));
  };

  return (
    <>
      <OrgFilter
        handleInputChange={handleInputChange}
        handleRelationshipManagerChange={handleRelationshipManagerChange}
        handleOrgSizeChange={handleOrgSizeChange}
      />
      <TableContainer
        sx={{
          bgcolor: '#fff',
          borderRadius: '12px',
          paddingInline: '20px',
        }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <div className={styles.tableTitle}>
                  <img className={styles.titleIcon} src={orgIcon} alt="icon" />
                  Organisation
                </div>
              </TableCell>
              <TableCell>
                <div className={styles.tableTitle}>
                  <img className={styles.titleIcon} src={manager} alt="icon" />
                  RM
                </div>
              </TableCell>
              <TableCell>Size</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              Array(10)
                .fill(0)
                .map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton variant="rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rounded" />
                    </TableCell>
                  </TableRow>
                ))}
            {orgData && orgData.data.length > 0
              ? orgData.data.map((org: IOrganization) => (
                  <TableRow
                    key={org.id}
                    onClick={() => navigate(`/org-screen/${org.id}`)}
                    className={styles.row}>
                    <TableCell
                      sx={{
                        fontWeight: '400',
                        color: '#2C2C3A',
                        fontSize: '14px',
                        width: '45%',
                      }}>
                      {org.name}
                    </TableCell>
                    <TableCell>
                      <div className={styles.text}>
                        {org.relationshipManager.name}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={styles.text}>
                        {getEmployeesAmountText(org.employeesAmount!)}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : !isLoading && (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      sx={{
                        borderBottom: 'none',
                      }}>
                      <div className={styles.noData}>
                        <img
                          src={emptyFolder}
                          alt="image"
                          className={styles.noDataImg}
                        />
                        <p className={styles.noDataMainText}>
                          There&apos;s no organisation
                        </p>
                        <p className={styles.noDataSecondaryText}>
                          No organisation at this moment
                        </p>
                        <SectionButton
                          label="Add Now"
                          path="/register-org"
                          className={styles.btnAdd}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
        {orgData && (
          <Pagination
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalItems={orgData.meta.itemCount}
          />
        )}
      </TableContainer>
    </>
  );
};

export default OrgTable;
