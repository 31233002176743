import React from 'react';
import './ContactsContainer.scss';
import TemplatePage from '../TemplatePage/TemplatePage';
import Contacts from '../../widgets/ContactCards/Contacts';

const ContactsContainer = () => {
  return (
    <div className="contacts-container">
      <TemplatePage>
        <Contacts />
      </TemplatePage>
    </div>
  );
};

export default ContactsContainer;
