import React from 'react';
import styles from './BillingClientSummary.module.scss';
import moment from 'moment';
import orderIcon from '../../../../assets/icons/billing/orderId.svg';
import managerIcon from '../../../../assets/icons/billing/manager.svg';
import dateIcon from '../../../../assets/icons/billing/date.svg';
import amountIcon from '../../../../assets/icons/money.svg';
import { formatNumber } from '@/shared/helpers/formatNumber';

interface PropTypes {
  orgName: string;
  orgType: string;
  orderId: number;
  orderDate: Date;
  manager: string;
  amount: number;
}

const BillingClientSummary = (props: PropTypes) => {
  const { orgName, orgType, orderId, orderDate, manager, amount } = props;

  const date = moment(orderDate).format('DD MMM, YYYY');

  return (
    <div className={styles.clientSummary}>
      <div className={styles.clientOrg}>
        <div className={styles.clientName}>{orgName}</div>
        <div className={styles.clientType}>{orgType}</div>
      </div>
      <div className={styles.clientInfo}>
        <img src={orderIcon} className={styles.clientLogo} />
        <div className={styles.clientItem}>
          <div className={styles.clientLabel}>Order #</div>
          <div className={styles.clientData}>{orderId}</div>
        </div>
      </div>
      <div className={styles.clientInfo}>
        <img src={dateIcon} className={styles.clientLogo} />
        <div className={styles.clientItem}>
          <div className={styles.clientLabel}>Order Date</div>
          <div className={styles.clientData}>{date}</div>
        </div>
      </div>
      <div className={styles.clientInfo}>
        <img src={managerIcon} className={styles.clientLogo} />
        <div className={styles.clientItem}>
          <div className={styles.clientLabel}>Relationship Manager</div>
          <div className={styles.clientData}>{manager}</div>
        </div>
      </div>
      <div className={styles.clientInfo}>
        <img src={amountIcon} className={styles.clientLogo} />
        <div className={styles.clientItem}>
          <div className={styles.clientLabel}>Payment Amount</div>
          <div className={styles.clientData}>SAR {formatNumber(amount)}</div>
        </div>
      </div>
    </div>
  );
};

export default BillingClientSummary;
