import React from 'react';
import './Settings.scss';
import TemplateFormPage from '../../TemplateFormPage/TemplateFormPage';
import { useGetAdminsQuery } from '../../../entities/Admin/api/adminApi';
import { AdminForm } from './components/AdminForm/AdminForm';
import { AdminEditForm } from './components/AdminEditForm/AdminEditForm';

const Settings = () => {
  const { data: adminsDataRaw } = useGetAdminsQuery({
    take: 100,
    page: 1,
  });

  const admins = adminsDataRaw?.data;

  return (
    <TemplateFormPage>
      <div className="settings">
        <div className="settings-title">Settings</div>
        <div className="settings-admins">
          <div className="settings-admins-title">Admins</div>
          {admins?.map((admin) => (
            <AdminEditForm key={admin.id} admin={admin} />
          ))}
          <div className="settings-new-admin">
            <AdminForm />
          </div>
        </div>
      </div>
    </TemplateFormPage>
  );
};

export default Settings;
