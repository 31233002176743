import React from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { useGetBranchByIdQuery } from '../../entities/Branch/api/branchApi';
import TemplatePage from '@/pages/TemplatePage/TemplatePage';
import { EditBranchForm } from './EditBranchForm/EditBranchForm';
// import './ModalNewBranch.scss';

const UpdateBranch = () => {
  const { id } = useParams();
  if (!id) {
    return null;
  }

  const { data, isLoading, isSuccess } = useGetBranchByIdQuery(id);

  return (
    <TemplatePage>
      {isLoading && <Skeleton variant="rectangular" height={400} />}
      {isSuccess && <EditBranchForm data={data} />}
    </TemplatePage>
  );
};

export default UpdateBranch;
