import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store/store';
import { toggleClose } from '../../../entities/ErrorNotification/errorNotificationSlice';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const SnackbarProvider = () => {
  const state = useSelector((state: RootState) => state.errorNotification);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(toggleClose());
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={state.opened}
      onClose={handleClose}
      autoHideDuration={6000}
      message={state.message}
      action={action}
    />
  );
};
