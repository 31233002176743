import { IMetadata } from '../../shared/api/api';
import { IBranch } from '../Branch/branch.models';
import { IContact } from '../Contact/contact.models';
import { IOrganization } from '../Organization/org.models';
import { ISku, ISkuLanguage, ISkuResponse } from '../SKU/SKUModel.models';

export interface IOrder {
  id: number;
  contact?: IContact;
  createdAt?: Date;
  needsInteraction: boolean;
  orderStatus: OrderStatuses;
  price: number;
  rating?: number;
  skuRequests?: ISKURequest[];
  tags?: string[];
  total: number;
  tax: number;
  taxPercentage: number;
  subtotal: number;
  fulfillmentRatePercent: number;
  useFreePositions?: boolean;
}

export interface IOrdersWithPagination {
  data: IOrderWithSkuRequestContactBranchOrganization[];
  meta: IMetadata;
}

export interface IDataWithPagination<T> {
  data: T[];
  meta: IMetadata;
}

export type IOrderWithRelations = Omit<IOrder, 'skuRequests'> & {
  skuRequests: IOrderWithSKUResponse[];
  contact: IContact;
  useFreePositions: boolean;
};

export type IOrderWithSkuRequestContactBranchOrganization = {
  id: number;
  createdAt: Date;
  orderStatus: OrderStatuses;
  price: number;
  skuRequests: IOrderWithSKUResponse[];
  contact: IContact;
  branch: IBranch;
  organization: IOrganization;
  rating?: number;
  needsInteraction?: boolean;
  subtotal: number;
  total: number;
  tax: number;
  useFreePositions?: boolean;
};

export enum OrderStatuses {
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  FULFILLED = 'FULFILLED',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_FULFILLMENT = 'PENDING_FULFILLMENT',
  CANCELED_DECLINED = 'CANCELED_DECLINED',
  CANCELED_NOT_CONFIRMED = 'CANCELED_NOT_CONFIRMED',
  CANCELED_NOT_PAID = 'CANCELED_NOT_PAID',
}

export const IOrderStatusesTypes = [
  { label: 'Pending Confirmation', value: OrderStatuses.PENDING_CONFIRMATION },
  { label: 'Pending Payment', value: OrderStatuses.PENDING_PAYMENT },
  { label: 'Pending Fulfillment', value: OrderStatuses.PENDING_FULFILLMENT },
  { label: 'Canceled Declined', value: OrderStatuses.CANCELED_DECLINED },
  {
    label: 'Canceled Not Confirmed',
    value: OrderStatuses.CANCELED_NOT_CONFIRMED,
  },
  { label: 'Canceled Not Paid', value: OrderStatuses.CANCELED_NOT_PAID },
];

export interface IOrderSummary {
  orderId?: number;
  id: number;
  icon?: string;
  position: string;
  grade?: number;
  price: number;
  deliveryDate?: number;
  priceSingle?: number;
  finalQuantity?: number;
  contract?: IOrderContract;
  interviewShifts?: IOrderSchedule[];
  contact?: IContact;
  femalesPerc?: number;
  localsPerc?: number;
  isSubmitted?: boolean;
  tags: IOrderTag[];
  userNotes?: string;
  orderStatus?: string;
  handleDeleteProductCard?: (id: number) => void;
  orderData?: any;
}

export interface IFiltersTypes {
  product: string;
  legalType: string;
  jobGroup: string;
}

export interface IOrderCard {
  id: number;
  product: string;
  deliveryDate?: number | null;
  grade?: number;
  quantity: number;
  price: number;
  contract?: IOrderContract;
  interviewShifts: IOrderSchedule[];
  ordInv: number;
  femalesPerc?: number;
  localsPerc?: number;
  salaryMinimum?: number;
  tags: IOrderTag[];
  languages?: ISkuLanguage[];
  userNotes?: string;
}

export interface IOrderContract {
  id?: number;
  contractType: string;
  maxSalary: number;
  isHousing?: boolean;
  femalesPercentage?: number;
  localsPercentage?: number;
}

export interface IOrderSchedule {
  id?: number;
  date?: string;
  startTime: string | Date;
  endTime?: string;
  positionsNumber?: number;
  index?: number;
}

export interface ISKURequest {
  id: number;
  createdAt: Date;
  sku: number | ISku;
  contract: IOrderContractSKU;
  crewsNumber: number;
  femalesPercentage: number;
  localsPercentage: number;
  interviewShifts: { startTime: Date | string; positionsNumber: number }[];
  contact: string;
  order?: string;
  userNotes?: string;
}

export interface IOrderWithSKUResponse {
  id: number;
  createdAt: Date;
  sku: ISkuResponse;
  contract: IOrderContractSKU;
  crewsNumber: number;
  femalesPercentage: number;
  localsPercentage: number;
  interviewShifts: { startTime: Date | string; positionsNumber: number }[];
  contact: IContact;
  order: string;
  subtotal: number;
  tax: number;
  total: number;
  taxPercentage: number;
  tags: IOrderTag[];
  languages: ISkuLanguage[];
  userNotes?: string;
}

export interface IOrderContractSKU {
  contractType: string;
  maxSalary: number;
  isHousing: boolean;
  femalesPercentage?: number;
  localsPercentage?: number;
}

export interface IOrderFilters {
  status?: string;
  date?: string;
  organization?: string;
}

export interface IOrderTag {
  id: number;
  name: string;
}

export interface IOrderUpdateRequest {
  id: number;
  skuRequests: ISkuUpdateRequest[];
}

export interface ISkuUpdateRequest {
  sku: number;
  crewsNumber: number;
  femalesPercentage: number;
  localsPercentage: number;
  userNotes?: string;
  interviewShifts: { startTime: Date | string; positionsNumber: number }[];
  contact: number;
  tags: { id: number }[];
  contract: {
    contractType: string;
    maxSalary: number;
    isHousing: boolean;
  };
}

export interface CancelPositionDTO {
  reason: string;
  notes: string;
  cancelPositionsItems: {
    SKU: number;
    positionsAmount: number;
  }[];
}

export const languageOptions = [
  'Bengali (Bangladesh)',
  'Hindi',
  'Malayalam',
  'Urdu',
  'Sinhala (Sri Lanka)',
  'Tagalog (Philippines)',
  'Russian',
  'Ukrainian',
  'Thai',
  'Polish',
  'Latvian',
  'Lithuanian',
  'German',
  'Turkish',
  'French',
  'English',
  'Uzbek',
  'Chinese',
];
