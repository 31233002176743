import React, { useMemo } from 'react';
import styles from './OffersPage.module.scss';
import logoOffer from '../../assets/icons/logo-offer.svg';
import eyeIcon from '../../assets/icons/eye.svg';
import { useGetOffersQuery } from '../../entities/Offers/api/offersApi';
import { useParams } from 'react-router-dom';
import { CandidatesItem } from './components/CandidatsItem/CandidatesItem';
import user from '../../assets/icons/user.svg';
import moment from 'moment';

export const OffersPage: React.FC = () => {
  const { id } = useParams();
  const { data, isSuccess, isLoading } = useGetOffersQuery({ secretId: id! });

  const time = useMemo(() => {
    if (isSuccess) {
      return `${moment(data.orderStartDate).format(
        'D MMM YYYY, hh:mm',
      )}-${moment(data.orderEndDate).format('hh:mm')}`;
    }
  }, [data, isSuccess]);

  if (isLoading) return <div>Loading...</div>;
  if (!isSuccess) return <div>Something went wrong</div>;
  return (
    isSuccess && (
      <div className={styles.offersPage}>
        <section className={styles.infoSection}>
          <div className={styles.logoContainer}>
            <img src={logoOffer} alt="logo-offer" width={33} height={28} />
            <h3>Crew</h3>
          </div>
          <p>
            Kindly review the candidates from your past interview shifts and
            perform necessary actions
          </p>
        </section>
        <section className={styles.reviewsSection}>
          <div className={styles.titleContainer}>
            <img width={24} height={24} src={eyeIcon} alt="eye" />
            <h3>Reviews</h3>
          </div>
          <div className={styles.candidates}>
            <div className={styles.timeContainer}>
              <img alt="user" src={user} width={24} height={24} />
              <span className={styles.title}>Candidates</span>
              <span className={styles.time}>{time}</span>
            </div>
            <div className={styles.list}>
              {data.candidates.map((candidate) => (
                <CandidatesItem key={candidate.name} candidate={candidate} />
              ))}
            </div>
          </div>
        </section>
      </div>
    )
  );
};
