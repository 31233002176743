import React from 'react';
import './SummaryBrochure.scss';
import contract from '../../../assets/icons/orderPage/contract.svg';
import money from '../../../assets/icons/money.svg';
import house from '../../../assets/icons/orderPage/house.svg';
import user from '@/assets/icons/user.svg';
import { formatNumber } from '@/shared/helpers/formatNumber';

interface PropTypes {
  contractType: string;
  maxSalary: number;
  isHousing: boolean;
  femalesPerc?: number;
}

const SummaryBrochure = (props: PropTypes) => {
  const { contractType, maxSalary, isHousing, femalesPerc } = props;

  return (
    <div className="summary-brochure">
      <div className="summary-item">
        <img src={contract} alt="contract" />
        <div className="summary-info">
          <div className="summary-label">Contract type</div>
          <div className="summary-data">{contractType}</div>
        </div>
      </div>
      <div className="summary-item">
        <img src={money} alt="salary" />
        <div className="summary-info">
          <div className="summary-label">Max Salary</div>
          <div className="summary-data">{formatNumber(maxSalary)} SAR</div>
        </div>
      </div>
      <div className="summary-item">
        <img src={house} alt="house" />
        <div className="summary-info">
          <div className="summary-label">Housing</div>
          <div className="summary-data">
            {isHousing === true ? 'Yes' : 'No'}
          </div>
        </div>
      </div>

      {!!femalesPerc && (
        <div className="summary-item">
          <img src={user} alt="house" />
          <div className="summary-info">
            <div className="summary-label">Females</div>
            <div className="summary-data">
              {femalesPerc === 100 ? 'Yes' : 'No'}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SummaryBrochure;
