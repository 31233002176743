import React from 'react';
import styles from './BasicOrderButton.module.scss';
import classNames from 'classnames';

interface PropTypes {
  label: string;
  title?: string;
  isFilled?: boolean;
  isPink?: boolean;
  isRedLabel?: boolean;
  type?: 'button' | 'submit';
  onClick?: () => void;
  classMix?: string;
}

const BasicOrderButton = (props: PropTypes) => {
  const {
    label,
    title,
    isFilled,
    isPink,
    isRedLabel,
    type,
    onClick,
    classMix,
  } = props;
  return (
    <div>
      {title && <div className={styles.buttonTitle}>{title}</div>}
      <button
        className={classNames(
          styles.basicOrderButton,
          { [styles.filled]: isFilled },
          { [styles.pink]: isPink },
          { [styles.redlabel]: isRedLabel },
          classMix,
        )}
        onClick={onClick}
        type={type}>
        {label}
      </button>
    </div>
  );
};

export default BasicOrderButton;
