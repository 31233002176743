import React, { ChangeEventHandler, useState } from 'react';
import styles from './InputPhone.module.scss';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export type Props = {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label: string;
  name: string;
  onBlur?: () => void;
  error?: string;
};

export const InputPhone = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div
      onClick={() => inputRef.current?.focus()}
      className={classNames(
        styles.base,
        isFocused && styles.base__focused,
        props.error && styles.base__error,
      )}>
      <div className={styles.control}>
        <PhoneInput
          country={'sa'}
          value={props.value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            props.onBlur?.();
            setIsFocused(false);
          }}
          inputProps={{
            onChange: props.onChange,
          }}
          placeholder={props.label}
          inputStyle={{ width: '100%', border: 'none' }}
          buttonStyle={{
            border: 'none',
            backgroundColor: 'transparent',
          }}
          buttonClass={styles.dropdown}
        />
      </div>
      {props.error && <div className={styles.error}>{props.error}</div>}
    </div>
  );
};
