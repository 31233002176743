import React, { useEffect, useMemo } from 'react';
import styles from './OrderInfo.module.scss';
import { Skeleton } from '@mui/material';
import { formatNumber } from '@/shared/helpers/formatNumber';
import classNames from 'classnames';
import { statisticsApi } from '@/entities/Statistics/statisticsApi';
import moment from 'moment';
import { FiltersFormData } from '../OrderFilters/OrderFilters';

type Props = {
  selectedDate: any;
  filters: FiltersFormData;
};

export const OrderInfo = ({ selectedDate, filters }: Props) => {
  const startDateDefault = useMemo(
    () => moment('2023-01-01').toISOString(),
    [],
  );
  const endDateDefault = useMemo(() => moment().toISOString(), []);

  const [trigger, result] =
    statisticsApi.endpoints.getStatisticsPositions.useLazyQuery();

  const { data: statisticsData } = result;

  useEffect(() => {
    const startDate = selectedDate
      ? new Date(selectedDate[0]).toISOString()
      : startDateDefault;
    const endDate = selectedDate
      ? new Date(selectedDate[1]).toISOString()
      : endDateDefault;

    trigger({
      startDate,
      endDate,
      relationshipManagerId: filters.relationshipManager,
      organization: filters.organization,
    });
  }, [selectedDate, filters]);

  const paidPercent = useMemo(() => {
    return statisticsData
      ? Math.round(
          (statisticsData.positionsFulfillment * 100) /
            statisticsData.positionsQuoted,
        ) || 0
      : 0;
  }, [statisticsData]);

  const confirmedPercent = useMemo(() => {
    return statisticsData
      ? Math.round(
          (statisticsData.positionsConfirmed * 100) /
            statisticsData.positionsQuoted,
        ) || 0
      : 0;
  }, [statisticsData]);

  return (
    <div className={styles.base}>
      <div className={styles.stat}>
        <span className={styles.label}>Quoted</span>
        <div className={styles.value}>
          {!statisticsData ? (
            <Skeleton variant="rectangular" />
          ) : (
            statisticsData.positionsQuoted
          )}
          <span className={styles.persent}>100%</span>
        </div>
      </div>

      <div className={styles.stat}>
        <span className={styles.label}>Confirmed</span>
        <div className={styles.value}>
          {!statisticsData ? (
            <Skeleton variant="rectangular" />
          ) : (
            statisticsData.positionsConfirmed
          )}
          <span className={styles.persent}>{`${confirmedPercent}%`}</span>
        </div>
      </div>

      <div className={styles.stat}>
        <span className={styles.label}>Paid</span>
        <div className={styles.value}>
          {!statisticsData ? (
            <Skeleton variant="rectangular" />
          ) : (
            statisticsData.positionsFulfillment
          )}
          <span className={styles.persent}>{`${paidPercent}%`}</span>
        </div>
      </div>
      <div className={classNames(styles.stat, styles.stat__last)}>
        <span className={styles.label}>Value Paid</span>
        <div className={styles.value}>
          {!statisticsData && <Skeleton variant="rectangular" />}
          {statisticsData &&
            `SAR ${formatNumber(statisticsData.totalValueFulfillment)}`}
        </div>
      </div>
    </div>
  );
};
