import React, { useContext, useState } from 'react';
import './ContractDetails.scss';
import contract from '../../../assets/icons/orderPage/contract.svg';
import money from '../../../assets/icons/money.svg';
import house from '../../../assets/icons/orderPage/house.svg';
import user from '../../../assets/icons/orderPage/user-blue.svg';
import OrderSelect from '../../../shared/components/Select/OrderSelect/OrderSelect';
import OrderInput from '../../../shared/components/Input/OrderInput/OrderInput';
import OrderRadioInput from '../../../shared/components/Input/OrderRadioInput/OrderRadioInput';
import BasicOrderButton from '../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IOrderContract } from '../../../entities/Order/order.models';
import { OrderContactLocationStateContext } from '../../../pages/CreateOrder/components/ContractSchedule/context';
import { Switch } from '@mui/material';

const LOCALS_PERS = 0;

interface PropTypes {
  handleCloseTab: () => void;
  handleData: (
    type: string,
    salary: number,
    housing: boolean,
    females: number,
    locals: number,
  ) => void;
  orderListContract?: IOrderContract;
  ordInv?: number;
  femalesPerc?: number;
  localsPerc?: number;
  salaryMinimum?: number;
  prevContractData?: IOrderContract;
}

type FormData = {
  contractType: 'Permanent' | 'Temporary';
  maxSalary: number;
  isHousing: boolean;
};

const ContractDetails = (props: PropTypes) => {
  const {
    handleCloseTab,
    handleData,
    orderListContract,
    femalesPerc: femalesPercDefault,
    prevContractData,
    salaryMinimum,
  } = props;

  const { freePosition } = useContext(OrderContactLocationStateContext);
  const { contractType: contractTypeOrderList, maxSalary } =
    orderListContract || {};

  const {
    contractType: prevContractType,
    femalesPercentage: prevFemales,
    isHousing,
    maxSalary: prevMaxSalary,
  } = prevContractData || {};

  const initialPercFemales = 0;

  const [femalesPerc, setFemalesPerc] = useState(
    prevFemales || femalesPercDefault || initialPercFemales,
  );

  const [contractType, setContractType] = useState(
    prevContractType || contractTypeOrderList || '',
  );

  const maxSalaryOrderList = maxSalary;

  const [salaryMax, setSalaryMax] = useState(
    Number(prevMaxSalary || maxSalaryOrderList) || undefined,
  );

  const onSubmitHandler: SubmitHandler<FormData> = async (data) => {
    handleData(
      data.contractType,
      data.maxSalary,
      data.isHousing,
      femalesPerc,
      LOCALS_PERS,
    );
    handleCloseTab?.();
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<FormData>({
    defaultValues: {
      contractType: contractType as 'Permanent' | 'Temporary',
      maxSalary: salaryMax,
      isHousing: isHousing || false,
    },
  });

  return (
    <div className="contract-details-container">
      <form
        className="contract-details"
        onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="contract-details-heading">
          <img src={contract} alt="icon" />
          <div className="contract-title">Contract</div>
        </div>
        <OrderSelect
          icon={contract}
          options={['Permanent', 'Temporary']}
          placeholder="Contract type"
          handleCollectValue={(value) => {
            setValue('contractType', value as 'Permanent' | 'Temporary');
            setContractType(value);
          }}
          error={errors.contractType?.message}
          {...register('contractType', {
            required: true,
          })}
        />
        <OrderInput
          innerIcon={money}
          placeholder="Salary Maximum (SAR)"
          handleCollectValue={(value) => {
            const salaryValue = Number(value);
            if (!isNaN(salaryValue)) {
              setValue('maxSalary', salaryValue);
              setSalaryMax(salaryValue);
            }
          }}
          error={errors.maxSalary?.message}
          {...register('maxSalary', {
            valueAsNumber: true,
            required: true,
            min: {
              value: salaryMinimum || 1,
              message: 'Salary must be greater than ' + (salaryMinimum || 1),
            },
          })}
        />
        <div className="contract-details-note">
          Crew will work to bring the best workers at the lowest cost
        </div>
        <div className="contract-housing">
          <div className="housing-label">
            <img src={house} alt="House" />
            Housing
          </div>
          <div className="housing-options">
            <Controller
              control={control}
              name="isHousing"
              render={({ field: { value, onChange } }) => (
                <>
                  <OrderRadioInput
                    value="Yes"
                    name="isHousing"
                    handleCollectValue={() => {
                      onChange(true);
                    }}
                    isChecked={value === true || undefined}
                  />
                  <OrderRadioInput
                    value="No"
                    name="isHousing"
                    handleCollectValue={() => {
                      onChange(false);
                    }}
                    isChecked={value === false || undefined}
                  />
                </>
              )}
            />
          </div>
        </div>
        {errors.isHousing?.message && (
          <div className="error-message">{errors.isHousing?.message}</div>
        )}
        <div className="hr"></div>
        <div
          className={`contract-diversity ${
            freePosition ? 'grey-diversity' : ''
          }`}>
          <div className="diversity-title">
            <img src={user} alt="user" />
            Diversity target
          </div>
          <div className="diversity-females">
            <div className="diversity-females-label">Females</div>
            <Switch
              disabled={!!freePosition}
              checked={femalesPerc === 100}
              onChange={(e) => setFemalesPerc(e.target.checked ? 100 : 0)}
              color="primary"
              inputProps={{ 'aria-label': 'Females Percentage' }}
            />
          </div>
          <div className="hr"></div>
          <BasicOrderButton label="Confirm" isFilled={true} />
        </div>
      </form>
    </div>
  );
};

export default ContractDetails;
