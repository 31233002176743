import React, { useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetOrganizationByIdQuery } from '@/entities/Organization/api/organizationApi';
import { IBranch } from '@/entities/Branch/branch.models';
import TemplatePage from '@/pages/TemplatePage/TemplatePage';
import SectionButton from '@/shared/components/Button/SectionButton/SectionButton';
import arrowRight from '@/assets/icons/chevron-right.svg';
import arrowBack from '@/assets/icons/chevron-left.svg';
import orgIcon from '@/assets/icons/org-pink.svg';
import taxIcon from '@/assets/icons/tax.svg';
import userIcon from '@/assets/icons/user.svg';
import employeesIcon from '@/assets/icons/team.svg';
import branchIcon from '@/assets/icons/branch-blue.svg';
import cityIcon from '@/assets/icons/pin-map.svg';
import locationIcon from '@/assets/icons/store.svg';
import workplaceIcon from '@/assets/icons/workplace.svg';
import eyeIcon from '@/assets/icons/eye-blue.svg';
import crownIcon from '@/assets/icons/crown.svg';
import style from './OrgScreen.module.scss';
import { useGetBranchesByOrganizationIdQuery } from '@/entities/Branch/api/branchApi';
import { DeleteOrganization } from './DeleteOrganization/DeleteOrganization';
import { OrgOrders } from './OrgOrders/OrgOrders';
import { useGetOrdersByOrganizationIdQuery } from '@/entities/Order/api/orderApi';

const OrgScreen = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const { id: orgId } = useParams();
  const { data: orgData, isSuccess } = useGetOrganizationByIdQuery(orgId!);

  const { data: branchData, isSuccess: branchesLoaded } =
    useGetBranchesByOrganizationIdQuery(orgId!);

  const { data: ordersData, isSuccess: ordersLoaded } =
    useGetOrdersByOrganizationIdQuery({
      organizationId: +orgId!,
      take: 100,
      page: 1,
    });

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const getSupervisorName = useCallback(
    (branchId: number) => {
      if (!orgData) return '';
      const branches = orgData.branches;
      const branch = branches.find((branch) => branch.id === branchId);
      const supervisor = branch?.contacts?.find((contact) => contact.isPrimary);
      return supervisor
        ? `${supervisor.firstName} ${supervisor.lastName}`
        : 'N/A';
    },
    [orgData],
  );

  if (!isSuccess) return null;

  return (
    <TemplatePage>
      <div className={style.nav}>
        <Link className={style.navLinkBack} to={'/organizations'}>
          <img src={arrowBack} alt="back" />
        </Link>
        <div className={style.navLink}>Organisations list</div>
        <img src={arrowRight} alt="arrow right" />
        <div className={style.navLinkActive}>Organisation detail</div>
      </div>

      <div className={style.orgScreen}>
        <div className={style.orgInfo}>
          <div className={style.orgInfoTop}>
            <img src={orgIcon} alt="org" />
            <Link key={orgId} to={`/org-screen/edit/${orgId}`}>
              <SectionButton className={style.orgInfoEdit} label="Edit" />
            </Link>
          </div>

          <div>
            <div className={style.orgInfoName}>{orgData?.name}</div>
            <div className={style.orgInfoIndustry}>{orgData?.industry}</div>
          </div>

          <div className={style.orgDetails}>
            {orgData?.taxReg && (
              <div className={style.orgDetailsItem}>
                <img src={taxIcon} alt="tax" />
                <div className={style.orgDetailsText}>
                  <p className={style.orgDetailsTitle}>
                    Tax Registration Number
                  </p>
                  <p className={style.orgDetailsValue}>{orgData.taxReg}</p>
                </div>
              </div>
            )}

            {isModalVisible && (
              <DeleteOrganization
                isModalVisible={isModalVisible}
                orgData={orgData}
                toggleModal={toggleModal}
              />
            )}

            <div className={style.orgDetailsItem}>
              <img src={userIcon} alt="user" />
              <div className={style.orgDetailsText}>
                <p className={style.orgDetailsTitle}>Relationship manager</p>
                <p className={style.orgDetailsValue}>
                  {orgData?.relationshipManager.name}
                </p>
              </div>
            </div>

            <div className={style.orgDetailsItem}>
              <img src={employeesIcon} alt="employees" />
              <div className={style.orgDetailsText}>
                <p className={style.orgDetailsTitle}>Number of employees</p>
                <p className={style.orgDetailsValue}>
                  {orgData?.employeesAmount}
                </p>
              </div>
            </div>
            <SectionButton
              className={style.branchDetailsButton}
              label="Delete organization"
              onClick={toggleModal}
            />
          </div>
        </div>

        <div className={style.branch}>
          <div className={style.branchTop}>
            <div className={style.branchTitle}>
              <img src={branchIcon} alt="branch icon" />
              <p className={style.branchTitle}>Branch</p>
            </div>
            <Link key={orgId} to={`/org-screen/register-branch/${orgId}`}>
              <SectionButton className={style.branchBtn} label="Add branch +" />
            </Link>
          </div>

          <div className={style.branchItems}>
            <div className={style.branchItem}>
              {branchesLoaded &&
                branchData.data.map((branch: IBranch) => (
                  <>
                    <div className={style.branchName}>{branch.name}</div>
                    <div className={style.branchContainer}>
                      <Link
                        key={branch.id}
                        to={`/contact-branch/${branch?.id}`}>
                        <div className={style.branchDetails}>
                          <div className={style.branchDetailsItem}>
                            <img src={cityIcon} alt="icon" />
                            <div className={style.branchDetailsText}>
                              <p className={style.branchDetailsTitle}>City</p>
                              <p className={style.branchDetailsValue}>
                                {branch.city}
                              </p>
                            </div>
                          </div>
                          <div className={style.branchDetailsItem}>
                            <img src={locationIcon} alt="icon" />
                            <div className={style.branchDetailsText}>
                              <p className={style.branchDetailsTitle}>
                                Location type
                              </p>
                              <p className={style.branchDetailsValue}>
                                {branch.locationType}
                              </p>
                            </div>
                          </div>
                          <div className={style.branchDetailsItem}>
                            <img src={workplaceIcon} alt="icon" />
                            <div className={style.branchDetailsText}>
                              <p className={style.branchDetailsTitle}>
                                Workplace type
                              </p>
                              <p className={style.branchDetailsValue}>
                                {branch.workplaceType}
                              </p>
                            </div>
                          </div>
                          <div className={style.branchDetailsItem}>
                            <img src={userIcon} alt="icon" />
                            <div className={style.branchDetailsText}>
                              <p className={style.branchDetailsTitle}>
                                Supervisor
                              </p>
                              <p className={style.branchDetailsValue}>
                                {getSupervisorName(branch.id)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <div className={style.branchBtns}>
                        {branch.isPrimary && (
                          <div className={style.branchDetailsItem}>
                            <img src={crownIcon} alt="icon" />
                            <div className={style.branchDetailsText}>
                              <p className={style.branchDetailsValue}>
                                Headquarter
                              </p>
                            </div>
                          </div>
                        )}
                        <SectionButton
                          label="Location"
                          icon={eyeIcon}
                          path={branch.googleMapsLink}
                        />
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>

          {ordersLoaded && (
            <OrgOrders ordersData={ordersData} isSuccess={ordersLoaded} />
          )}
        </div>
      </div>
    </TemplatePage>
  );
};

export default OrgScreen;
