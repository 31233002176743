import React, { useState } from 'react';
import './OrderInput.scss';
import classNames from 'classnames';

interface PropTypes {
  label?: string;
  icon?: string;
  innerIcon?: string;
  placeholder?: string;
  value?: string;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCollectValue?: (value: string) => void;
  name?: string;
}

export const formatNumberToCurrency = (value: string): string => {
  const numberValue = parseFloat(value.replace(/,/g, ''));
  if (isNaN(numberValue)) return '';
  return numberValue.toLocaleString('en-US');
};

const OrderInput = React.forwardRef<HTMLInputElement, PropTypes>(
  (props, ref) => {
    const {
      label,
      icon,
      innerIcon,
      placeholder,
      value,
      error,
      onChange,
      handleCollectValue,
    } = props;

    const [inputValue, setInputValue] = useState(
      value ? formatNumberToCurrency(value) : '',
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = e.target.value.replace(/,/g, '');
      const formattedValue = formatNumberToCurrency(rawValue);
      setInputValue(formattedValue);

      const numericValue = rawValue;
      onChange &&
        onChange({ ...e, target: { ...e.target, value: numericValue } });
      handleCollectValue && handleCollectValue(numericValue);
    };

    return (
      <div className="order-input-container">
        <div className="order-control">
          {label && <div className="order-input-label">{label}</div>}
          {icon && <img className="order-icon" src={icon} alt="icon" />}
          {innerIcon && (
            <img
              className="order-inner-icon"
              src={innerIcon}
              alt="inner icon"
            />
          )}
          <input
            className={classNames('order-input', { 'inner-input': innerIcon })}
            placeholder={placeholder}
            onChange={handleInputChange}
            value={inputValue}
            ref={ref}
          />
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    );
  },
);

OrderInput.displayName = 'order-input';

export default OrderInput;
