import { Outlet, useNavigate } from 'react-router-dom';
import React, { PropsWithChildren, useEffect } from 'react';
import { SkuProvider } from '../SkuProvider/SkuProvider';
import { useFirebaseNotificationProvider } from '../../../entities/FirebaseNotification/FirebaseNotification';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { auth } from '@/services/firebase';
import { setCurrentUser } from '@/entities/Admin/admin.slice';
import { RoleProvider } from '@/entities/Role/RoleProvider';
import { logout } from '@/services/logout';

const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch<AppDispatch>();
  const { initFCM } = useFirebaseNotificationProvider();
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          dispatch(setCurrentUser(user));
          const token = await user.getIdToken();
          localStorage.setItem('token', token);
        } else {
          throw new Error('User is not authenticated');
        }
      } catch (err) {
        dispatch(logout());
        navigate('/login');
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      initFCM();
    }
  }, [user]);

  return (
    user && (
      <RoleProvider>
        <SkuProvider>{children ?? <Outlet />}</SkuProvider>
      </RoleProvider>
    )
  );
};

export default ProtectedRoute;
