const userTokenKey = 'token';

export interface IAdmin {
  adminType: AdminTypes;
  email: string;
  name: string;
  phone: string;
  id: number;
}

export interface IAdminFireBase {
  adminType: AdminTypes;
  email: string;
  name: string;
  phone: string;
  id: number;
  uid: string;
  label: string;
}

export enum AdminTypes {
  super = 'super',
  accountant = 'accountant',
  ordinary = 'ordinary',
}

export interface ICreateAdminRequest {
  name: string;
  email: string;
  adminType: AdminTypes;
  phone: string;
}

export interface CreateAdminResponse {
  adminId: string;
  isSuccess: boolean;
  token: string;
}

export interface IUpdateAdminRequest {
  id: number;
  adminType: AdminTypes;
  name: string;
  phone: string;
  email: string;
}

export const saveToken = (token: string) => {
  localStorage.setItem(userTokenKey, token);
};
export const removeItem = async () => {
  localStorage.removeItem(userTokenKey);
};

export const getToken = () => {
  const tokenString = localStorage.getItem('token') || '';
  return {
    token: tokenString,
  };
};
