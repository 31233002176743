import React from 'react';
import TemplatePage from '@/pages/TemplatePage/TemplatePage';
import styles from './ContactDetails.module.scss';
import { ContactTable } from './ContactTable/ContactTable';

export const ContactDetails = () => {
  return (
    <div className={styles.container}>
      <TemplatePage>
        <ContactTable />
      </TemplatePage>
    </div>
  );
};
