import { OrderStatuses } from '../../../entities/Order/order.models';

export const statusTitles = {
  [OrderStatuses.PENDING_CONFIRMATION]: 'Pending Confirmation',
  [OrderStatuses.FULFILLED]: 'Fulfilled',
  [OrderStatuses.PENDING_PAYMENT]: 'Pending Payment',
  [OrderStatuses.PENDING_FULFILLMENT]: 'Pending Fulfillment',
  [OrderStatuses.CANCELED_DECLINED]: 'Canceled Declined',
  [OrderStatuses.CANCELED_NOT_CONFIRMED]: 'Canceled Not Confirmed',
  [OrderStatuses.CANCELED_NOT_PAID]: 'Canceled Not Paid',
};
