import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../shared/api/api';
import { RootState } from '../../../store/store';
import { OfferResponse, SendOffer } from '../offer.models';

export const offersApi = createApi({
  reducerPath: 'offersApi',
  tagTypes: ['Offer'],
  baseQuery: fetchBaseQuery({
    ...baseQueryConfig,
    baseUrl: baseQueryConfig.baseUrl + '/offer',
    prepareHeaders: async (headers: Headers, { getState }) => {
      // If we have a token set in state, let's assume that we should be passing it.
      const token = await (getState() as RootState).user.user?.getIdToken();
      token && headers.set('Authorization', token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOffers: builder.query<OfferResponse, { secretId: string }>({
      query: ({ secretId }) => ({
        url: '',
        params: {
          secretId,
        },
      }),
      providesTags: ['Offer'],
    }),
    sendOffer: builder.mutation<any, SendOffer>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Offer'],
    }),
  }),
});

export const { useGetOffersQuery, useSendOfferMutation } = offersApi;
