import React, { ChangeEventHandler, useState } from 'react';
import styles from './Input.module.scss';
import classNames from 'classnames';
export type Props = {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label: string;
  name: string;
  icon?: string;
  onBlur?: () => void;
  error?: string;
  maxLength?: number;
};
export const Input = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <div
      onClick={() => inputRef.current?.focus()}
      className={classNames(
        styles.base,
        isFocused && styles.base__focused,
        props.error && styles.base__error,
      )}>
      {props.icon && (
        <img
          src={props.icon}
          width={24}
          height={24}
          alt="icon"
          className={styles.icon}
        />
      )}
      <div className={styles.control}>
        {props.value && <label className={styles.label}>{props.label}</label>}
        <input
          ref={inputRef}
          type="text"
          name={props.name}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            props.onBlur?.();
            setIsFocused(false);
          }}
          onChange={props.onChange}
          placeholder={props.label}
          className={styles.input}
          maxLength={props.maxLength}
          value={props.value}
        />
      </div>
      {props.error && <div className={styles.error}>{props.error}</div>}
    </div>
  );
};
