import React from 'react';
import { Modal } from '@mui/material';
import BasicOrderButton from '../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';

import styles from './CancelModal.module.scss';

type Props = {
  isCancelModalVisible: boolean;
  toggleModal: () => void;
  handleCancelOrder: (refundPolicy: string) => Promise<void>;
};

export const CancelModal: React.FC<Props> = ({
  isCancelModalVisible,
  toggleModal,
  handleCancelOrder,
}) => {
  return (
    <Modal
      className={styles.modal}
      open={isCancelModalVisible}
      onClose={toggleModal}>
      <div className={styles.modalContent}>
        <span className={styles.modalHeader}>
          Do you want the refund to be paid out or you want to use it for the
          other orders?
        </span>
        <div className={styles.modalSummaryBtns}>
          <BasicOrderButton
            label="Pay out"
            onClick={() => handleCancelOrder('Payout')}
          />
          <BasicOrderButton
            label="Use for other orders"
            onClick={() => handleCancelOrder('HoldOnBalance')}
          />
        </div>
      </div>
    </Modal>
  );
};
