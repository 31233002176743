import React, { useState } from 'react';
import TemplatePage from '../../../TemplatePage/TemplatePage';
import FormTitle from '../FormTitle/FormTitle';
import BillingClientSummary from '../BillingClientSummary/BillingClientSummary';
import { PaymentMethod } from '../../../../entities/transactEntities/Payment/payment.models';
import BasicOrderButton from '../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import styles from '../PaymentForm/PaymentForm.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import backIcon from '../../../../assets/icons/arrow-left-grey.svg';
import refundIcon from '../../../../assets/icons/billing/refund.png';
import moment from 'moment';
import { SubmitHandler, useForm } from 'react-hook-form';
import arrowDownIcon from '../../../../assets/icons/chevron-down.svg';
import {
  RefundItem,
  useGetRefundByIdQuery,
  useUpdateCancelationRequestMutation,
} from '../../../../entities/CancelationRequest/CancelationRequestApi';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { getOrganizationName, truncateString } from '../../helpers';
import { formatNumber } from '@/shared/helpers/formatNumber';

export const RefundForm = () => {
  const { id } = useParams();
  if (!id) {
    return null;
  }
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const navigate = useNavigate();

  const {
    data: refundData,
    isSuccess,
    isLoading,
  } = useGetRefundByIdQuery({
    id: `${id}`,
    relations: ['order.contact.branch.organization.relationshipManager'],
  });

  const date =
    refundData && moment(refundData.createdAt).format('DD MMM, YYYY');

  const [updateRefund] = useUpdateCancelationRequestMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      paymentMethod: '',
      file: null,
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
  };

  const handleSubmitUpdate: SubmitHandler<{
    paymentMethod: string;
    file: File | null;
  }> = async (formData) => {
    updateRefund({
      id: +id,
      paymentMethod: formData.paymentMethod,
      file: selectedFile!,
    })
      .then(() => {
        navigate('/billing');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error while updating payment:', error);
      });
  };

  const totalPerItem =
    refundData &&
    refundData.refundItems.map(
      (item: RefundItem) => item.positionsAmount * item.pricePerUnit,
    );
  const totalSum = totalPerItem
    ? totalPerItem.reduce((acc: number, curr: number) => acc + curr, 0)
    : 0;

  return (
    <TemplatePage>
      <>
        <div>
          {isLoading && (
            <div className={styles.loader}>
              <CircularProgress />
            </div>
          )}

          {isSuccess && refundData.order?.contact?.branch && (
            <>
              <FormTitle title="Refund form" icon={backIcon} />
              <div className={styles.paymentFormContent}>
                <BillingClientSummary
                  orgName={getOrganizationName(refundData)}
                  orgType={refundData.order.contact.branch.locationType}
                  orderId={refundData.order.id}
                  orderDate={refundData.order.createdAt!}
                  manager={
                    refundData.order.contact.branch.organization
                      ?.relationshipManager.name || '-'
                  }
                  amount={refundData.refundableAmount}
                />
                <div className={styles.paymentReceipt}>
                  <div className={styles.paymentTitleContainer}>
                    <img
                      className={styles.paymentIcon}
                      src={refundIcon}
                      alt="icon"
                    />
                    <div className={styles.paymentTitle}>
                      Upload refund receipt
                    </div>
                  </div>

                  <div className={styles.paymentInputs}>
                    <div className={styles.paymentInput}>
                      <label
                        className={styles.paymentInputLabel}
                        htmlFor="amount">
                        Amount Paid
                      </label>
                      <label
                        className={styles.paymentInputLabelSAR}
                        htmlFor="amount">
                        SAR
                      </label>
                      <input
                        id="amount"
                        className={styles.paymentInputAmount}
                        type="number"
                        defaultValue={refundData.refundableAmount}
                        readOnly
                      />
                    </div>

                    <div className={styles.paymentInput}>
                      <label
                        className={styles.paymentInputLabel}
                        htmlFor="date">
                        Invoice Date
                      </label>
                      <input
                        id="date"
                        className={styles.paymentInputReceipt}
                        type="text"
                        defaultValue={date}
                        readOnly
                      />
                    </div>

                    <div className={styles.paymentInput}>
                      <select
                        id="paymentMethod"
                        className={styles.paymentInputMethod}
                        defaultValue=""
                        {...register('paymentMethod', { required: true })}>
                        <option value="" disabled hidden>
                          Choose Payment
                        </option>
                        {Object.values(PaymentMethod).map((method, index) => (
                          <option key={index} value={method}>
                            {method}
                          </option>
                        ))}
                      </select>
                      <img
                        className={styles.selectIcon}
                        src={arrowDownIcon}
                        alt="arrow"
                      />
                      <div className={styles.errorBlock}>
                        {errors.paymentMethod &&
                          errors.paymentMethod.type === 'required' && (
                            <p className={styles.error}>
                              This field is required
                            </p>
                          )}
                      </div>
                    </div>
                    <div className={styles.invoiceItems}>
                      <TableContainer>
                        <Table sx={{ minWidth: 400 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell padding="none">
                                <div className={styles.invoiceTitle}>Item</div>
                              </TableCell>
                              <TableCell padding="none">
                                <div className={styles.invoiceTitle}>Qty</div>
                              </TableCell>
                              <TableCell padding="none">
                                <div className={styles.invoiceTitle}>
                                  Price/unit
                                </div>
                              </TableCell>
                              <TableCell padding="none">
                                <div className={styles.invoiceTitle}>Total</div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {refundData.refundItems.map(
                              (refundItem: RefundItem, index: number) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    '&:not(:last-child) td': {
                                      border: 0,
                                    },
                                  }}>
                                  <TableCell padding="none">
                                    <div className={styles.invoiceItem}>
                                      {truncateString(refundItem.name, 25)}
                                    </div>
                                  </TableCell>
                                  <TableCell padding="none">
                                    <div className={styles.invoiceItem}>
                                      {refundItem.positionsAmount}
                                    </div>
                                  </TableCell>
                                  <TableCell padding="none">
                                    <div className={styles.invoiceItem}>
                                      SAR{' '}
                                      {formatNumber(refundItem.pricePerUnit)}
                                    </div>
                                  </TableCell>
                                  <TableCell padding="none">
                                    <div className={styles.invoiceItem}>
                                      SAR{' '}
                                      {refundItem.positionsAmount *
                                        refundItem.pricePerUnit}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ),
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                    <div className={styles.summaryItem}>
                      <div className={styles.summaryLabel}>Total</div>
                      <div className={styles.summaryTotal}>SAR {totalSum}</div>
                    </div>

                    <hr className={styles.hr} />

                    <div className={styles.paymentAddReceipt}>
                      <div className={styles.paymentAddReceiptLabel}>
                        Upload receipt
                      </div>
                      <div>
                        <label
                          htmlFor="receiptInput"
                          className={styles.customFileUpload}>
                          <input
                            type="file"
                            id="receiptInput"
                            accept=".pdf"
                            required
                            className={styles.paymentAddReceiptButton}
                            onChange={handleFileChange}
                          />
                          Upload
                        </label>
                        <div className={styles.errorBlockReceipt}>
                          {selectedFile && (
                            <span className={styles.paymentAddReceiptFile}>
                              {selectedFile.name}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr className={styles.hr} />
                    <div className={styles.paymentButtonsWrap}>
                      <BasicOrderButton
                        label="Cancel"
                        onClick={() => navigate(-1)}
                      />
                      <BasicOrderButton
                        label="Save"
                        isPink={true}
                        type="submit"
                        onClick={handleSubmit(handleSubmitUpdate)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </TemplatePage>
  );
};
