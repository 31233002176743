import React, { PropsWithChildren } from 'react';
import { useGetRoleQuery } from './api/roleApi';

const RoleProviderCmp = ({ children }: PropsWithChildren) => {
  const { isLoading } = useGetRoleQuery('');
  if (isLoading) return <div></div>;
  return <>{children}</>;
};

export const RoleProvider = React.memo(RoleProviderCmp);
