import moment from 'moment';
import { StatisticsTime } from '@/entities/Statistics/models';

export const getStartAndEndDate = (time: StatisticsTime) => {
  let newStartDate;
  let newEndDate;

  switch (time) {
    case StatisticsTime.ALL_TIME:
      newStartDate = moment('2023-01-01').toISOString();
      newEndDate = moment().toISOString();
      break;
    case StatisticsTime.THIS_WEEK:
      newStartDate = moment().startOf('isoWeek').toISOString();
      newEndDate = moment().toISOString();
      break;
    case StatisticsTime.LAST_WEEK:
      newStartDate = moment()
        .subtract(1, 'weeks')
        .startOf('isoWeek')
        .toISOString();
      newEndDate = moment()
        .subtract(1, 'weeks')
        .endOf('isoWeek')
        .subtract(1, 'days')
        .toISOString();
      break;
    case StatisticsTime.THIS_MONTH:
      newStartDate = moment().startOf('month').toISOString();
      newEndDate = moment().toISOString();
      break;
    case StatisticsTime.LAST_MONTH:
      newStartDate = moment()
        .subtract(1, 'months')
        .startOf('month')
        .toISOString();
      newEndDate = moment().subtract(1, 'months').endOf('month').toISOString();
      break;
    case StatisticsTime.LAST_14_DAYS:
      newStartDate = moment().subtract(13, 'days').startOf('day').toISOString(); // Include today
      newEndDate = moment().toISOString();
      break;
    case StatisticsTime.LAST_28_DAYS:
      newStartDate = moment().subtract(27, 'days').startOf('day').toISOString(); // Include today
      newEndDate = moment().toISOString();
      break;
    case StatisticsTime.LAST_90_DAYS:
      newStartDate = moment().subtract(89, 'days').startOf('day').toISOString(); // Include today
      newEndDate = moment().toISOString();
      break;
    default:
      newStartDate = moment().startOf('isoWeek').toISOString();
      newEndDate = moment().toISOString();
      break;
  }

  return { newStartDate, newEndDate };
};
