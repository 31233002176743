import React, { useCallback, useState } from 'react';
import { ISelectOptions, RefundsFilter } from './RefundsFilter/RefundsFilter';
import SectionButton from '../../../../../shared/components/Button/SectionButton/SectionButton';

import styles from './RefundsFilterBlock.module.scss';
import { RequestStatuses } from '../../../../../entities/CancelationRequest/CancelationRequestApi';

export type FilterStatus = {
  status: string;
};

type PropTypes = {
  visible: boolean;
  onSubmit: (data: FilterStatus) => void;
};

const SelectOptions: ISelectOptions[] = Object.values(RequestStatuses).map(
  (status) => ({
    label: status,
    value: status,
  }),
);

const initialSearchQuery = {
  status: 'all',
};

export const RefundsFilterBlock = ({ onSubmit, visible }: PropTypes) => {
  const [searchQuery, setSearchQuery] =
    useState<FilterStatus>(initialSearchQuery);

  const handleInputChange = (value: string, category: string) => {
    setSearchQuery((prevSearchQuery: any) => {
      const updatedSearchQuery = {
        ...prevSearchQuery,
        [category]: value,
      };
      return updatedSearchQuery;
    });
  };

  const handleSubmit = useCallback(() => {
    onSubmit(searchQuery);
  }, [searchQuery]);
  if (!visible) return null;

  const handleReset = () => {
    setSearchQuery(initialSearchQuery);
    onSubmit(initialSearchQuery);
  };

  return (
    <div className={styles.ordersFilterBlock}>
      <RefundsFilter
        name="status"
        label="Status"
        type="select"
        options={SelectOptions}
        searchQuery={searchQuery}
        handleInputChange={handleInputChange}
      />
      <SectionButton label="Apply filters" onClick={handleSubmit} />
      <SectionButton
        label="Reset filters"
        className={styles.resetFilters}
        onClick={handleReset}
      />
    </div>
  );
};
