import { EmployeesAmountTypes } from '@/entities/Organization/org.models';

export const getEmployeesAmountText = (
  amount: number,
): EmployeesAmountTypes => {
  if (amount === 0) {
    return EmployeesAmountTypes.MICRO;
  } else if (amount < 5) {
    return EmployeesAmountTypes.SMALL;
  } else if (amount < 50) {
    return EmployeesAmountTypes.MEDIUM;
  } else if (amount < 500) {
    return EmployeesAmountTypes.LARGE;
  } else {
    return EmployeesAmountTypes.ENTERPRISE;
  }
};
