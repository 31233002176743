import { IContact, IContactCreateRequest } from '../Contact/contact.models';
import { IOrganization } from '../Organization/org.models';

export interface IBranch {
  city: CitiesTypes;
  contacts?: IContact[];
  country?: string;
  district: string;
  isPrimary?: boolean;
  locationType: LocationTypes;
  name: string;
  organization?: IOrganization;
  workplaceType: WorkplaceTypes;
  id: number;
  createdAt: string;
  googleMapsLink?: string;
}

export interface IBranchCreateRequest {
  name: string;
  city: string;
  contacts: IContactCreateRequest[];
  country: string;
  district: string;
  locationType: string;
  googleMapsLink: string;
  workplaceType: string;
  organization?: number;
}

export type IBranchUpdateRequest = {
  id: number;
  branchName: string;
  country: string;
  district: string;
  locationType: string;
  city: string;
  workplaceType: string;
  googleMapsLink: string;
};

export type CreateBranchForm = Omit<IBranch, 'createdAt' | 'id'>;

export enum CitiesTypes {
  Jeddah = 'Jeddah',
  Makkah = 'Makkah',
  Taif = 'Taif',
  Madinah = 'Madinah',
  Riyadh = 'Riyadh',
  Dammam = 'Dammam',
  Ahsaa = 'Ahsaa',
  Khobar = 'Khobar',
  Abha = 'Abha',
  KhamisMushait = 'Khamis Mushait',
  Buraidah = 'Buraidah',
  Kharj = 'Kharj',
  Tabuk = 'Tabuk',
}

export enum LocationTypes {
  Mall = 'Mall',
  StripMall = 'Strip Mall',
  Airport = 'Airport',
  TrainStation = 'Train Station',
  Independant = 'Independent',
  Hospital = 'Hospital',
  RemoteSite = 'Remote Site',
}

export enum WorkplaceTypes {
  HotelFive = '5 Star Hotel',
  HotelFour = '4 Star Hotel',
  HotelThree = '3 Star Hotel',
  HotelTwo = '2 Star Hotel',
  UnRatedHotel = 'Un-Rated Hotel',
  FurnishedApartments = 'Furnished Apartments',
  FineDiningRestaurant = 'Fine Dining Restaurant',
  CasualDiningRestaurant = 'Casual Dining Restaurant',
  FastFoodRestaurant = 'Fast Food Restaurant',
  TraditionalRestaurant = 'Traditional Restaurant',
  Cafe = 'Cafe',
  AirlineInflightServices = 'Airline Inflight Services',
  Office = 'Office',
  Lounge = 'Lounge',
  CateringKitchen = 'Catering Kitchen',
  BallroomConvention = 'Ballroom & Convention',
  FoodTruck = 'Food Truck',
  CoffeeKiosk = 'Coffee Kiosk',
}

export enum DistrictTypes {
  Makkah = 'Makkah',
  Madinah = 'Madinah',
  Tabuk = 'Tabuk',
  Riyadh = 'Riyadh',
}

export enum CountryTypes {
  SaudiArabia = 'Saudi Arabia',
}
