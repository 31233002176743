import Dexie, { Table } from 'dexie';

interface FirebaseNotification {
  id?: number;
  title: string;
  body: string;
}

//
// Declare Database
//
class NotificationsDb extends Dexie {
  public notifications!: Table<FirebaseNotification, number>;

  public constructor() {
    super('NotificationsDb');
    this.version(1).stores({
      notifications: '++id, title, body',
    });
  }
}

export const notificationsDb = new NotificationsDb();

export const addNotification = (notification: FirebaseNotification) => {
  notificationsDb.notifications.add(notification);
};
