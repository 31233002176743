import React, { useCallback, useMemo, useState } from 'react';
import './ContractSchedule.scss';
import money from '../../../../assets/icons/orderPage/money.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  IOrderCard,
  IOrderSchedule,
} from '../../../../entities/Order/order.models';
import TemplatePage from '../../../TemplatePage/TemplatePage';
import NextOrderButton from '../../../../shared/components/Button/OrderButtons/NextOrderButton/NextOrderButton';
import BasicOrderButton from '../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import OrderTitle from '../../../../shared/components/Title/OrderTitle/OrderTitle';
import OrderStagesPanel, {
  StageData,
} from '../../../../widgets/Order/OrderStagesPanel/OrderStagesPanel';
import MarketSalary from '../../../../widgets/Order/MarketSalary/MarketSalary';
import { mapProductNameToIcon } from '../SelectProduct/components/ProductCard/helper';
import OrderProductCard from '../../../../entities/Order/OrderProductCard/OrderProductCard';
import { OrderContactLocationStateContext, OrderIdContext } from './context';

const ContractSchedule = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    contactData,
    orderProductCards: initialCards,
    id,
  } = location.state || {};

  const [alertMessage, setAlertMessage] = useState('');

  const [orderProductCards, setOrderProductCards] =
    useState<IOrderCard[]>(initialCards);

  const crewQuantity = useMemo(() => {
    return orderProductCards.reduce<number>(
      (acc, value) => value.quantity + acc,
      0,
    );
  }, [orderProductCards]);
  const handleChangeNotes = (
    event: React.ChangeEvent<HTMLInputElement>,
    cardId: number,
  ) => {
    const updatedOrderProductCards = orderProductCards.map((card) => {
      if (card.id === cardId) {
        return {
          ...card,
          userNotes: event.target.value,
        };
      }
      return card;
    });

    setOrderProductCards(updatedOrderProductCards);
  };
  const handleDeleteProductCard = (id: number) => {
    setOrderProductCards((prevCards) => {
      const newProductCards = prevCards?.filter((card) => card.id !== id);
      return newProductCards;
    });
  };

  const handleNavigateToNextPage = useCallback(() => {
    if (orderProductCards?.some((product) => !product.contract)) {
      setAlertMessage('Please provide the required contract information.');
    } else {
      navigate('/summary-order', {
        state: {
          contactData,
          orderProductCards,
          id,
        },
      });
    }
  }, [id, orderProductCards, contactData]);

  const productNames = orderProductCards?.map(
    (card: IOrderCard) => card.product,
  );

  const productNamesString = useMemo(() => {
    return productNames?.join(', ');
  }, [productNames]);

  const stagesData = useMemo<StageData[]>(
    () => [
      {
        label: `${crewQuantity} Products Selected`,
        active: true,
        bold: true,
        link: '/select-product',
        secondLine: productNamesString,
        state: {
          contactData,
          orderProductCards,
          id,
        },
      },
      {
        label: 'Contract & Schedule',
        active: true,
        bold: true,
        link: '/contract-schedule',
        state: { id, contactData, orderProductCards },
      },
      {
        label: 'Review Summary Order',
        active: !!id,
        state: { id, contactData, orderProductCards },
        link: '/summary-order',
      },
    ],
    [crewQuantity, productNamesString, contactData, orderProductCards, id],
  );

  const handleChangeCard = (id: number, card: IOrderCard) => {
    setOrderProductCards(
      (prevCards) =>
        prevCards?.map((prevCard) => (prevCard.id === id ? card : prevCard)),
    );
  };

  const interviewShift = useMemo(() => {
    const emptyShift: IOrderSchedule = {
      date: '',
      startTime: '',
      endTime: '',
    };
    if (!orderProductCards.length) return emptyShift;

    const firstProduct = orderProductCards[0];
    const firstInterviewShift = firstProduct.interviewShifts[0];
    return firstInterviewShift || emptyShift;
  }, [orderProductCards]);

  return (
    <div className="contract-schedule-container">
      <TemplatePage>
        <div className="contract-schedule">
          <div className="contract-schedule-header">
            <OrderTitle title="Contract & Schedule" icon={money} />
            <div className="contract-btns">
              <Link to={'/orders'}>
                <BasicOrderButton label="Cancel" />
              </Link>
              <div className="contract-btns-next">
                <BasicOrderButton
                  label="Back"
                  onClick={() =>
                    navigate('/select-product', {
                      state: { contactData, orderProductCards, id },
                    })
                  }
                />
                <div
                  onClick={handleNavigateToNextPage}
                  style={{ cursor: 'pointer' }}>
                  <NextOrderButton label="Submit" />
                </div>
              </div>
            </div>
          </div>
          <OrderStagesPanel data={stagesData} />
          <div className="hr"></div>
          {alertMessage && (
            <div className="submit-validation-message">{alertMessage}</div>
          )}
          <div className="row">
            <div className="order-prod-list">
              <OrderContactLocationStateContext.Provider value={contactData}>
                <>
                  {orderProductCards?.map((card) => (
                    <OrderIdContext.Provider
                      key={card.id}
                      value={{
                        productCard: card,
                        handleChangeCard: (card: IOrderCard) => {
                          handleChangeCard(card.id, card);
                        },
                      }}>
                      <OrderProductCard
                        id={card.id}
                        icon={mapProductNameToIcon[card.product]}
                        position={card.product}
                        grade={card.grade}
                        price={card.price * card.quantity}
                        priceSingle={card.price}
                        quantity={card.quantity}
                        deliveryDate={card.deliveryDate}
                        interviewShift={interviewShift}
                        ordInv={card.ordInv}
                        femalesPerc={card.femalesPerc}
                        localsPerc={card.localsPerc}
                        salaryMinimum={card.salaryMinimum}
                        userNotes={card.userNotes}
                        handleDeleteProductCard={handleDeleteProductCard}
                        handleNotesChange={(event) =>
                          handleChangeNotes(event, card.id)
                        }
                      />
                    </OrderIdContext.Provider>
                  ))}
                </>
              </OrderContactLocationStateContext.Provider>
            </div>
            <MarketSalary cardsData={orderProductCards} />
          </div>
        </div>
      </TemplatePage>
    </div>
  );
};

export default ContractSchedule;
