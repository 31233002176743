import React from 'react';
import styles from './ContactInput.module.scss';
import { UseFormRegisterReturn } from 'react-hook-form';

type PropTypes = UseFormRegisterReturn & {
  label?: string;
  placeholder?: string;
  error?: string;
};

const ContactInput = React.forwardRef<HTMLInputElement, PropTypes>(
  (props: PropTypes, ref) => {
    const { label, placeholder, error, ...rest } = props;
    return (
      <div className={styles.contactControl}>
        <label className={styles.contactLabel} htmlFor="branch-input">
          {label}
        </label>
        <input
          className={styles.contactInput}
          id="branch-input"
          placeholder={placeholder}
          {...rest}
          ref={ref}
        />
        <div>{error}</div>
      </div>
    );
  },
);

ContactInput.displayName = 'Contact-Input';

export default ContactInput;
