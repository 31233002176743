import React from 'react';
import SummaryOrderCard from '../../../../entities/Order/SummaryOrderCard/SummaryOrderCard';
import { IOrderWithSKUResponse } from '../../../../entities/Order/order.models';
import { DEFAULT_DELIVERY_DATE } from '../../../../pages/CreateOrder/components/SummaryOrder/deliveryDate.const';

export type OrderCardsType = {
  skuRequests: IOrderWithSKUResponse[];
  orderStatus: string;
  orderData: any;
};

export const OrderCards = ({
  skuRequests,
  orderStatus,
  orderData,
}: OrderCardsType) => {
  return (
    <div className="summary-order-cards">
      {skuRequests?.map((skuReq) => {
        const currentSku = skuReq.sku;
        return (
          <SummaryOrderCard
            orderData={orderData}
            id={skuReq.id || 0}
            key={skuReq.id}
            position={currentSku!.productName}
            grade={currentSku.qualityGrade}
            price={currentSku!.pricePerUnit! * currentSku!.orderableInventory}
            deliveryDate={
              currentSku.deliveryDate
                ? currentSku.deliveryDate
                : DEFAULT_DELIVERY_DATE
            }
            priceSingle={currentSku?.pricePerUnit}
            finalQuantity={skuReq.crewsNumber}
            contract={skuReq.contract}
            femalesPerc={skuReq.femalesPercentage}
            interviewShifts={skuReq.interviewShifts}
            contact={skuReq.contact}
            tags={skuReq.tags}
            orderStatus={orderStatus}
          />
        );
      })}
    </div>
  );
};
