import React, { useEffect } from 'react';
import BasicOrderButton from '../Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import { orderApi } from '../../../entities/Order/api/orderApi';
import { useNavigate } from 'react-router-dom';
import { IOrderCard } from '../../../entities/Order/order.models';

export type Props = {
  orderId: number;
};

export const RepeatOrderButton = ({ orderId }: Props) => {
  const [trigger, { data, isSuccess }] =
    orderApi.endpoints.getOrderByIdWithRelations.useLazyQuery();
  const navigate = useNavigate();
  const handleRepeatOrder = () => {
    trigger({ id: orderId.toString() });
  };

  useEffect(() => {
    if (isSuccess && data && data.skuRequests.length) {
      const orderProductCards: IOrderCard[] = data.skuRequests.map(
        (skuRequest) => ({
          id: skuRequest.sku.id,
          femalesPerc: skuRequest.femalesPercentage,
          price: skuRequest.sku.pricePerUnit,
          quantity: skuRequest.crewsNumber,
          product: skuRequest.sku.productName,
          interviewShifts: skuRequest.interviewShifts,
          tags: skuRequest.tags,
          contract: {
            contractType: skuRequest.contract.contractType,
            maxSalary: skuRequest.contract.maxSalary,
            isHousing: skuRequest.contract.isHousing,
            femalesPercentage: skuRequest.femalesPercentage,
            localsPercentage: skuRequest.localsPercentage,
          },
          contact: data.contact,
          grade: skuRequest.sku.qualityGrade,
          ordInv: skuRequest.sku.orderableInventory,
          localsPercentage: skuRequest.localsPercentage,
        }),
      );
      const contactData = {
        selectedOrg: data.contact.branch?.organization?.name,
        selectedBranch: data.contact.branch?.organization?.name,
        selectedContact: data.contact.firstName + ' ' + data.contact.lastName,
        orgId: data.contact.branch?.organization?.id,
        branchId: data.contact.branch?.id,
        freePosition: data.useFreePositions,
        contact: data.contact,
      };
      navigate('/contract-schedule', {
        state: {
          orderProductCards,
          contactData,
        },
      });
    }
  }, [data, isSuccess, navigate]);

  return (
    <BasicOrderButton
      label="Repeat Order"
      isFilled
      type="button"
      onClick={handleRepeatOrder}
    />
  );
};
