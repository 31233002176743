import { useLiveQuery } from 'dexie-react-hooks';
import _ from 'lodash';
import { notificationsDb } from '@/services/IndexedDb/notificationDb';

export type DbNotification = {
  id: number;
  title: string;
  body: any;
};

export type UseNotificationProps = {
  onClear: () => void;
  onDelete: (id: number) => void;
  notifications: DbNotification[];
};

export const useNotifications = (): UseNotificationProps => {
  const notifications = useLiveQuery(async () => {
    return await notificationsDb.notifications.toArray();
  });

  if (!notifications) {
    return { notifications: [], onClear: () => {}, onDelete: () => {} };
  }

  const filteredNotifications = _.chain(notifications)
    .uniqBy((it) => it.body)
    .reduce<DbNotification[]>((acc: DbNotification[], val: any) => {
      try {
        const body = JSON.parse(val.body);
        return [
          ...acc,
          {
            id: val.id,
            title: val.title,
            body: body,
          },
        ];
      } catch (err) {
        return acc;
      }
    }, [])
    .value();
  return {
    notifications: filteredNotifications,
    onClear: () => {
      notificationsDb.notifications.clear();
    },
    onDelete: (id) => {
      notificationsDb.notifications.delete(id);
    },
  };
};
