import React from 'react';
// import './OrderCreated.scss';
import order from '../../assets/icons/orderPage/order.svg';
import { Link, useLocation } from 'react-router-dom';

import { format } from 'date-fns';
import { useGetOrderByIdWithRelationsQuery } from '../../entities/Order/api/orderApi';
import TemplatePage from '../TemplatePage/TemplatePage';
import OrderTitle from '../../shared/components/Title/OrderTitle/OrderTitle';
import OrderInformation from '../../widgets/Order/OrderInformation/OrderInformation';
import ClientSummary from '../../widgets/Order/ClientSummary/ClientSummary';
import TotalQuantity from '../../widgets/Order/TotalQuantity/TotalQuantity';
import SummaryTotal from '../../widgets/Order/SummaryTotal/SummaryTotal';
import NextOrderButton from '../../shared/components/Button/OrderButtons/NextOrderButton/NextOrderButton';
import { OrderCards } from './components/OrderCards/OrderCards';
import styles from './OrderCreated.module.scss';

const OrderCreated = () => {
  const location = useLocation();
  const { orderId, freePosition } = location.state || {};

  const { data: orderData, isSuccess } = useGetOrderByIdWithRelationsQuery({
    id: orderId,
  });

  if (!isSuccess) return <div></div>;

  const contactName = `${orderData?.contact?.firstName} 
  ${orderData?.contact?.lastName}`;
  const branchName = orderData.contact?.branch?.name || '';
  const orgName = orderData.contact?.branch?.organization?.name || '';
  const taxReg = Number(orderData.contact?.branch?.organization?.taxReg) || 1;

  const dateObject = new Date(orderData.createdAt ?? '');

  const totalQty = orderData.skuRequests?.reduce((sum: number, skuReq) => {
    return sum + skuReq.crewsNumber;
  }, 0);

  let orderCreatedDate = '';
  if (orderData) {
    orderCreatedDate = format(dateObject, 'dd.MM.yyyy HH:mm');
  }

  return (
    <div className={styles.orderCreatedContainer}>
      <TemplatePage>
        <div className={styles.orderCreated}>
          <div className={styles.header}>
            <OrderTitle icon={order} title="Order has been placed" />
            <div className={styles.subtitle}>
              The confirmation email has been sent. Waiting for the client’s
              response
            </div>
          </div>
          <div className={styles.orderCreatedContent}>
            <div className={styles.orderInfoClient}>
              <OrderInformation
                date={orderCreatedDate || 'undefined'}
                number={`ORD-${orderData.id || ''}`}
              />
              <ClientSummary
                contact={contactName}
                org={orgName}
                branch={branchName}
                taxReg={taxReg}
              />
            </div>
            <div className={styles.orderTotalCards}>
              <OrderCards
                orderStatus={orderData.orderStatus}
                skuRequests={orderData.skuRequests}
                orderData={orderData}
              />
              <TotalQuantity quantity={totalQty!} />
              <div className={styles.footer}>
                <SummaryTotal
                  tax={orderData.tax}
                  taxPercentage={orderData.taxPercentage}
                  subtotal={orderData.subtotal}
                  total={orderData.total}
                  freePosition={freePosition}
                />
                <Link to={'/create-order'}>
                  <NextOrderButton label="Make another order" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </TemplatePage>
    </div>
  );
};

export default OrderCreated;
