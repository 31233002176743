import { AdminTypes } from '@/entities/Admin/admin.models';
import * as yup from 'yup';

type AdminTypeToTitle = Record<AdminTypes, string>;

export const AdminTypeToTile: AdminTypeToTitle = {
  accountant: 'Financial Controller',
  ordinary: 'Relationship Manager',
  super: 'Product Manager',
};

export const adminYupSchema = yup.object({
  name: yup.string().required(),
  status: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
});

export type YupSchemaType = yup.InferType<typeof adminYupSchema>;
