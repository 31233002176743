import { useUpdateBranchMutation } from '@/entities/Branch/api/branchApi';
import { IBranch } from '@/entities/Branch/branch.models';
import RegOrgSection from '@/features/RegOrgSection/RegOrgSection';
import { branchInputs } from '@/pages/Organizations/RegisterOrg/regOrgInputs';
import SectionButton from '@/shared/components/Button/SectionButton/SectionButton';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback } from 'react';
import {
  FieldValues,
  SubmitHandler,
  UseFormRegister,
  useForm,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import 'yup-phone-lite';
import styles from './EditBranchForm.module.scss';

type Props = {
  data: IBranch;
};

const yupSchema = yup.object({
  branchName: yup.string().required('Branch name is required'),
  city: yup.string().required('Required field'),
  country: yup.string().required('Required field'),
  district: yup.string().required('Required field'),
  locationType: yup.string().required('Required field'),
  workplaceType: yup.string().required('Required field'),
  googleMapsLink: yup
    .string()
    .url('Invalid URL format')
    .required('Required field'),
});

type YupSchemaType = yup.InferType<typeof yupSchema>;

export const EditBranchForm = ({ data }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [updateBranch] = useUpdateBranchMutation();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<YupSchemaType>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      branchName: data.name,
      country: data.country,
      city: data.city,
      district: data.district,
      locationType: data.locationType,
      workplaceType: data.workplaceType,
    },
  });

  const onSubmitHandler: SubmitHandler<YupSchemaType> = useCallback(
    async (data) => {
      updateBranch({
        id: +id!,
        branchName: data.branchName,
        country: data.country,
        district: data.district,
        locationType: data.locationType,
        city: data.city,
        workplaceType: data.workplaceType,
        googleMapsLink: data.googleMapsLink,
      })
        .unwrap()
        .then(() => {
          navigate(-1);
        });
    },
    [getValues, data, navigate, updateBranch],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <RegOrgSection
        title="Edit Branch"
        inputEntries={branchInputs}
        register={register as unknown as UseFormRegister<FieldValues>}
        errors={errors}
        control={control}
        validate={() => {
          return trigger([
            'branchName',
            'country',
            'city',
            'district',
            'locationType',
            'workplaceType',
          ]);
        }}
      />
      <div className={styles.buttonSave}>
        <SectionButton label="Save" type="submit" isFilled={true} />
      </div>
    </form>
  );
};
