import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './EnumsAutocomplete.module.scss';

type Props = {
  control: Control<any>;
  name: string;
  placeholder?: string;
  selectOptions: Array<{ label: string; value: any }>;
  icon: any;
};

export const EnumsAutocomplete = ({
  control,
  name,
  placeholder,
  selectOptions,
  icon,
}: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <Autocomplete
          disablePortal
          options={selectOptions || []}
          onChange={(event, val) => {
            if (val) {
              onChange(val.value);
            }
            // TODO: fix
            if (val === null) {
              onChange(null);
            }
          }}
          onBlur={onBlur}
          value={selectOptions.find((option) => option.value === value)}
          popupIcon={<KeyboardArrowDownIcon />}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              helperText={error?.message}
              error={!!error?.message}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: '20px',
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '14px',
                    color: '#000',
                    opacity: 0.5,
                  },
                },
                className: styles.autocompleteInput,
                startAdornment: (
                  <div className={styles.startAdornment}>
                    {<img src={icon} width={24} height={24} />}
                  </div>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};
