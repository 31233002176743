import React from 'react';
import styles from './OrgContent.module.scss';
import orgIcon from '@/assets/icons/org-pink.svg';
import SectionButton from '../../shared/components/Button/SectionButton/SectionButton';
import plusIcon from '@/assets/icons/plus-blue.svg';
import OrgTable from '@/entities/Organization/OrgTable/OrgTable';

const OrgContent = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <img src={orgIcon} alt="icon" />
          <div className={styles.headerTextContainer}>
            <span className={styles.headerTitle}>Organisations</span>
            <span className={styles.headerSecondaryText}>
              Manage easily registered organisations here
            </span>
          </div>
        </div>

        <div className={styles.headerRight}>
          <SectionButton
            label="Add organisation"
            path="/register-org"
            icon={plusIcon}
          />
        </div>
      </div>

      <OrgTable />
    </div>
  );
};

export default OrgContent;
