import React, { useEffect, useMemo, useState } from 'react';
import './SummaryOrderCard.scss';
import SelectProductCard from '../SelectProductCard/SelectProductCard';
import { IOrderSummary, OrderStatuses } from '../order.models';
import SummaryBrochure from '../SummaryBrochure/SummaryBrochure';
import SummaryDetailsLine from '../SummaryDetailsLine/SummaryDetailsLine';
import calendar from '../../../assets/icons/orderPage/calendar-gray.svg';
import user from '../../../assets/icons/orderPage/user-gray.svg';
import info from '@/assets/icons/info.svg';
import { monthNames } from '../../../pages/CreateOrder/components/ContractSchedule/components/ScheduleForm/month.const';

const SummaryOrderCard = (props: IOrderSummary) => {
  const {
    orderId,
    orderStatus,
    id,
    icon,
    position,
    grade,
    price,
    deliveryDate,
    priceSingle,
    finalQuantity,
    contract,
    femalesPerc,
    contact,
    isSubmitted,
    tags,
    userNotes,
    handleDeleteProductCard,
    orderData,
  } = props;

  const { contractType, maxSalary, isHousing } = contract ?? {};

  const contactName = useMemo(
    () => contact?.firstName + ' ' + contact?.lastName,
    [contact],
  );

  const [displayDeliveryDate, setDisplayDeliveryDate] = useState(
    `${deliveryDate} days after payment`,
  );

  useEffect(() => {
    if (
      orderStatus == OrderStatuses.PENDING_FULFILLMENT ||
      orderStatus == OrderStatuses.FULFILLED
    ) {
      const date = new Date(
        orderData.payments.length > 0 && orderData.payments[0].actualDate
          ? orderData.payments[0].actualDate
          : '',
      );

      date.setHours(12, 0, 0, 0);
      date.setDate(date.getDate() + Number(deliveryDate));

      const day = date.getDate();
      const month = monthNames[date.getMonth()];

      setDisplayDeliveryDate(`${day} ${month}`);
    }
  }, [orderData]);

  return (
    <div className="summary-order-card">
      <SelectProductCard
        orderId={orderId}
        id={id}
        icon={icon}
        position={position}
        grade={grade}
        price={price}
        priceSingle={priceSingle}
        finalQuantity={finalQuantity}
        isSummary={true}
        isSubmitted={isSubmitted}
        onDeleteProductCard={handleDeleteProductCard}
      />
      <div className="hr"></div>
      <SummaryBrochure
        contractType={contractType || 'No contract type'}
        maxSalary={maxSalary || NaN}
        isHousing={isHousing || false}
        femalesPerc={femalesPerc}
      />
      <div className="hr"></div>
      <SummaryDetailsLine
        label="Estimated delivery"
        data={displayDeliveryDate}
        icon={calendar}
      />
      <div className="hr"></div>
      <SummaryDetailsLine
        label="Contact"
        data={contact ? contactName : 'No Contact'}
        icon={user}
      />
      {tags.length > 0 && (
        <>
          <div className="hr"></div>
          <SummaryDetailsLine
            label="Tags"
            data={tags.map((el) => el.name).join(', ')}
            icon={info}
          />
        </>
      )}

      {userNotes && (
        <>
          <div className="hr"></div>
          <div className="user-notes">
            <div className="user-notes-label">User Notes</div>
            <div className="user-notes-value">{userNotes}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default SummaryOrderCard;
