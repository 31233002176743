import React, { useState } from 'react';
import styles from '../PaymentForm/PaymentForm.module.scss';
import BillingClientSummary from '../BillingClientSummary/BillingClientSummary';
import { useNavigate, useParams } from 'react-router-dom';
import backIcon from '../../../../assets/icons/arrow-left-grey.svg';
import tax from '../../../../assets/icons/billing/tax.svg';
import BasicOrderButton from '../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import TemplatePage from '../../../TemplatePage/TemplatePage';
import FormTitle from '../FormTitle/FormTitle';
import {
  useAddReceiptInvoiceMutation,
  useGetInvoiceByIdQuery,
} from '../../../../entities/transactEntities/Invoice/api/invoiceApi';
import moment from 'moment';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { IInvoiceItem } from '@/entities/transactEntities/Invoice/invoice.models';
import { getOrganizationName, truncateString } from '../../helpers';
import { formatNumber } from '@/shared/helpers/formatNumber';

export interface AddFileInvoicePayload {
  id: any;
  file: File;
}

export const InvoicesForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    return null;
  }

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const {
    data: invoiceData,
    isSuccess,
    isLoading,
  } = useGetInvoiceByIdQuery({
    id: `${id}`,
    relations: ['order.contact.branch.organization.relationshipManager'],
  });

  const [updateInvoice] = useAddReceiptInvoiceMutation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
  };

  const submitHandler = async () => {
    updateInvoice({ id: +id, file: selectedFile! })
      .then(() => {
        navigate('/billing');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error addReceiptInvoice:', error);
      });
  };

  const date =
    invoiceData && moment(invoiceData.createdAt).format('DD MMM, YYYY');

  const totalPerItem =
    invoiceData &&
    invoiceData.invoiceItems.map(
      (item: IInvoiceItem) => item.count * item.pricePerUnit,
    );
  const totalSum = totalPerItem
    ? totalPerItem.reduce((acc: number, curr: number) => acc + curr, 0)
    : 0;

  return (
    <TemplatePage>
      <>
        <div>
          {isLoading && (
            <div className={styles.loader}>
              <CircularProgress />
            </div>
          )}
          {isSuccess && invoiceData.order.contact?.branch && (
            <>
              <FormTitle title="Upload Tax invoice" icon={backIcon} />
              <div className={styles.paymentFormContent}>
                <BillingClientSummary
                  orgName={getOrganizationName(invoiceData)}
                  orgType={invoiceData.order.contact?.branch?.locationType}
                  orderId={invoiceData.order.id}
                  orderDate={invoiceData.order.createdAt!}
                  manager={
                    invoiceData.order.contact?.branch?.organization
                      ?.relationshipManager.name || '-'
                  }
                  amount={invoiceData.amount}
                />
                <div className={styles.paymentReceipt}>
                  <div className={styles.paymentTitleContainer}>
                    <img className={styles.paymentIcon} src={tax} alt="icon" />
                    <div className={styles.paymentTitle}>Tax detail</div>
                  </div>

                  <div className={styles.paymentInputs}>
                    <div className={styles.paymentInput}>
                      <label
                        className={styles.paymentInputLabel}
                        htmlFor="receipt">
                        Invoice Number
                      </label>
                      <input
                        id="receipt"
                        className={styles.paymentInputReceipt}
                        type="text"
                        defaultValue={invoiceData.id}
                        readOnly
                      />
                    </div>

                    <div className={styles.paymentInput}>
                      <label
                        className={styles.paymentInputLabel}
                        htmlFor="date">
                        Invoice Date
                      </label>
                      <input
                        id="date"
                        className={styles.paymentInputReceipt}
                        type="text"
                        defaultValue={date}
                        readOnly
                      />
                    </div>
                    <div className={styles.invoiceItems}>
                      <TableContainer>
                        <Table sx={{ minWidth: 400 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell padding="none">
                                <div className={styles.invoiceTitle}>Item</div>
                              </TableCell>
                              <TableCell padding="none">
                                <div className={styles.invoiceTitle}>Qty</div>
                              </TableCell>
                              <TableCell padding="none">
                                <div className={styles.invoiceTitle}>
                                  Price/unit
                                </div>
                              </TableCell>
                              <TableCell padding="none">
                                <div className={styles.invoiceTitle}>Total</div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoiceData.invoiceItems.map(
                              (invoiceItem: IInvoiceItem, index: number) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    '&:not(:last-child) td': {
                                      border: 0,
                                    },
                                  }}>
                                  <TableCell padding="none">
                                    <div className={styles.invoiceItem}>
                                      {truncateString(invoiceItem.name, 25)}
                                    </div>
                                  </TableCell>
                                  <TableCell padding="none">
                                    <div className={styles.invoiceItem}>
                                      {invoiceItem.count}
                                    </div>
                                  </TableCell>
                                  <TableCell padding="none">
                                    <div className={styles.invoiceItem}>
                                      SAR {invoiceItem.pricePerUnit}
                                    </div>
                                  </TableCell>
                                  <TableCell padding="none">
                                    <div className={styles.invoiceItem}>
                                      SAR{' '}
                                      {invoiceItem.count *
                                        invoiceItem.pricePerUnit}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ),
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div className={styles.paymentSummary}>
                      <div className="summary-item">
                        <div className="summary-label">Total</div>
                        <div className="summary-data data-total">
                          SAR {formatNumber(totalSum)}
                        </div>
                      </div>
                    </div>
                    <hr className={styles.hr} />
                    <div className={styles.paymentAddReceipt}>
                      <div className={styles.paymentAddReceiptLabel}>
                        Upload invoice
                      </div>
                      <div>
                        <label
                          htmlFor="receiptInput"
                          className={styles.customFileUpload}>
                          <input
                            type="file"
                            id="receiptInput"
                            accept=".pdf"
                            required
                            className={styles.paymentAddReceiptButton}
                            onChange={handleFileChange}
                          />
                          Upload
                        </label>
                        <div className={styles.errorBlockReceipt}>
                          {selectedFile && (
                            <span className={styles.paymentAddReceiptFile}>
                              {selectedFile.name}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr className={styles.hr} />
                    <div className={styles.paymentButtonsWrap}>
                      <BasicOrderButton
                        label="Cancel"
                        onClick={() => navigate(-1)}
                      />
                      <BasicOrderButton
                        label="Save"
                        isPink={true}
                        type="submit"
                        onClick={submitHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </TemplatePage>
  );
};
