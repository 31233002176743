import React from 'react';
import './OrgContainer.scss';
import TemplatePage from '../TemplatePage/TemplatePage';
import OrgContent from '../../widgets/OrgContent/OrgContent';

const OrgContainer = () => {
  return (
    <div className="org-container">
      <TemplatePage>
        <OrgContent />
      </TemplatePage>
    </div>
  );
};

export default OrgContainer;
