import React, { useCallback, useState } from 'react';
import './SummaryOrderClient.scss';
import { useParams } from 'react-router-dom';
import { statusTitles } from './utils/map.status';
import {
  useCancelOrderMutation,
  useConfirmOrderMutation,
  useGetOrderByIdWithRelationsQuery,
} from '../../entities/Order/api/orderApi';
import orderIcon from '../../assets/icons/orderPage/contract-pink.svg';
import ClientSummary from '../../widgets/Order/ClientSummary/ClientSummary';
import OrderTitle from '../../shared/components/Title/OrderTitle/OrderTitle';
import TotalQuantity from '../../widgets/Order/TotalQuantity/TotalQuantity';
import SectionButton from '../../shared/components/Button/SectionButton/SectionButton';
import OrderInformation from '../../widgets/Order/OrderInformation/OrderInformation';
import { format } from 'date-fns';
import SummaryOrderList from './components/SummaryOrderList/SummaryOrderList';
import SummaryTotal from '../../widgets/Order/SummaryTotal/SummaryTotal';
import userIcon from '@/assets/icons/user.svg';
import phoneIcon from '@/assets/icons/phone.svg';
import { OrderStatuses } from '@/entities/Order/order.models';

const SummaryOrderClient = () => {
  const [showOrderDetails, setShowOrderDetails] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showCancellation, setShowCancellation] = useState(false);

  const { id } = useParams();
  if (!id) {
    return null;
  }
  const encodedId = encodeURIComponent(id);
  const { data: order } = useGetOrderByIdWithRelationsQuery({
    id: encodedId,
  });

  const [submitService] = useConfirmOrderMutation();
  const [cancelService] = useCancelOrderMutation();

  const handleConfirm = useCallback(async () => {
    submitService({ id });
    setShowConfirmation(true);
  }, [submitService]);

  const handleCancel = useCallback(async () => {
    cancelService({ id });
    setShowCancellation(true);
  }, []);

  if (!order) return <div></div>;
  const title = statusTitles[order.orderStatus];

  const totalQty = order.skuRequests.reduce((sum: number, skuReq) => {
    return sum + skuReq.crewsNumber;
  }, 0);
  const createdAt = format(new Date(order!.createdAt!), 'dd.MM.yyyy HH:mm');
  return (
    <div>
      {showOrderDetails && (
        <div>
          <div className="title">
            <OrderTitle icon={orderIcon} title={title} />
          </div>
          <div className="info-block">
            <div className="info-block-left">
              <OrderInformation
                date={createdAt}
                number={`ORD-${order?.id || ''}`}
              />
              <ClientSummary
                contact={`${order.contact.firstName} ${order.contact.lastName}`}
                org={order.contact.branch?.organization?.name ?? ''}
                branch={order.contact.branch?.name ?? ''}
                taxReg={
                  Number(order?.contact?.branch?.organization?.taxReg) ?? 0
                }
              />
            </div>
            <div className="summary-total-cards">
              <SummaryOrderList
                key={id}
                orderStatus={order.orderStatus}
                skuRequests={order.skuRequests}
                orderData={order}
              />
              <div className="total-quantity-bar">
                <TotalQuantity quantity={totalQty || 0} />
              </div>
              <div className="summary-total-container">
                <SummaryTotal
                  subtotal={order.subtotal}
                  tax={order.tax}
                  taxPercentage={order.taxPercentage}
                  total={order.total}
                  freePosition={order.useFreePositions}
                />
              </div>
              <div className="client-button">
                {order?.orderStatus === OrderStatuses.PENDING_CONFIRMATION && (
                  <SectionButton
                    label={order.useFreePositions ? 'Confirm' : 'Pay'}
                    isFilled={true}
                    className="pink"
                    onClick={() => {
                      setShowOrderDetails(false);
                      handleConfirm();
                    }}
                  />
                )}

                {order?.orderStatus === OrderStatuses.PENDING_CONFIRMATION && (
                  <SectionButton
                    className="summary-cancel-button"
                    label="Cancel"
                    onClick={() => {
                      setShowOrderDetails(false);
                      handleCancel();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showConfirmation && (
        <div className="confirmation-container">
          <div className="confirmation-window">
            <h2 className="confirmation-header">Payment registered</h2>
            <div className="confirmation-content">
              <p className="confirmation-descr">
                {order.useFreePositions ? (
                  <>
                    Thank you for confirming your order #{order.id}. Your
                    interviews are scheduled in 7 days.
                    <br />
                    <br />
                  </>
                ) : (
                  <>
                    Thank you for confirming your order #{order.id}. We kindly
                    request your payment of the retainer in order to proceed to
                    fulfillment of your order.
                    <br />
                    <br />
                  </>
                )}
                If you have any questions, please feel free to speak to your
                relationship manager:
              </p>
              <div className="confirmation-contacts">
                <img src={userIcon} alt="person" />
                <p>
                  {`${order.contact.firstName} 
                  ${order.contact.lastName}`}
                </p>
              </div>

              <div className="confirmation-contacts">
                <img src={phoneIcon} alt="phone" />
                <p>{order.contact.phone}</p>
              </div>
            </div>
            <p className="confirmation-bottom">Thank you for your business.</p>
            <p className="confirmation-company">Crew KSA</p>
          </div>
        </div>
      )}
      {showCancellation && (
        <div className="confirmation-container">
          <div className="confirmation-window">
            <h2 className="confirmation-header">Quote canceled</h2>
            <div className="confirmation-content">
              <p className="confirmation-descr">
                Your cancelation is recorded. Feel free to contact your customer
                relationship manager if you have any questions:
              </p>
              <div className="confirmation-contacts">
                <img src={userIcon} alt="person" />
                <p>
                  {`${order.contact.firstName} 
                ${order.contact.lastName}`}
                </p>
              </div>

              <div className="confirmation-contacts">
                <img src={phoneIcon} alt="phone" />
                <p>{order.contact.phone}</p>
              </div>
            </div>
            <p className="confirmation-bottom">Best regards,</p>
            <p className="confirmation-company">Crew KSA</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SummaryOrderClient;
