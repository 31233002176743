import { IBranch, IBranchCreateRequest } from '../Branch/branch.models';

export interface IOrganization {
  id: number;
  balance: number;
  balanceOnHold: number;
  branches: IBranch[];
  createdAt: Date;
  creditLimit?: number;
  employeesAmount?: number;
  name: string;
  taxReg: number;
  freePositions: number;
  relationshipManager: IRelationshipManager;
  industry: string;
  qualityGrade?: number;
}

export interface IUpdateOrganizationRequest {
  id: number;
  name: string;
  employeesAmount: number;
  taxReg?: number;
  relationshipManager: number;
  industry: string;
  qualityGrade?: number;
}

export interface ICreateOrganizationRequest {
  branches: IBranchCreateRequest[];
  employeesAmount: number;
  name: string;
  relationshipManager: number;
  taxReg?: number;
  creditLimit: number;
  industry: string;
  qualityGrade?: number;
}

export interface IRelationshipManager {
  id: number;
  name: string;
}

export enum IndustryEnum {
  AGRICULTURE_FORESTRY_FISHING = 'Agriculture, forestry and fishing',
  MINING_QUARRYING = 'Mining and quarrying',
  MANUFACTURING = 'Manufacturing',
  ELECTRICITY = 'Electricity, gas, steam and air conditioning supply',
  WATER_SUPPLY = 'Water supply; sewerage, waste management and remediation',
  WHOSALE_RETAIL = 'Wholesale and retail trade; motor vehicles repair',
  TRANSPORTATION = 'Transportation and storage',
  ACCOMMODATION = 'Accommodation and food service activities',
  INFORMATION_COMMUNICATION = 'Information and communication',
  FINANCIAL_ACTIVITIES = 'Financial and insurance activities',
  ESTATE_ACTIVITIES = 'Real estate activities',
  PROFESSIONAL_TECHNICAL_ACTIVITIES = 'Professional, scientific and technical activities',
  ADMINISTRATIVE_SERVICE = 'Administrative and support service activities',
  ADMINISTRATION_DEFENSE = 'Public administration and defense; compulsory social security',
  EDUCATION = 'Education',
  HUMAN_HEALTH = 'Human health and social work activities',
  ARTS_ENTERTAINMENT = 'Arts, entertainment and recreation',
  OTHER_SERVICE = 'Other service activities',
  ACTIVITIES_OF_HOURSEHOLDS = 'Activities of households as employers',
  ACTIVITIES_OF_EXTRATERRITORIAL_ORG = 'Activities of extraterritorial organizations and bodies',
}

export const IEmployeesAmountTypes = [
  { label: 'Micro', value: 'Micro' },
  { label: 'Small', value: 'Small' },
  { label: 'Medium', value: 'Medium' },
  { label: 'Large', value: 'Large' },
  { label: 'Enterprise', value: 'Enterprise' },
];

export const IEmployeesAmountNumbersTypes = [
  { label: 'Micro', value: 5 },
  { label: 'Small', value: 49 },
  { label: 'Medium', value: 499 },
  { label: 'Large', value: 2999 },
  { label: 'Enterprise', value: 3000 },
];

export enum EmployeesAmountTypes {
  MICRO = 'Micro',
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  ENTERPRISE = 'Enterprise',
}
