import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../shared/api/api';
import {
  ICreateAdminRequest,
  IAdmin,
  IUpdateAdminRequest,
  getToken,
  IAdminFireBase,
} from '../admin.models';
import { IDataWithPagination } from '@/entities/Order/order.models';

const adminConfig = {
  ...baseQueryConfig,
  baseUrl: baseQueryConfig.baseUrl + '/admin',
  prepareHeaders: (headers: Headers) => {
    const session = getToken();
    headers.set('Authorization', `${session.token}`);
    return headers;
  },
};

export const adminApi = createApi({
  reducerPath: 'adminApi',
  tagTypes: ['Admin'],
  baseQuery: fetchBaseQuery(adminConfig),
  endpoints: (builder) => ({
    getAdmins: builder.query<
      IDataWithPagination<IAdmin>,
      {
        page?: number;
        take?: number;
        searchByName?: string;
      }
    >({
      query: ({ page, take, searchByName }) => ({
        url: '',
        params: {
          page,
          take,
          name: searchByName,
        },
      }),
      providesTags: ['Admin'],
      transformResponse: (response: any) => {
        const data = response.data;
        return {
          ...response,
          data: data.map((admin: IAdmin) => {
            if (admin.phone) {
              admin.phone = admin.phone.replace('+', '');
            }
            return admin;
          }),
        };
      },
    }),
    getAdminById: builder.query<IAdmin, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ['Admin'],
    }),
    getAdminByUuId: builder.query<IAdminFireBase, string>({
      query: (uuid: string) => ({
        url: `/`,
        params: {
          firebaseUid: uuid,
        },
      }),
      transformResponse: (response: any) => {
        const data = response.data.length ? response.data[0] : null;
        return data;
      },
      providesTags: ['Admin'],
    }),
    createAdmin: builder.mutation<void, ICreateAdminRequest>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Admin'],
    }),
    updateAdmin: builder.mutation<void, IUpdateAdminRequest>({
      query: (data) => ({
        url: `/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Admin'],
    }),
    deleteAdmin: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Admin'],
    }),
    triggerAdmin: builder.mutation<any, { email: string; uid: string }>({
      query: ({ email, uid }) => ({
        url: '/trigger',
        method: 'POST',
        body: { email, uid },
      }),
      invalidatesTags: ['Admin'],
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useCreateAdminMutation,
  useDeleteAdminMutation,
  useGetAdminByIdQuery,
  useUpdateAdminMutation,
  useTriggerAdminMutation,
  useGetAdminByUuIdQuery,
} = adminApi;
