import React, { useState } from 'react';
import CustomerCard from '../../entities/Customer/CustomerCard/CustomerCard';
import './CustomerCards.scss';
import FilterTabButton from '../../shared/components/Button/FilterTabButton/FilterTabButton';
import CustomerFilters from '../../features/Filters/CustomerFilters/CustomerFilters';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  CustomerCareStatuses,
  ICustomerCare,
  useGetCustomerCareQuery,
} from '../../entities/Customer/api/customerApi';

const filters = {
  needsHandling: 'Needs Handling',
  handled: 'Handled',
  all: 'All',
};

const CustomerCards = () => {
  const [page, setPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState('All');
  const [externalFilter, setExternalFilter] = useState({
    organization: '',
    location: '',
  });
  const { organization: organizationFilter, location: locationFilter } =
    externalFilter;

  const { data: customerCareData, isSuccess } = useGetCustomerCareQuery({
    relations: ['order.contact.branch.organization'],
    take: 10,
    page,
    sort: 'id:desc',
  });

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) =>
    setPage(page);

  const handleFilterChange = (newFilter: string) => {
    setStatusFilter(newFilter);
  };

  const handleExternalFiltersChange = (
    organization: string,
    location: string,
  ) => {
    setExternalFilter({ organization, location });
  };

  const filterCards = (card: ICustomerCare) => {
    if (statusFilter === filters.all) {
      return true;
    }
    const status =
      card.status === CustomerCareStatuses.PENDING
        ? 'Needs Handling'
        : 'Handled';
    return status === statusFilter;
  };

  const filterByExternal = (card: ICustomerCare) => {
    const organizationMatches =
      !organizationFilter ||
      card.order.contact?.branch?.organization?.name === organizationFilter;
    const locationMatches =
      !locationFilter ||
      card.order?.contact?.branch?.country === locationFilter;
    return organizationMatches && locationMatches;
  };

  const filteredData = customerCareData?.data
    .filter(filterCards)
    .filter(filterByExternal);

  return (
    <>
      {isSuccess && (
        <>
          <div className="customer-cards-container">
            <div className="customer-filters-section">
              <div className="customer-filters-status">
                {Object.values(filters).map((filter) => (
                  <FilterTabButton
                    key={filter}
                    title={filter}
                    onClick={() => handleFilterChange(filter)}
                    isActive={statusFilter === filter}
                  />
                ))}
              </div>
              <CustomerFilters onFilterChange={handleExternalFiltersChange} />
            </div>
            <div className="customer-cards">
              <>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Organization</TableCell>
                        <TableCell>Rewiev</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData?.map((card) => (
                        <TableRow
                          key={card.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}>
                          <CustomerCard
                            key={card.id}
                            id={card.id}
                            orgName={
                              card.order.contact?.branch?.organization?.name
                            }
                            rewievValue={card.valueStars}
                            createdDate={card.createdAt}
                            status={card.status}
                          />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            </div>
          </div>
          <Pagination
            hidden={customerCareData.meta.pageCount <= 1}
            page={page}
            onChange={handlePageChange}
            count={customerCareData.meta.pageCount}
          />
        </>
      )}
    </>
  );
};

export default CustomerCards;
