import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../shared/api/api';
import {
  CancelPositionDTO,
  IDataWithPagination,
  IOrder,
  IOrderUpdateRequest,
  IOrderWithRelations,
  IOrderWithSkuRequestContactBranchOrganization,
  IOrdersWithPagination,
} from '../order.models';
import { RootState } from '../../../store/store';

export const orderApi = createApi({
  reducerPath: 'OrderApi',
  tagTypes: ['Order'],
  baseQuery: fetchBaseQuery({
    ...baseQueryConfig,
    baseUrl: baseQueryConfig.baseUrl + '/orders',
    prepareHeaders: async (headers: Headers, { getState }) => {
      // If we have a token set in state, let's assume that we should be passing it.
      const token = await (getState() as RootState).user.user?.getIdToken();
      token && headers.set('Authorization', token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOrdersWithFilters: builder.query<
      IOrdersWithPagination,
      {
        sort?: string;
        take?: number;
        page?: number;
        startDate?: string;
        endDate?: string;
        status?: string | string[];
        organization?: string | string[];
        orderId?: string;
        organizationSize?: string[];
        relationshipManagerId?: number | number[];
        searchBy?: string;
      }
    >({
      providesTags: ['Order'],
      query: ({
        sort,
        take,
        page,
        organization,
        startDate,
        endDate,
        status,
        orderId,
        organizationSize,
        relationshipManagerId,
        searchBy,
      }) => {
        const organizationQuery = Array.isArray(organization)
          ? organization
              .map((org) => `&contact.branch.organization.name=${org}`)
              .join('')
          : organization
            ? `&contact.branch.organization.name=${organization}`
            : '';

        const statusQuery = Array.isArray(status)
          ? status.map((s) => `&orderStatus=${s}`).join('')
          : status
            ? `&orderStatus=${status}`
            : '';

        const organizationSizeQuery = Array.isArray(organizationSize)
          ? organizationSize
              .map(
                (size) =>
                  `&contact.branch.organization.organizationSize=${size}`,
              )
              .join('')
          : organizationSize
            ? `&contact.branch.organization.employeesAmountMin=${organizationSize}`
            : '';

        const relationshipManagerIdQuery = Array.isArray(relationshipManagerId)
          ? relationshipManagerId
              .map(
                (manager) =>
                  `&contact.branch.organization.relationshipManager.id=${manager}`,
              )
              .join('')
          : relationshipManagerId
            ? `&contact.branch.organization.relationshipManager.id=${relationshipManagerId}`
            : '';

        return (
          `?relations=contact.branch.organization` +
          `&relations=skuRequests` +
          `&relations=skuRequests.interviewShifts` +
          `&relations=skuRequests.contact` +
          `&showSkus=true` +
          `&relations=contact.branch.organization.relationshipManager` +
          `${sort ? '&sort=' + sort : ''}` +
          `${take ? '&take=' + take : ''}` +
          `${page ? '&page=' + page : ''}` +
          `${organizationQuery}` +
          `${startDate ? `&createdAtMin=${startDate}` : ''}` +
          `${endDate ? `&createdAtMax=${endDate}` : ''}` +
          `${statusQuery}` +
          `${orderId ? `&id=${orderId}` : ''}` +
          `${organizationSizeQuery}` +
          `${relationshipManagerIdQuery}` +
          `${searchBy ? `&searchBy=${searchBy}` : ''}`
        );
      },
    }),
    getOrdersByOrganizationId: builder.query<
      IOrdersWithPagination,
      { sort?: string; take?: number; page?: number; organizationId?: number }
    >({
      providesTags: ['Order'],
      query: ({ sort, take, page, organizationId }) =>
        `?relations=contact.branch.organization.relationshipManager&relations=skuRequests&relations=skuRequests.contact${
          (sort ? '&sort=' + sort : '') +
          (take ? '&take=' + take : '') +
          (page ? '&page=' + page : '') +
          (organizationId
            ? '&contact.branch.organization.id=' + organizationId
            : '')
        }`,
    }),
    getOrders: builder.query<
      IDataWithPagination<IOrder>,
      {
        relations?: string[];
        sortField?: string;
        sortOrder?: string;
        take?: number;
        page?: number;
        organizationId?: number;
        showSkus?: boolean;
      }
    >({
      providesTags: ['Order'],
      query: ({
        relations = [],
        sortField,
        sortOrder,
        take,
        organizationId,
        showSkus,
      }) => {
        let url = '?';

        if (relations.length > 0) {
          relations.forEach(
            (relation) => (url += 'relations=' + relation + '&'),
          );
          url = url.slice(0, -1);
        }

        if (sortField && sortOrder) {
          url += `${
            relations.length > 0 ? '&' : '?'
          }sort=${sortField}:${sortOrder}`;
        }

        if (take) {
          url += `${
            relations.length > 0 || (sortField && sortOrder) ? '&' : '?'
          }take=${take}`;
        }

        if (organizationId) {
          url += `${
            url.includes('?') ? '&' : '?'
          }contact.branch.organization.id=${organizationId}`;
        }

        if (showSkus !== undefined) {
          url += `&showSkus=${showSkus}`;
        }

        return { url };
      },
    }),

    getOrderByIdWithSkuRequestsContactBranchOrganization: builder.query<
      IOrderWithSkuRequestContactBranchOrganization,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/${id}?relations=skuRequests&relations=contact.branch.organization`,
      }),
      providesTags: ['Order'],
    }),

    getOrderById: builder.query<any, { id: string; relations?: string[] }>({
      query: ({ id, relations }) => ({
        url: `/${id}`,
        params: {
          relations: [relations],
        },
      }),
      providesTags: ['Order'],
    }),
    getOrderByIdWithRelations: builder.query<
      IOrderWithRelations,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/${id}?relations=contact.branch.organization&relations=skuRequests.contact&relations=skuRequests.interviewShifts&relations=payments`,
      }),
      providesTags: ['Order'],
    }),
    createOrder: builder.mutation<{ id: number }, any>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Order'],
    }),
    updateOrder: builder.mutation<any, IOrderUpdateRequest>({
      query: ({ id, skuRequests }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: { skuRequests },
      }),
      invalidatesTags: ['Order'],
    }),
    deleteOrder: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Order'],
    }),
    confirmOrder: builder.mutation<void, { id?: string }>({
      query: ({ id }) => ({
        url: `/${id}/confirm`,
        method: 'GET',
      }),
    }),
    confirmOrderByAdmin: builder.mutation<void, { id?: string }>({
      query: ({ id }) => ({
        url: `/${id}/confirm/admin`,
        method: 'GET',
      }),
      invalidatesTags: ['Order'],
    }),
    cancelOrderWithRefundPolicy: builder.mutation<
      void,
      { id?: string; refundPolicy?: string }
    >({
      query: ({ id, refundPolicy }) => ({
        url: `/${id}/cancel?refundPolicy=${refundPolicy}`,
        method: 'GET',
      }),
    }),
    cancelOrder: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}/cancel`,
        method: 'GET',
      }),
    }),
    getOrderChartAmount: builder.query<
      Array<{ key: string; value: number }>,
      { startDate: string; endDate: string; timeUnit: string }
    >({
      query: ({ startDate, endDate, timeUnit }) => ({
        url: '/chart/amount-from-time',
        params: {
          startDate,
          endDate,
          timeUnit,
        },
      }),
    }),
    getOrderChartRating: builder.query<
      Array<{ key: string; value: number }>,
      { startDate: string; endDate: string; timeUnit: string }
    >({
      query: ({ startDate, endDate, timeUnit }) => ({
        url: '/chart/rating-from-time',
        params: {
          startDate,
          endDate,
          timeUnit,
        },
      }),
    }),
    cancelPositions: builder.mutation<
      void,
      {
        id: number;
        cancelPositionDTO: CancelPositionDTO;
      }
    >({
      query: ({ id, cancelPositionDTO }) => ({
        url: `/${id}/cancel-positions`,
        method: 'POST',
        body: cancelPositionDTO,
      }),
      invalidatesTags: ['Order'],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useCreateOrderMutation,
  useDeleteOrderMutation,
  useGetOrderByIdQuery,
  useUpdateOrderMutation,
  useConfirmOrderMutation,
  useConfirmOrderByAdminMutation,
  useCancelOrderMutation,
  useCancelOrderWithRefundPolicyMutation,
  useGetOrderChartAmountQuery,
  useGetOrderChartRatingQuery,
  useGetOrderByIdWithRelationsQuery,
  useGetOrderByIdWithSkuRequestsContactBranchOrganizationQuery,
  useGetOrdersWithFiltersQuery,
  useGetOrdersByOrganizationIdQuery,
  useCancelPositionsMutation,
} = orderApi;
