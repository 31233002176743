import React, { useEffect, useState } from 'react';
import { orderApi } from '../../../entities/Order/api/orderApi';
import { Skeleton } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import styles from './OrderCards.module.scss';
import moment from 'moment';
import { statusDisplayName } from '@/pages/Dashboard/components/Stats/components/OrdersTable/OrdersTable';
import { Pagination } from '@/shared/components/Pagination/Pagination';
import { Link } from 'react-router-dom';
import { OrderInfo } from '../OrderInfo/OrderInfo';
import {
  FiltersFormData,
  OrderFilters,
  initialSearchQuery,
} from '../OrderFilters/OrderFilters';
import { formatNumber } from '@/shared/helpers/formatNumber';
import { OrderStatuses } from '@/entities/Order/order.models';
type Props = {
  selectedDate: any;
};

const OrderCards = ({ selectedDate }: Props) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState<FiltersFormData>(initialSearchQuery);

  const [trigger, result] =
    orderApi.endpoints.getOrdersWithFilters.useLazyQuery();

  const { data: ordersData, isLoading, isSuccess } = result;

  useEffect(() => {
    const startDate = selectedDate
      ? new Date(selectedDate[0]).toISOString()
      : undefined;
    const endDate = selectedDate
      ? new Date(selectedDate[1]).toISOString()
      : undefined;

    trigger({
      take: rowsPerPage,
      page,
      sort: 'id:desc',
      searchBy: filters.genericSearch,
      organization: filters.organization,
      status: filters.status,
      relationshipManagerId: filters.relationshipManager,
      startDate,
      endDate,
    });
  }, [page, rowsPerPage, filters, selectedDate]);

  const handleInputChange = (value: string): void => {
    setFilters((prevFilters) => ({ ...prevFilters, genericSearch: value }));
  };

  const handleStatusChange = (values: string[]): void => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: values,
    }));
  };

  const handleOrganizationChange = (values: string[]): void => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      organization: values,
    }));
  };

  const handleRelationshipManagerChange = (values: number[]): void => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      relationshipManager: values,
    }));
  };

  return (
    <>
      <OrderFilters
        handleInputChange={handleInputChange}
        handleOrganizationChange={handleOrganizationChange}
        handleRelationshipManagerChange={handleRelationshipManagerChange}
        handleStatusChange={handleStatusChange}
      />
      {isSuccess && <OrderInfo selectedDate={selectedDate} filters={filters} />}
      <div className={styles.base}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Requested</TableCell>
                <TableCell>RM</TableCell>
                <TableCell>Order #</TableCell>
                <TableCell>Organisation</TableCell>
                <TableCell>Branch</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading &&
                Array(10)
                  .fill(0)
                  .map((_, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                    </TableRow>
                  ))}
              {isSuccess &&
                ordersData.data.map((order, index) => (
                  <TableRow
                    key={order.id}
                    className={`${styles.row} ${
                      index % 2 === 0 ? styles.colorRow : styles.whiteRow
                    }`}
                    component={Link}
                    to={`/view-order/${order.id}`}>
                    <TableCell
                      sx={{
                        color: '#141A7F',
                      }}>
                      {moment(order.createdAt).format('DD MMM')}
                    </TableCell>
                    <TableCell>
                      {
                        order.contact.branch?.organization?.relationshipManager
                          .name
                      }
                    </TableCell>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>
                      {order.contact.branch?.organization?.name}
                    </TableCell>
                    <TableCell>{order.contact.branch?.name}</TableCell>
                    <TableCell>
                      {order.skuRequests.reduce<number>(
                        (acc, item) => acc + item.crewsNumber,
                        0,
                      )}
                    </TableCell>
                    <TableCell>SAR {formatNumber(order.total)}</TableCell>
                    <TableCell>
                      {order.orderStatus ===
                        OrderStatuses.PENDING_FULFILLMENT &&
                      order.orderStatus === OrderStatuses.PENDING_FULFILLMENT
                        ? 'Pending Fulfillment'
                        : statusDisplayName[order.orderStatus]}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isSuccess && (
          <Pagination
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalItems={ordersData.meta.itemCount}
          />
        )}
      </div>
    </>
  );
};

export default OrderCards;
