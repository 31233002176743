import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import EmailLink from '../../../shared/components/Link/EmailLink/EmailLink';
import WhatsAppLink from '../../../shared/components/Link/WhatsAppLink/WhatsAppLink';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import { IContact } from '../../../entities/Contact/contact.models';
import userIcon from '@/assets/icons/user.svg';
import emailIcon from '@/assets/icons/email.svg';
import phoneIcon from '@/assets/icons/phone.svg';
import crownIcon from '@/assets/icons/crown.svg';
import styles from './ModalDeleteStyles.module.scss';
import style from './ContactCard.module.scss';
import { Link } from 'react-router-dom';

interface typeProps {
  contact: IContact;
  deleteContact: () => void;
}

const ContactCard = (props: typeProps) => {
  const { email, id, lastName, firstName, phone, isPrimary } = props.contact;
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      contact: '',
    },
  });

  const fullName = `${firstName} ${lastName}`;
  const contactNameInput = watch('contact');

  useEffect(() => {
    if (contactNameInput === fullName) {
      clearErrors('contact');
    } else if (contactNameInput && contactNameInput !== fullName) {
      setError('contact', {
        type: 'manual',
        message: 'Name doesnt match',
      });
    } else {
      clearErrors('contact');
    }
  }, [contactNameInput, setError, clearErrors, fullName]);

  const toggleDeleteModal = () => {
    setIsDeleteModalVisible(
      (prevIsDeleteModalVisible) => !prevIsDeleteModalVisible,
    );
  };

  const handleDeleteContact = () => {
    if (fullName === contactNameInput) {
      props.deleteContact();
    } else {
      return;
    }
  };

  const isDeleteDisabled = !!errors.contact;

  return (
    <>
      <div key={id}>
        <div className={style.contactDetails}>
          <div className={style.contactDetailsItems}>
            <div className={style.contactDetailsItem}>
              <img src={userIcon} alt="icon" />
              <div className={style.contactDetailsText}>
                <p className={style.contactDetailsValue}>
                  {`${firstName}
                  ${lastName}`}
                </p>
              </div>
            </div>
            {email ? (
              <div className={style.contactDetailsItem}>
                <img src={emailIcon} alt="icon" />
                <div className={style.contactDetailsText}>
                  <EmailLink
                    className={style.contactDetailsValue}
                    email={email}
                  />
                </div>
              </div>
            ) : (
              <div className={style.contactDetailsItem}>
                <img src={emailIcon} alt="icon" />
                <div className={style.contactDetailsText}>
                  <p className={style.contactDetailsValue}>N/A</p>
                </div>
              </div>
            )}
            <div className={style.contactDetailsItem}>
              <img src={phoneIcon} alt="icon" />
              <div className={style.contactDetailsText}>
                <WhatsAppLink
                  phone={phone}
                  className={style.contactDetailsValue}
                />
              </div>
            </div>

            {isPrimary && (
              <div className={style.contactDetailsItem}>
                <img src={crownIcon} alt="icon" />
                <div className={style.contactDetailsText}>
                  <p className={style.contactDetailsValue}>Supervisor</p>
                </div>
              </div>
            )}
          </div>

          <div className={style.contactButtons}>
            {!isPrimary && (
              <SectionButton
                className="delete-button"
                label="✖"
                onClick={toggleDeleteModal}
              />
            )}
            <Link key={id} to={`/edit-contact/${id}`}>
              <SectionButton className="update-button" label={'✎'} />
            </Link>
          </div>
        </div>

        {isDeleteModalVisible && (
          <Modal
            className={styles.modal}
            open={isDeleteModalVisible}
            onClose={toggleDeleteModal}>
            <form onSubmit={handleSubmit(handleDeleteContact)}>
              <div className={styles.modalDelete}>
                <div className={styles.modalDeleteHeader}>
                  <p
                    className={styles.modalDeleteHeaderText}
                    id="confirmation-form-modal-title">
                    {`Delete ${firstName} ${lastName}`}
                  </p>
                </div>
                <div className={styles.modalDeleteMainSection}>
                  <p className={styles.modalDeleteMainText}>
                    Caution! All orders related to this contact will be deleted
                  </p>
                  <p className={styles.modalDeleteConfirmText}>
                    Enter contact&apos;s full name to proceed
                  </p>
                  <TextField
                    {...register('contact', {
                      required: 'This field is required',
                      validate: (value) =>
                        value === fullName || 'Name does not match.',
                    })}
                    className={styles.modalDeleteInput}
                    placeholder="Contact name"
                    error={!!errors.contact}
                    helperText={errors.contact?.message}
                    onChange={() => clearErrors('contact')}
                    type="text"
                  />
                </div>
                <div className={styles.modalDeleteButtons}>
                  <button
                    className={styles.modalDeleteButton}
                    type="submit"
                    onClick={handleDeleteContact}
                    disabled={isDeleteDisabled}>
                    Delete contact
                  </button>
                  <button
                    className={styles.modalDeleteButtonCancel}
                    onClick={toggleDeleteModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </div>
    </>
  );
};

export default ContactCard;
