import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IMetadata, baseQueryConfig } from '../../../shared/api/api';
import {
  ICreateOrganizationRequest,
  IOrganization,
  IUpdateOrganizationRequest,
} from '../org.models';
import { RootState } from '../../../store/store';

export type IOrganizationWithMetadata = {
  data: IOrganization[];
  meta: IMetadata;
};

export const organizationApi = createApi({
  reducerPath: 'OrganizationApi',
  tagTypes: ['Organization', 'Organizations'],
  baseQuery: fetchBaseQuery({
    ...baseQueryConfig,
    baseUrl: baseQueryConfig.baseUrl + '/organizations',
    prepareHeaders: async (headers: Headers, { getState }) => {
      const token = await (getState() as RootState).user.user!.getIdToken();
      headers.set('Authorization', token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOrganizations: builder.query<
      IOrganizationWithMetadata,
      {
        relations?: string[];
        page?: number;
        take?: number;
        searchByName?: string;
        sort?: string;
        relationshipManager?: string[];
        organizationSize?: string[];
      }
    >({
      query: ({
        relations,
        page,
        take,
        searchByName,
        sort,
        relationshipManager,
        organizationSize,
      }) => {
        const params = new URLSearchParams();

        if (page !== undefined) params.append('page', page.toString());
        if (take !== undefined) params.append('take', take.toString());
        if (searchByName) params.append('name', searchByName);
        if (sort) params.append('sort', sort);
        if (relations) {
          relations.forEach((relation) => params.append('relations', relation));
        }
        if (relationshipManager) {
          relationshipManager.forEach((manager) =>
            params.append('relationshipManager.id', manager.toString()),
          );
        }

        if (organizationSize) {
          organizationSize.forEach((size) =>
            params.append('organizationSize', size),
          );
        }
        return {
          url: '',
          params,
        };
      },
      providesTags: ['Organizations'],
    }),
    getOrganizationById: builder.query<IOrganization, string>({
      query: (id) => ({
        url: `/${id}?relations=branches&relations=branches.contacts&relations=relationshipManager&relations=branches.contacts.orders.skuRequests`,
      }),
      providesTags: ['Organization'],
    }),
    createOrganization: builder.mutation<any, ICreateOrganizationRequest>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Organizations'],
    }),
    updateOrganization: builder.mutation<any, IUpdateOrganizationRequest>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Organization', 'Organizations'],
    }),
    deleteOrganization: builder.mutation<any, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Organizations'],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationByIdQuery,
  useUpdateOrganizationMutation,
} = organizationApi;
