import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styles from './OrgFilter.module.scss';
import { InputLabel } from '@mui/material';
import { IEmployeesAmountTypes } from '@/entities/Organization/org.models';
import { useGetAdminsQuery } from '@/entities/Admin/api/adminApi';
import FormInput from '@/shared/components/Input/FormInput/FormInput';
import { MultiSelect } from '@/shared/components/Select/MultiSelect/MultiSelect';

export const initialSearchQuery = {
  orgName: '',
  relationshipManager: [] as string[],
  orgSize: [] as string[],
};

export type FiltersFormData = {
  orgName: string;
  relationshipManager: string[];
  orgSize: string[];
};

type Props = {
  handleInputChange: (value: string) => void;
  handleRelationshipManagerChange: (values: string[]) => void;
  handleOrgSizeChange: (values: string[]) => void;
};

export const OrgFilter = ({
  handleInputChange,
  handleRelationshipManagerChange,
  handleOrgSizeChange,
}: Props) => {
  const { control, watch } = useForm<FiltersFormData>({
    defaultValues: initialSearchQuery,
  });

  const { data: adminData } = useGetAdminsQuery({
    take: 100,
    page: 1,
  });

  const adminOptions = adminData
    ? adminData.data.map((admin) => ({
        label: admin.name,
        value: admin.id,
      }))
    : [];

  const relationshipManager = watch('relationshipManager') || [];
  const orgSize = watch('orgSize') || [];

  useEffect(() => {
    handleRelationshipManagerChange(relationshipManager);
  }, [relationshipManager]);

  useEffect(() => {
    handleOrgSizeChange(orgSize);
  }, [orgSize]);

  return (
    <>
      <hr className={styles.hr} />
      <div className={styles.container}>
        <div className={styles.wrapInput}>
          <InputLabel className={styles.label}>Search Organisation</InputLabel>
          <FormInput
            type="search"
            id="search-form"
            placeholder="Search"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.value)
            }
          />
        </div>
        <div className={styles.wrapInput}>
          <InputLabel className={styles.label}>Relationship Manager</InputLabel>
          <MultiSelect
            control={control}
            name="relationshipManager"
            selectOptions={adminOptions}
            label="Please select"
          />
        </div>

        <div className={styles.wrapInput}>
          <InputLabel className={styles.label}>Organisation size</InputLabel>
          <MultiSelect
            control={control}
            name="orgSize"
            selectOptions={IEmployeesAmountTypes}
            label="Please select"
          />
        </div>
      </div>
      <hr className={styles.hr} />
    </>
  );
};
