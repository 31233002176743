import React from 'react';
import './MarketSalary.scss';
import OrderSubtitle from '../../../shared/components/Title/OrderSubtitle/OrderSubtitle';
import MarketSalaryItem from '../../../entities/Order/MarketSalaryItem/MarketSalaryItem';
import money from '../../../assets/icons/orderPage/money.svg';
import { IOrderCard } from '../../../entities/Order/order.models';
import { useGetSkuQuery } from '../../../entities/SKU/api/SKUApi';
import {
  defaultIcon,
  mapProductNameToIcon,
} from '../../../pages/CreateOrder/components/SelectProduct/components/ProductCard/helper';

interface PropTypes {
  cardsData?: IOrderCard[];
}

const MarketSalary = (props: PropTypes) => {
  const { cardsData } = props;

  const { data: skuData, isSuccess } = useGetSkuQuery({});

  if (!isSuccess) return <div></div>;
  return (
    <div className="market-salary">
      <div className="market-salary-title">
        <img src={money} alt="icon" />
        <OrderSubtitle title="Market Salary" />
      </div>
      <div className="market-salary-items">
        {cardsData?.map((card) => {
          const currentSku = skuData?.find((sku) => sku.id === card.id);
          return (
            <MarketSalaryItem
              key={card.id}
              icon={mapProductNameToIcon[card.product] || defaultIcon}
              position={card.product}
              grade={card.grade}
              minimumAsk={currentSku?.salaryMinimum}
              averageAsk={currentSku?.salaryAverage}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MarketSalary;
