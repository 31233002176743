import { IDataWithPagination, IOrder } from '../../Order/order.models';
import { baseQueryConfig } from './../../../shared/api/api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../../store/store';

// const requestConfig = {
//   ...baseQueryConfig,
//   baseUrl: baseQueryConfig.baseUrl + '/customer-care',
//   prepareHeaders: (headers: Headers) => {
//     const session = getToken();
//     headers.set('Authorization', `${session.token}`);
//     return headers;
//   },
// };

export enum CustomerCareStatuses {
  PENDING = 'PendingProcessing',
  PROCESSED = 'Processed',
}

export type ICustomerCare = {
  id: number;
  createdAt: Date;
  status: CustomerCareStatuses;
  valueStars: number;
  note: string;
  order: IOrder;
};

export const customerApi = createApi({
  reducerPath: 'customerApi',
  tagTypes: ['CustomerCare'],
  baseQuery: fetchBaseQuery({
    ...baseQueryConfig,
    baseUrl: baseQueryConfig.baseUrl + '/customer-care',
    prepareHeaders: async (headers: Headers, { getState }) => {
      const token = await (getState() as RootState).user.user?.getIdToken();
      token && headers.set('Authorization', token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCustomerCare: builder.query<
      IDataWithPagination<ICustomerCare>,
      { relations?: string[]; page?: number; take: number; sort?: string }
    >({
      query: ({ relations = [], page, take, sort }) => ({
        url: '',
        params: {
          ...(relations.length ? { relations } : {}),
          page,
          take,
          sort,
        },
      }),
      providesTags: ['CustomerCare'],
    }),
    getCustomerCareById: builder.query<
      ICustomerCare,
      { id: string; includeDetails?: boolean }
    >({
      query: ({ id, includeDetails }) => ({
        url: `/${id}?relations=order.contact.branch.organization`,
        params: {
          'include-details': includeDetails,
        },
      }),
      providesTags: ['CustomerCare'],
    }),
    updateCustomerCare: builder.mutation<any, any>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['CustomerCare'],
    }),
  }),
});

export const {
  useGetCustomerCareQuery,
  useGetCustomerCareByIdQuery,
  useUpdateCustomerCareMutation,
} = customerApi;
