import React, { forwardRef } from 'react';
import classNames from 'classnames';
import './OrderSelect.scss';

export type OptionObject<T> = {
  label: string;
  value: T;
};

interface PropTypes {
  label?: string;
  icon?: string;
  options: (string | OptionObject<any>)[];
  value?: string;
  placeholder?: string;
  error?: string;
  handleChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleCollectValue?: (value: string) => void;
}

const OrderSelect = forwardRef<HTMLButtonElement, PropTypes>(
  (props: PropTypes, ref: any) => {
    const {
      label,
      icon,
      value,
      options,
      placeholder,
      error,
      handleChange,
      handleCollectValue,
    } = props;
    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      handleChange && handleChange(e);
      handleCollectValue && handleCollectValue(e.target.value);
    };

    return (
      <div className="order-select-container">
        {label && <div className="order-select-label">{label}</div>}
        <div className="order-select-item">
          <select
            className={classNames('order-select', { 'has-icon': icon })}
            value={value}
            ref={ref}
            onChange={handleSelectChange}>
            {placeholder && (
              <option className="select-placeholder" value="" hidden>
                {placeholder}
              </option>
            )}
            {options?.map((option) => {
              const value = typeof option === 'string' ? option : option.value;
              const label = typeof option === 'string' ? option : option.label;

              return (
                <option value={value} key={label}>
                  {label}
                </option>
              );
            })}
          </select>
          {icon && <img className="order-select-icon" src={icon} alt="icon" />}
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    );
  },
);

OrderSelect.displayName = 'order-select';

export default OrderSelect;
