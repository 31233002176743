import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CustomerCare from './pages/CustomerCare/CustomerCare';
import OrgContainer from './pages/Organizations/OrgContainer';
import ContactsContainer from './pages/Contacts/ContactsContainer';
import OrdersContainer from './pages/Orders/OrdersContainer';
import LoginForm from './pages/Authorization/LoginForm/LoginForm';
import { RegisterOrg } from './pages/Organizations/RegisterOrg/RegisterOrg';
import ContactBranch from './pages/Contacts/ContactBranch/ContactBranch';
import OrgScreen from './pages/Organizations/OrgScreen/OrgScreen';
import Settings from './pages/UserProfile/Settings/Settings';
import OrgBalance from './pages/Organizations/OrgBalance/OrgBalance';
import SKU from './pages/SKU/SKU';
import AddTransaction from './pages/Organizations/AddTransaction/AddTransaction';
import CreateOrder from './pages/CreateOrder/CreateOrder';
import OrgEdit from './pages/Organizations/OrgEdit/OrgEdit';
import { roleApi } from './entities/Role/api/roleApi';
import { AdminTypes } from './entities/Admin/admin.models';
import ProtectedRoute from './shared/components/ProtectedRoute/ProtectedRoute';
import SelectProduct from './pages/CreateOrder/components/SelectProduct/SelectProduct';
import ContractSchedule from './pages/CreateOrder/components/ContractSchedule/ContractSchedule';
import SummaryOrder from './pages/CreateOrder/components/SummaryOrder/SummaryOrder';
import OrderCreated from './pages/OrderCreated/OrderCreated';
import SummaryOrderClient from './pages/SummaryOrderClient/SummaryOrderClient';
import './App.scss';
import { SnackbarProvider } from './shared/components/SnackBarProvider/SnackbarProvider';
import { OffersPage } from './pages/OffersPage/OffersPage';
import { RefundDetails } from './pages/Organizations/OrgBalance/components/RefundDetails/RefundDetails';
import { CustomerDetails } from './entities/Customer/CustomerDetails/CustomerDetails';
import { PaymentForm } from './pages/Billing/components/PaymentForm/PaymentForm';
import { InvoicesForm } from './pages/Billing/components/InvoicesForm/InvoicesForm';
import { RefundForm } from './pages/Billing/components/RefundForm/RefundForm';
import { BillingContainer } from './pages/Billing/BillingContainer';
import ViewOrder from './pages/CreateOrder/components/ViewOrder/ViewOrder';
import { RegisterBranch } from './pages/Branches/RegisterBranch/RegisterBranch';
import UpdateBranch from './widgets/NewBranchPage/UpdateBranch';
import UpdateContact from './shared/components/Modal/UpdateContact/UpdateContact';
import { CreateContact } from './pages/Contacts/CreateContact/CreateContact';
import { ContactDetails } from './pages/Contacts/ContactDetails/ContactDetails';
import { Dashboard } from './pages/Dashboard/Dashboard';

export const App = () => {
  const { data: userRole } = roleApi.endpoints.getRole.useQueryState('');
  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/create-order" element={<CreateOrder />} />
          <Route path="/customer-care" element={<CustomerCare />} />
          <Route path="/organizations" element={<OrgContainer />} />
          <Route path="/contacts" element={<ContactsContainer />} />
          <Route path="/orders" element={<OrdersContainer />} />
          <Route path="/register-org" element={<RegisterOrg />} />
          <Route path="/contact-details/:id" element={<ContactDetails />} />
          <Route path="/contact-branch/:branchId" element={<ContactBranch />} />
          <Route
            path="/contacts/register-contact"
            element={<CreateContact />}
          />
          <Route path="/org-screen/:id" element={<OrgScreen />} />
          {userRole?.role === AdminTypes.super && (
            <Route path="/settings" element={<Settings />} />
          )}
          {userRole &&
            [AdminTypes.super, AdminTypes.accountant].includes(
              userRole?.role,
            ) && <Route path="/org-balance/*" element={<OrgBalance />} />}
          <Route path="/select-product" element={<SelectProduct />} />
          <Route path="/contract-schedule" element={<ContractSchedule />} />
          <Route path="/summary-order" element={<SummaryOrder />} />
          <Route path="/view-order/:id" element={<ViewOrder />} />
          <Route path="/order-created" element={<OrderCreated />} />
          <Route path="/sku" element={<SKU />} />
          <Route path="/add_transaction" element={<AddTransaction />} />
          <Route path="/org-screen/edit/:id" element={<OrgEdit />} />
          <Route
            path="/org-screen/register-branch/:id"
            element={<RegisterBranch />}
          />
          <Route path="/contact-branch/:id/edit" element={<UpdateBranch />} />
          <Route path="/edit-contact/:id" element={<UpdateContact />} />
          <Route path="/refund-details/:id" element={<RefundDetails />} />
          {userRole &&
            [AdminTypes.super, AdminTypes.accountant].includes(
              userRole.role,
            ) && <Route path="/billing" element={<BillingContainer />} />}
        </Route>
        <Route path="/offers-client/:id" element={<OffersPage />} />
        <Route path={'/orders-client/:id'} element={<SummaryOrderClient />} />
        <Route path={'/customer-details/:id'} element={<CustomerDetails />} />
        <Route path={'/payment-form/:id'} element={<PaymentForm />} />
        <Route path={'/invoice-form/:id'} element={<InvoicesForm />} />
        <Route path={'/refund-form/:id'} element={<RefundForm />} />
        <Route path="/login" element={<LoginForm />} />
      </Routes>
      <SnackbarProvider />
    </>
  );
};
