import { createAsyncThunk } from '@reduxjs/toolkit';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
import { signOutUser } from '../entities/Admin/admin.slice';

export const logout = createAsyncThunk(
  'user/logout',
  async (_, { dispatch }) => {
    localStorage.removeItem('token');
    await signOut(auth);
    dispatch(signOutUser());
  },
);
