import React, { useMemo, useState } from 'react';
import './SelectProductFilters.scss';
import OrderSelect from '../../../shared/components/Select/OrderSelect/OrderSelect';
import { IFiltersTypes } from '../../../entities/Order/order.models';
import { ISkuResponse } from '../../../entities/SKU/SKUModel.models';

interface PropTypes {
  selectedFilters: IFiltersTypes;
  onFiltersChange: (filters: IFiltersTypes) => void;
  skus: ISkuResponse[];
}

const SelectProductFilters = (props: PropTypes) => {
  const { selectedFilters, onFiltersChange } = props;
  const [selectedProduct, setSelectedProduct] = useState(
    selectedFilters.product,
  );

  const [selectedJobGroup, setSelectedJobGroup] = useState(
    selectedFilters.jobGroup,
  );

  const [selectedLegalType, setSelectedLegalType] = useState(
    selectedFilters.legalType,
  );

  const handleProductChange = (selectedProduct: string) => {
    setSelectedProduct(selectedProduct);
    onFiltersChange({ ...selectedFilters, product: selectedProduct });
  };

  const handleJobGroupChange = (jobGroup: string) => {
    setSelectedJobGroup(jobGroup);
    onFiltersChange({ ...selectedFilters, jobGroup: jobGroup });
  };

  const handleLegalTypeChange = (legalType: string) => {
    setSelectedLegalType(legalType);
    onFiltersChange({ ...selectedFilters, legalType: legalType });
  };

  const jobGroupsOptions = useMemo(
    () => [
      'All job groups',
      ...props.skus.reduce(
        (acc: string[], sku) =>
          acc.includes(sku.productGroupName.toString())
            ? acc
            : [...acc, sku.productGroupName.toString()],
        [],
      ),
    ],
    [props.skus],
  );
  const productOptions = useMemo(
    () => [
      'All jobs',
      ...props.skus.reduce(
        (acc: string[], sku) =>
          acc.includes(sku.productName) ? acc : [...acc, sku.productName],
        [],
      ),
    ],
    [props.skus],
  );

  const legalTypesOptions = useMemo(
    () => [
      'All legal types',
      ...props.skus.reduce((acc: string[], sku) => {
        if (!sku.legalType) return acc;
        return acc.includes(sku.legalType.toString())
          ? acc
          : [...acc, sku.legalType.toString()];
      }, []),
    ],
    [props.skus],
  );

  return (
    <div className="select-product-filters">
      <OrderSelect
        label="Job groups"
        options={jobGroupsOptions}
        value={selectedJobGroup}
        handleChange={(e) => handleJobGroupChange(e.target.value)}
      />
      <OrderSelect
        label="Jobs"
        options={productOptions}
        value={selectedProduct}
        handleChange={(e) => handleProductChange(e.target.value)}
      />
      <OrderSelect
        label="Legal types"
        options={legalTypesOptions}
        value={selectedLegalType}
        handleChange={(e) => handleLegalTypeChange(e.target.value)}
      />
    </div>
  );
};

export default SelectProductFilters;
