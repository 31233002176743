import React from 'react';
import styles from './Pagination.module.scss';
import arrowLeft from '@/assets/icons/chevron-left.svg';
import arrowRight from '@/assets/icons/chevron-right.svg';
import { MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';

type PaginationProps = {
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rows: number) => void;
  totalItems: number;
};

export const Pagination: React.FC<PaginationProps> = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalItems,
}) => {
  const totalPages = Math.ceil(totalItems / rowsPerPage);

  const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      margin="20px">
      <div className={styles.paginationLeft}>
        Items per page:
        <Select
          className={styles.paginationSelect}
          value={rowsPerPage}
          onChange={handleRowsPerPageChange}
          sx={{
            width: '83px',
            height: '44px',
            borderRadius: '12px',
          }}>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </Select>
        <div>
          {totalItems > 0
            ? `${(page - 1) * rowsPerPage + 1}-${Math.min(
                page * rowsPerPage,
                totalItems,
              )} of ${totalItems} items`
            : '0 items'}
        </div>
      </div>

      <div className={styles.paginationRight}>
        <button
          className={styles.paginationBtns}
          onClick={() => setPage(Math.max(page - 1, 1))}
          disabled={page === 1 || totalItems === 0}>
          <img src={arrowLeft} alt="back" className={styles.paginationArrows} />
        </button>
        <input
          className={styles.paginationInputPage}
          type="number"
          value={totalItems > 0 ? page : 0}
          onChange={(e) => {
            const newPage = Math.max(
              1,
              Math.min(totalPages, Number(e.target.value)),
            );
            setPage(newPage);
          }}
          disabled={totalItems === 0}
        />
        {' of ' + (totalItems > 0 ? totalPages : 0)}
        <button
          className={styles.paginationBtns}
          onClick={() => setPage(Math.min(page + 1, totalPages))}
          disabled={page === totalPages || totalItems === 0}>
          <img
            src={arrowRight}
            alt="forward"
            className={styles.paginationArrows}
          />
        </button>
      </div>
    </Stack>
  );
};
