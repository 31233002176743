import React from 'react';
import './OrderSubtitle.scss';

interface PropTypes {
  title: string;
  icon?: string;
  name?: string;
}

const OrderSubtitle = (props: PropTypes) => {
  const { title, icon, name } = props;
  return (
    <div className="order-subtitle">
      {icon && <img src={icon} alt="icon" />}
      {title}
      <span className="name">{name?.replace(title + ',', '')}</span>
    </div>
  );
};

export default OrderSubtitle;
