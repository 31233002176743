export const createPaymentFormData = (
  paymentMethod: string,
  actualDate: string,
  file: File,
) => {
  const formData = new FormData();
  formData.append('paymentMethod', paymentMethod);
  formData.append('actualDate', actualDate);
  formData.append('file', file);
  return formData;
};

export function buildUrlParams(
  relations: string[],
  status?: string[],
  sort?: string,
) {
  let urlParams = '';

  if (relations.length > 0) {
    urlParams += `?relations=${relations[0]}`;
    for (let i = 1; i < relations.length; i++) {
      urlParams += `&relations=${relations[i]}`;
    }
  }

  if (status) {
    urlParams += `&status=${status}`;
  }

  if (sort) {
    urlParams += `&sort=${sort}`;
  }

  return urlParams;
}
