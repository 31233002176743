import React, { useMemo } from 'react';
import {
  CircularProgress,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import styles from './CustomerDetails.module.scss';
import star from '../../../assets/icons/star.svg';
import TemplateFormPage from '../../../pages/TemplateFormPage/TemplateFormPage';
import BasicOrderButton from '../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  CustomerCareStatuses,
  useGetCustomerCareByIdQuery,
  useUpdateCustomerCareMutation,
} from '../api/customerApi';
import { useGetOrderByIdWithRelationsQuery } from '../../Order/api/orderApi';
import { mapProductNameToIcon } from '../../../pages/CreateOrder/components/SelectProduct/components/ProductCard/helper';
import { RepeatOrderButton } from '../../../shared/components/RepeatOrderButton/RepeatOrderButton';
import WhatsAppLink from '../../../shared/components/Link/WhatsAppLink/WhatsAppLink';
import EmailLink from '../../../shared/components/Link/EmailLink/EmailLink';

export const CustomerDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) {
    return null;
  }

  const {
    data: customerData,
    isLoading,
    isSuccess,
  } = useGetCustomerCareByIdQuery({
    id,
  });

  const { firstName, lastName, email, phone } =
    customerData?.order.contact || {};

  const orderId = customerData?.order?.id.toString() as string;

  const { data: orderData } = useGetOrderByIdWithRelationsQuery({
    id: orderId,
  });

  const [handledRewievStatus] = useUpdateCustomerCareMutation();

  const name = `${firstName} ${lastName}`;

  const handleChangeCustomerStatus = async () => {
    await handledRewievStatus({
      id: id,
      status: CustomerCareStatuses.PROCESSED,
    });
    navigate('/customer-care');
  };

  const orderProductCards = useMemo(() => {
    return orderData?.skuRequests?.map((skuReq) => ({
      id: skuReq.sku.id,
      price: skuReq.crewsNumber * skuReq.sku.pricePerUnit,
      product: skuReq.sku.productName,
      quantity: skuReq.crewsNumber,
      contact: skuReq.contact,
      contract: {
        ...skuReq.contract,
        femalesPercentage: skuReq.femalesPercentage,
        localsPercentage: skuReq.localsPercentage,
      },
      grade: skuReq.sku.qualityId,
      tags: skuReq.tags,
      languages: skuReq.languages,
      interviewShifts: skuReq.interviewShifts,
      icon: mapProductNameToIcon[skuReq.sku.productName],
    }));
  }, [orderData]);

  const finalStatus =
    customerData?.status === CustomerCareStatuses.PENDING
      ? 'Unhandled'
      : 'Handled';

  return (
    <TemplateFormPage>
      <>
        <div className={styles.customerDetails}>
          {isLoading && (
            <div className={styles.loader}>
              <CircularProgress />
            </div>
          )}
          {isSuccess && (
            <>
              <div className={styles.customerDetailsTitle}>Rewiev Details</div>
              <div className={styles.customerDetailsRightButton}>
                <RepeatOrderButton orderId={customerData?.order?.id} />
                {orderData && (
                  <Link
                    to="/summary-order"
                    state={{
                      id: orderData?.id,
                      currOrderCardList: {
                        price: orderData.price,
                        totalQty: orderData.skuRequests[0].crewsNumber,
                        orderStatus: orderData.orderStatus,
                      },
                      contactData: {
                        selectedOrg:
                          orderData.contact.branch?.organization?.name,
                        selectedBranch: orderData.contact.branch?.name,
                        selectedContact: `${orderData.contact.firstName} ${orderData.contact.lastName}`,
                        orgId: orderData.contact.branch?.organization?.id,
                        branchId: orderData.contact.branch?.id,
                      },
                      orderProductCards,
                    }}>
                    <BasicOrderButton label="To order" />
                  </Link>
                )}
              </div>
              <div className={styles.customerDetailsItems}>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>E-mail</TableCell>
                        <TableCell>Rewiew</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{name}</TableCell>
                        <TableCell>
                          {email && (
                            <EmailLink email={email} className="card-phone" />
                          )}
                        </TableCell>
                        <TableCell>
                          {phone && (
                            <WhatsAppLink
                              phone={phone}
                              className="card-email"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <Rating
                            className="card-rating"
                            value={customerData.valueStars}
                            readOnly
                            emptyIcon={
                              <img
                                src={star}
                                alt="star"
                                style={{ display: 'none' }}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell>{finalStatus}</TableCell>
                        <TableCell>
                          {customerData.status ===
                          CustomerCareStatuses.PENDING ? (
                            <BasicOrderButton
                              onClick={handleChangeCustomerStatus}
                              label="Mark as handled"
                            />
                          ) : customerData.status ===
                            CustomerCareStatuses.PROCESSED ? (
                            CustomerCareStatuses.PROCESSED
                          ) : null}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
        </div>
      </>
    </TemplateFormPage>
  );
};
