import React from 'react';
import userIcon from '../../../../assets/icons/user.svg';
import { ChooseOption } from '../ChooseOption/ChooseOption';
import styles from './CandidatsItem.module.scss';
import {
  Candidate,
  CandidateStatus,
} from '../../../../entities/Offers/offer.models';
import classNames from 'classnames';

type Props = {
  candidate: Candidate;
};

export const statusToColor = {
  [CandidateStatus.PENDING_FOR_DECISION]: styles.pending__pink,
  [CandidateStatus.JOINED]: styles.candidateItemStatus__green,
  [CandidateStatus.REJECTED]: styles.candidateItemStatus__red,
  [CandidateStatus.NOT_JOIN]: styles.candidateItemStatus__gray,
  [CandidateStatus.OFFER_ACCEPTED]: styles.candidateItemStatus__green,
  [CandidateStatus.OFFERED]: styles.candidateItemStatus__white,
  [CandidateStatus.OFFER_EXPIRED]: styles.candidateItemStatus__red,
  [CandidateStatus.OFFER_REJECTED]: styles.candidateItemStatus__red,
};

export const CandidatesItem: React.FC<Props> = ({ candidate }) => {
  let formattedStatus;

  if (candidate.candidateStatus == CandidateStatus.OFFER_ACCEPTED) {
    formattedStatus = 'Offer Accepted';
  } else if (candidate.candidateStatus == CandidateStatus.OFFER_EXPIRED) {
    formattedStatus = 'Offer Expired';
  } else if (candidate.candidateStatus == CandidateStatus.OFFER_REJECTED) {
    formattedStatus = 'Offer Rejected';
  } else if (candidate.candidateStatus == CandidateStatus.NOT_JOIN) {
    formattedStatus = 'No Show';
  } else {
    formattedStatus = candidate.candidateStatus;
  }

  return (
    <div className={styles.candidateItem}>
      <div className={styles.candidateItemLeftBox}>
        <img className={styles.candidateItemIcon} src={userIcon} alt="*" />
        <a className={styles.candidateItemName}>{`${candidate.name}.`}</a>
      </div>
      {candidate.candidateStatus === CandidateStatus.PENDING_FOR_DECISION ? (
        <ChooseOption candidate={candidate} />
      ) : (
        <span
          className={classNames(
            styles.candidateItemStatus,
            statusToColor[candidate.candidateStatus] ||
              styles.candidateItemStatus__white,
          )}>
          {formattedStatus}
        </span>
      )}
    </div>
  );
};
