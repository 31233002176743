import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { cancelationRequestApi } from '../../../../../entities/CancelationRequest/CancelationRequestApi';
import FiltersBlockButton from '../../../../../shared/components/Button/FiltersBlockButton/FiltersBlockButton';
import { RefundItem } from '../RefundItem/RefundItem';
import {
  FilterStatus,
  RefundsFilterBlock,
} from '../RefundsFilterBlock/RefundsFilterBlock';

import styles from './RefundsTab.module.scss';
import { useLocation } from 'react-router-dom';

const TAKE_REQUEST = 10;

export const RefundsTab = () => {
  const location = useLocation();
  const { orgId } = location.state || {};
  const [page, setPage] = useState(1);
  const [isFilterBlockVisible, setFilterBlockVisibility] = useState(false);

  const [trigger, result] =
    cancelationRequestApi.endpoints.getCanсelationRequestWithFilters.useLazyQuery();

  const { data: cancelationRequestData, isLoading, isSuccess } = result;

  useEffect(() => {
    trigger({
      orgId: orgId,
      take: TAKE_REQUEST,
      page,
      sort: 'createdAt:desc',
    });
  }, [page]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleToggleFilters = () => {
    setFilterBlockVisibility(!isFilterBlockVisible);
  };

  const filterBlockRef = useRef(null);

  const handleSubmitFilters = useCallback(
    (data: FilterStatus) => {
      setFilterBlockVisibility(false);
      trigger({
        orgId: orgId,
        take: 10,
        page,
        sort: 'id:asc',
        ...data,
        status: data.status === 'all' ? undefined : data.status,
      });
    },
    [page],
  );

  return (
    <div className={styles.orgcontentRefunds}>
      <div className={styles.refundsFilter} ref={filterBlockRef}>
        <FiltersBlockButton onClick={handleToggleFilters} />
        <RefundsFilterBlock
          visible={isFilterBlockVisible}
          onSubmit={handleSubmitFilters}
        />
      </div>
      <div className={styles.refundsItems}>
        {isLoading && <div>Loading...</div>}
        {isSuccess && (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Reason</TableCell>
                    <TableCell>Refund Policy</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cancelationRequestData.data.map((refund) => (
                    <TableRow
                      key={refund.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <RefundItem key={refund.id} {...refund} />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination page={page} onChange={handlePageChange} />
          </>
        )}
      </div>
    </div>
  );
};
