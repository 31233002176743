import React, { useMemo } from 'react';
import styles from './RefundTab.module.scss';
import { TableBody, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import orgIcon from '../../../../assets/icons/nav-icon-organisations.svg';
import orderIcon from '../../../../assets/icons/billing/orderId.svg';
import dateIcon from '../../../../assets/icons/billing/date.svg';
import relShipIcon from '../../../../assets/icons/billing/manager.svg';
import {
  ICancelationRequest,
  useGetCancelationRequestsWithRelationQuery,
} from '../../../../entities/CancelationRequest/CancelationRequestApi';
import { formatNumber } from '@/shared/helpers/formatNumber';

type Props = {
  searchQuery: string;
};

export const RefundTab: React.FC<Props> = ({ searchQuery }) => {
  const navigate = useNavigate();

  const { data: refundsData } = useGetCancelationRequestsWithRelationQuery({
    relations: ['order.contact.branch.organization.relationshipManager'],
    status: ['PendingProcessing'],
    sort: 'id:desc',
  });

  const filteredRefunds = useMemo(() => {
    if (!refundsData || !refundsData.data) return [];
    return refundsData.data.filter((refund: ICancelationRequest) => {
      const orgName = refund.order?.contact?.branch?.organization?.name;
      const orderId = refund.order?.id?.toString() || '';
      return (
        orgName!.toLowerCase().includes(searchQuery.toLowerCase()) ||
        orderId.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [refundsData, searchQuery]);

  const handleRowClick = (refund: ICancelationRequest) => {
    if (refund.id) {
      navigate(`/refund-form/${refund.id}`);
    }
  };

  return (
    <TableBody>
      {filteredRefunds.map((refund: ICancelationRequest, index: number) => (
        <TableRow
          key={refund.id}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}
          className={index % 2 === 0 ? styles.lightRow : styles.darkRow}
          onMouseEnter={(e) =>
            e.currentTarget.classList.add(styles.highlightedRow)
          }
          onMouseLeave={(e) =>
            e.currentTarget.classList.remove(styles.highlightedRow)
          }
          onClick={() => handleRowClick({ ...refund })}>
          <TableCell>
            <div className={styles.cellIcon}>
              <img className={styles.billingIcon} src={orgIcon} alt="icon" />
              {refund.order?.contact?.branch?.organization?.name}
            </div>
          </TableCell>
          <TableCell>
            <div className={styles.cellIcon}>
              <img className={styles.billingIcon} src={orderIcon} alt="icon" />
              <div className={styles.cellIcon}>{refund.order?.id}</div>
            </div>
          </TableCell>
          <TableCell>
            <div className={styles.cellIcon}>
              <img className={styles.billingIcon} src={dateIcon} alt="icon" />
              <div className={styles.cellIcon}>
                {moment(refund.createdAt).format('MMM DD, YYYY')}
              </div>
            </div>
          </TableCell>
          <TableCell>
            <div className={styles.cellIcon}>
              <img
                className={styles.billingIcon}
                src={relShipIcon}
                alt="icon"
              />
              <div className={styles.cellIcon}>
                {refund.order?.contact?.branch?.organization
                  ?.relationshipManager?.name || '-'}
              </div>
            </div>
          </TableCell>
          <TableCell>
            <div className={styles.blueCell}>
              SAR {formatNumber(refund.refundableAmount)}
            </div>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
