import React, { useMemo, useState } from 'react';
import TemplateFormPage from '../../../../TemplateFormPage/TemplateFormPage';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './RefundDetails.module.scss';
import BasicOrderButton from '../../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import {
  RefundItem,
  RequestStatuses,
  useApproveCancelationRequestMutation,
  useDeclineCancelationRequestMutation,
  useGetCancelationRequestByIdQuery,
} from '../../../../../entities/CancelationRequest/CancelationRequestApi';
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useGetOrderByIdWithRelationsQuery } from '../../../../../entities/Order/api/orderApi';
import { mapProductNameToIcon } from '../../../../CreateOrder/components/SelectProduct/components/ProductCard/helper';

export interface ApproveCancelationRequestPayload {
  id: any;
  refundPolicy: any;
  file?: File;
}

export const RefundDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, refundPolicy, status } = location.state || {};

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);

  const numericId = Number(id);

  const {
    data: requestData,
    isLoading: requestLoading,
    isSuccess: requestSuccess,
  } = useGetCancelationRequestByIdQuery(numericId);

  const orderId = requestData?.order?.id.toString() as string;

  const { data: orderData } = useGetOrderByIdWithRelationsQuery({
    id: orderId,
  });

  const orderProductCards = useMemo(() => {
    return orderData?.skuRequests?.map((skuReq) => ({
      id: skuReq.sku.id,
      price: skuReq.crewsNumber * skuReq.sku.pricePerUnit,
      product: skuReq.sku.productName,
      quantity: skuReq.crewsNumber,
      contact: skuReq.contact,
      contract: {
        ...skuReq.contract,
        femalesPercentage: skuReq.femalesPercentage,
        localsPercentage: skuReq.localsPercentage,
      },
      grade: skuReq.sku.qualityId,
      tags: skuReq.tags,
      languages: skuReq.languages,
      interviewShifts: skuReq.interviewShifts,
      icon: mapProductNameToIcon[skuReq.sku.productName],
    }));
  }, [orderData]);

  const [declineCancelationRequest] = useDeclineCancelationRequestMutation();
  const [approveCancelationRequest] = useApproveCancelationRequestMutation();

  const handleToggleApproveModal = () => {
    setIsApproveModalOpen((prevState) => !prevState);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
  };

  const handleDecline = async () => {
    try {
      await declineCancelationRequest({ id: numericId });
      navigate('/org-balance/refunds');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error declining the request:', error);
    }
  };

  const handleApprove = () => {
    const payload: ApproveCancelationRequestPayload = { id, refundPolicy };
    if (refundPolicy === 'Payout' && selectedFile) {
      payload.file = selectedFile;
    }

    approveCancelationRequest(payload)
      .then(() => {
        navigate('/org-balance/refunds');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error approving the request:', error);
      });
  };

  const isFinalizedStatus =
    status === RequestStatuses.APPROVED || status === RequestStatuses.DECLINED;

  return (
    <TemplateFormPage>
      <>
        <div className={styles.refundDetails}>
          {requestLoading && (
            <div className={styles.loader}>
              <CircularProgress />
            </div>
          )}
          {requestSuccess && (
            <>
              <div className={styles.refundDetailsPolicy}>
                {refundPolicy === 'HoldOnBalance'
                  ? 'Policy: Use for future orders'
                  : 'Policy: Payout to client bank account'}
              </div>
              <div className={styles.refundDetailsRightButton}>
                {orderData && (
                  <Link
                    to="/summary-order"
                    state={{
                      id: orderData?.id,
                      currOrderCardList: {
                        price: orderData.price,
                        totalQty: orderData.skuRequests[0].crewsNumber,
                        orderStatus: orderData.orderStatus,
                      },
                      contactData: {
                        selectedOrg:
                          orderData.contact.branch?.organization?.name,
                        selectedBranch: orderData.contact.branch?.name,
                        selectedContact: `${orderData.contact.firstName} ${orderData.contact.lastName}`,
                        orgId: orderData.contact.branch?.organization?.id,
                        branchId: orderData.contact.branch?.id,
                      },
                      orderProductCards,
                    }}>
                    <BasicOrderButton label="To order" />
                  </Link>
                )}
              </div>
              <div className={styles.refundDetailsLeftButtons}>
                {!isFinalizedStatus && (
                  <>
                    <BasicOrderButton
                      onClick={handleToggleApproveModal}
                      label="Approve"
                    />
                    <BasicOrderButton onClick={handleDecline} label="Decline" />
                  </>
                )}
              </div>
              <div className={styles.refundDetailsItems}>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>SKUID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Positions</TableCell>
                        <TableCell>RefundableAmount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {requestData.refundItems.map((item: RefundItem) => (
                        <TableRow key={item.SKU}>
                          <TableCell># {item.SKU}</TableCell>
                          <TableCell>{item.skuName}</TableCell>
                          <TableCell>{item.positionsAmount}</TableCell>
                          <TableCell>{item.refundableAmount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
        </div>

        {isApproveModalOpen && (
          <Modal
            className={styles.modal}
            open={isApproveModalOpen}
            onClose={handleToggleApproveModal}>
            <div className={styles.modalContent}>
              <span className={styles.modalHeader}>
                Are you sure you want to approve this request?
              </span>
              {refundPolicy === 'Payout' && (
                <input
                  className={styles.modalInput}
                  type="file"
                  accept=".pdf"
                  required
                  onChange={handleFileChange}
                />
              )}
              <div className={styles.modalSummaryBtns}>
                <BasicOrderButton onClick={handleApprove} label="Approve" />
                <BasicOrderButton
                  onClick={handleToggleApproveModal}
                  label="Cancel"
                />
              </div>
            </div>
          </Modal>
        )}
      </>
    </TemplateFormPage>
  );
};
