import { IAdmin } from '@/entities/Admin/admin.models';
import { Autocomplete } from '@/shared/components/Autocomplete/Autocomplete';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import org from '@/assets/icons/org-grey.svg';
import { useGetOrganizationsQuery } from '@/entities/Organization/api/organizationApi';
import { IOrganization } from '@/entities/Organization/org.models';
import { useGetBranchesByOrganizationIdQuery } from '@/entities/Branch/api/branchApi';
import { IBranch } from '@/entities/Branch/branch.models';
import branch from '@/assets/icons/branch.svg';

export type Props = {
  control: Control<any>;
  name: string;
  placeholder?: string;
};

export const SearchAutocompleteOrg = ({
  control,
  name,
  placeholder,
}: Props) => {
  const [orgInput, setOrgInput] = useState<string>('');
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const { data } = useGetOrganizationsQuery({
    searchByName: orgInput,
  });

  const orgOptions: IOrganization[] =
    data?.data.map((org) => ({ label: org.name, ...org })) || [];

  const { data: branchesData } = useGetBranchesByOrganizationIdQuery(
    selectedOrgId?.toString() ?? '',
  );

  const branchOptions: IBranch[] =
    branchesData?.data.map((branch) => ({ label: branch.name, ...branch })) ||
    [];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}>
      <Controller
        control={control}
        name={name}
        render={({
          field: { value, onChange, onBlur, name },
          formState: { errors },
        }) => (
          <Autocomplete
            error={errors[name]?.message?.toString()}
            name={name}
            onBlur={onBlur}
            onInput={(val) => {
              setOrgInput(val);
            }}
            placeholder={placeholder ?? ''}
            onChange={(val: IAdmin) => {
              if (val) {
                onChange(val);
                setSelectedOrgId(val.id);
              }
            }}
            value={value}
            options={orgOptions}
            startAdornment={<img src={org} width={24} height={24} />}
          />
        )}
      />
      <Controller
        control={control}
        name={'branch'}
        render={({
          field: { value, onChange, onBlur, name },
          formState: { errors },
        }) => (
          <Autocomplete
            error={errors[name]?.message?.toString()}
            name={'branch'}
            onBlur={onBlur}
            onInput={(val) => {
              setOrgInput(val);
            }}
            placeholder={'Branch'}
            onChange={(val: IAdmin) => {
              if (val) {
                onChange(val);
              }
            }}
            value={value}
            options={branchOptions}
            startAdornment={<img src={branch} width={24} height={24} />}
          />
        )}
      />
    </div>
  );
};
