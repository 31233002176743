import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../shared/api/api';
import { getToken } from '../../Admin/admin.models';
import {
  IBranch,
  IBranchCreateRequest,
  IBranchUpdateRequest,
} from '../branch.models';
import { IDataWithPagination } from '@/entities/Order/order.models';

const branchConfig = {
  ...baseQueryConfig,
  baseUrl: baseQueryConfig.baseUrl + '/branches',
  prepareHeaders: (headers: Headers) => {
    const session = getToken();
    headers.set('Authorization', `${session.token}`);
    return headers;
  },
};

export const branchApi = createApi({
  reducerPath: 'BranchApi',
  tagTypes: ['Branch'],
  baseQuery: fetchBaseQuery(branchConfig),
  endpoints: (builder) => ({
    getBranches: builder.query<any, { relations?: string[]; query?: string }>({
      query: ({ relations = [], query }) => {
        let url = `?${query}`;
        if (relations.length > 0) {
          url = `?relations=${relations.join(',')}`;
        }
        return { url };
      },
      providesTags: ['Branch'],
    }),
    getBranchesByOrganizationId: builder.query<
      IDataWithPagination<IBranch>,
      string
    >({
      query: (id) => ({
        url: `?relations=contacts&relations=organization&organization.id=${id}`,
        params: {
          // todo: fix add pagination
          take: 100,
        },
      }),
      providesTags: ['Branch'],
    }),
    getBranchById: builder.query<IBranch, string>({
      query: (id) => ({
        url: `/${id}?relations=contacts&relations=organization`,
      }),
      providesTags: ['Branch'],
    }),
    createBranch: builder.mutation<any, IBranchCreateRequest>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Branch'],
    }),
    updateBranch: builder.mutation<any, IBranchUpdateRequest>({
      query: ({ id, ...data }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Branch'],
    }),
    deleteBranch: builder.mutation<any, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Branch'],
    }),
  }),
});

export const {
  useGetBranchesQuery,
  useCreateBranchMutation,
  useDeleteBranchMutation,
  useGetBranchByIdQuery,
  useUpdateBranchMutation,
  useGetBranchesByOrganizationIdQuery,
} = branchApi;
