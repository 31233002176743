import React, { useCallback, useState } from 'react';
import './ProductCard.scss';
import check from '../../../../../../assets/icons/orderPage/check.svg';
import clear from '../../../../../../assets/icons/orderPage/clear.svg';
import OrderSubtitle from '../../../../../../shared/components/Title/OrderSubtitle/OrderSubtitle';
import {
  ISkuResponse,
  ITag,
} from '../../../../../../entities/SKU/SKUModel.models';
import {
  ProductCardFormData,
  ProductCardModal,
} from './components/ProductCardModal';
import { formatNumber } from '@/shared/helpers/formatNumber';

interface PropTypes {
  id: number;
  grade: number;
  productName: string;
  pricePerUnit: number;
  qualityName: string;
  quantity?: number;
  isAdded: boolean;
  tags: ITag[];
  orderableInventory: number;
  sku: ISkuResponse;
  onSelectCard?: (cardsData: {
    id: number;
    tags: ITag[];
    quantity: number;
    sku: ISkuResponse;
  }) => void;
  onDeleteCard?: (id: number) => void;
}

const ProductCard = (props: PropTypes) => {
  const {
    id,
    pricePerUnit,
    productName,
    quantity = 1,
    isAdded = false,
    sku,
    onSelectCard,
    onDeleteCard,
  } = props;

  const [hovered, setHovered] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const shouldShowDeleteButton = isAdded && onDeleteCard;
  const shouldShowAddToOrder = hovered && !isAdded;

  const handleButtonClick = () => {
    setIsModalOpened(true);
  };

  const onCancel = () => {
    onDeleteCard?.(id);
  };

  const handleCloseModal = () => {
    setIsModalOpened(false);
  };

  const toggleMouseOver = (hover: boolean) => () => {
    if (!isAdded) {
      setHovered(hover);
    }
  };

  const onSubmit = useCallback((data: ProductCardFormData) => {
    onSelectCard?.({
      quantity: data.quantity,
      tags: data.tags,
      id,
      sku,
    });
    handleCloseModal();
  }, []);

  return (
    <div
      className="select-product-card"
      onMouseEnter={toggleMouseOver(true)}
      onMouseLeave={toggleMouseOver(false)}>
      <div className="card-left">
        {isModalOpened && (
          <ProductCardModal
            skuId={sku.id}
            onClose={handleCloseModal}
            onSubmit={onSubmit}
          />
        )}
        <div className="card-info">
          <div className="card-info-person">
            <OrderSubtitle title={productName} name={sku.name} />
          </div>
          <div className="card-info-details">
            <div className="card-rating">
              <span>Salaries starting</span>
              <div className="card-grade">
                SAR {formatNumber(sku.salaryMinimum)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-delivery">
        <div className="card-grade">delivery in {sku.deliveryDate} days</div>
      </div>
      <div className="card-right">
        {isAdded && <div className="card-quantity">{quantity}</div>}
        {<div className="card-price">SAR {formatNumber(pricePerUnit)}</div>}

        {shouldShowAddToOrder && (
          <button className="btn-add-to" onClick={handleButtonClick}>
            Add to Order
          </button>
        )}

        {isAdded && (
          <button className="btn-added" onClick={onCancel}>
            Added
            <img src={check} alt="+" />
          </button>
        )}

        {shouldShowDeleteButton && (
          <button className="btn-delete" onClick={onCancel}>
            <img src={clear} alt="U+0078" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
