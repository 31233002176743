import { useCreateAdminMutation } from '@/entities/Admin/api/adminApi';
import SectionButton from '@/shared/components/Button/SectionButton/SectionButton';
import BranchInput from '@/shared/components/Input/BranchInput/BranchInput';
import StatusInput from '@/shared/components/Input/BranchInput/StatusInput';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styles from './AdminForm.module.scss';
import { AdminTypes } from '@/entities/Admin/admin.models';
import PhoneInput from 'react-phone-input-2';
import { YupSchemaType, adminYupSchema } from '../../helper';

export const AdminForm = () => {
  const [createAdmin] = useCreateAdminMutation();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(adminYupSchema) });

  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (formData) => {
    createAdmin({
      name: formData.name,
      adminType: formData.status as AdminTypes,
      email: formData.email,
      phone: '+' + formData.phone,
    })
      .unwrap()
      .then(() => {
        reset();
      });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)} className={styles.base}>
        <Controller
          control={control}
          name="name"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <BranchInput
              placeholder="Admin name"
              onChange={(e) => onChange(e.target.value)}
              value={value}
              error={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="status"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <StatusInput
              placeholder="Status"
              onChange={(e) => onChange(e.target.value)}
              value={value}
              error={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <BranchInput
              placeholder="Email"
              onChange={(e) => onChange(e.target.value)}
              value={value}
              error={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="phone"
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <div>
              <PhoneInput
                country={'sa'}
                value={value}
                onChange={onChange}
                containerClass={styles.phoneInput}
                inputProps={{
                  required: true,
                }}
              />
              {errors.phone && (
                <div className={styles.error}>{errors.phone.message}</div>
              )}
            </div>
          )}
        />
        <SectionButton
          label="+ Add new admin"
          className={styles.addAdmin}
          type="submit"
        />
      </form>
      {errors && <div className={styles.error}>{errors.root?.message}</div>}
    </>
  );
};
