import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type Props = {
  name: string;
  selectOptions: Array<{ label: string; value: any }>;
  icon?: string;
  control: Control<any>;
  label?: string;
  classMix?: string;
  disableClearable?: boolean;
};

export const BasicSelect: React.FC<Props> = ({
  name,
  selectOptions,
  icon,
  control,
  label,
  classMix,
  disableClearable,
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <>
          <Autocomplete
            disableClearable={disableClearable}
            disablePortal
            options={selectOptions || []}
            onChange={(event, val) => {
              onChange(val?.value);
            }}
            value={
              selectOptions.find((option) => option.value === value) || null
            }
            inputValue={inputValue}
            onBlur={onBlur}
            popupIcon={<KeyboardArrowDownIcon />}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            classes={{
              inputRoot: `rounded-[20px] ${classMix} `,
              endAdornment: 'ml-[100px]',
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="rounded-[20px]"
                placeholder={label}
                helperText={error?.message}
                error={!!error?.message}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    backgroundColor: 'white',
                    borderRadius: '12px',
                    width: '283px',
                    height: '44px',
                    alignContent: 'center',

                    '& .MuiAutocomplete-input': {
                      fontSize: '14px',
                      color: '#B2B5C8',
                    },
                  },
                  startAdornment: icon && (
                    <div className="mr-[7px] ml-[12px]">
                      {<img src={icon} width={24} height={24} alt="icon" />}
                    </div>
                  ),
                }}
              />
            )}
          />
        </>
      )}
    />
  );
};
