import React from 'react';
import * as yup from 'yup';
import 'yup-phone-lite';
import {
  FieldValues,
  SubmitHandler,
  UseFormRegister,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TemplatePage from '@/pages/TemplatePage/TemplatePage';
import { useCreateBranchMutation } from '@/entities/Branch/api/branchApi';
import {
  CitiesTypes,
  LocationTypes,
  WorkplaceTypes,
} from '@/entities/Branch/branch.models';
import RegOrgSection from '@/features/RegOrgSection/RegOrgSection';
import { Steps } from '@/pages/Organizations/RegisterOrg/Steps/Steps';
import {
  branchInputs,
  contactInputs,
} from '@/pages/Organizations/RegisterOrg/regOrgInputs';

const yupSchema = yup
  .object({
    firstName: yup.string().required('Required field'),
    lastName: yup.string().required('Required field'),
    phone: yup.string().required('Required field'),
    email: yup.string().email('Invalid email format'),
    branchName: yup.string().required('Required field'),
    country: yup.string().required('Required field'),
    city: yup.string().required('Required field'),
    district: yup.string().required('Required field'),
    locationType: yup.string().required('Required field'),
    workplaceType: yup.string().required('Required field'),
    googleMapsLink: yup
      .string()
      .url('Invalid URL format')
      .required('Required field'),
  })
  .required();

export type YupSchemaType = yup.InferType<typeof yupSchema>;

export const RegisterBranch = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [createBranch] = useCreateBranchMutation();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm<YupSchemaType>({
    resolver: yupResolver(yupSchema),
  });

  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (formData) => {
    createBranch({
      name: formData.branchName,
      country: formData.country,
      city: formData.city as CitiesTypes,
      locationType: formData.locationType as LocationTypes,
      workplaceType: formData.workplaceType as WorkplaceTypes,
      district: formData.district,
      organization: +id!,
      googleMapsLink: formData.googleMapsLink,
      contacts: [
        {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
        },
      ],
    })
      .unwrap()
      .then(() => {
        navigate(-1);
      });
  };

  return (
    <TemplatePage>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Steps
          onCancel={() => navigate(-1)}
          onComplete={handleSubmit(onSubmitHandler)}>
          <RegOrgSection
            title="Add Contact"
            inputEntries={contactInputs}
            register={register as unknown as UseFormRegister<FieldValues>}
            errors={errors}
            control={control}
            validate={() => {
              return trigger(['firstName', 'lastName', 'phone', 'email']);
            }}
            confirmButtonTitle="To branch"
            ButtonIcon={ArrowForwardIcon}
          />
          <RegOrgSection
            title="Add Branch"
            inputEntries={branchInputs}
            register={register as unknown as UseFormRegister<FieldValues>}
            errors={errors}
            control={control}
            validate={() => {
              return trigger([
                'branchName',
                'country',
                'city',
                'district',
                'locationType',
                'workplaceType',
                'googleMapsLink',
              ]);
            }}
            confirmButtonTitle="Finish"
          />
        </Steps>
      </form>
    </TemplatePage>
  );
};
