import React from 'react';
import styles from './Select.module.scss';
import { KeyboardArrowDown } from '@mui/icons-material';

export type Props<T> = {
  options: Array<{ label: string; value: T }>;
  value: T;
  onChange: (value: T) => void;
  className?: string;
};

export const Select = <T,>({
  options,
  value,
  onChange,
  className,
}: Props<T>) => {
  return (
    <div className={styles.wrapper}>
      <select
        className={`${styles.base} ${className || ''}`}
        value={JSON.stringify(value)}
        onChange={(e) => onChange(JSON.parse(e.target.value) as unknown as T)}>
        {options.map((option, index) => (
          <option key={index} value={JSON.stringify(option.value)}>
            {option.label}
          </option>
        ))}
      </select>
      <div className={styles.arrow}>
        <KeyboardArrowDown />
      </div>
    </div>
  );
};
