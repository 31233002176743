import React from 'react';
import NavLinkCustom from '../../../../shared/components/Link/NavLink/NavLink';
import dashboardIcon from '../../../../assets/icons/navLinks/dashboard.svg';
import ordersIcon from '../../../../assets/icons/navLinks/orders.svg';
import orgsIcon from '../../../../assets/icons/navLinks/organizations.svg';
import customerCareIcon from '../../../../assets/icons/navLinks/customer-care.svg';
import contactsIcon from '../../../../assets/icons/navLinks/contacts.svg';
import billingIcon from '../../../../assets/icons/navLinks/money.svg';
import orgsHoverIcon from '../../../../assets/icons/navLinks/org-solid.svg';
import ordersHoverIcon from '../../../../assets/icons/navLinks/order-solid.svg';
import contactsHoverIcon from '../../../../assets/icons/navLinks/contact-solid.svg';
import billingHoverIcon from '../../../../assets/icons/navLinks/money-solid.svg';
import './NavMenu.scss';
import { AdminTypes } from '@/entities/Admin/admin.models';
import { roleApi } from '@/entities/Role/api/roleApi';

const BodyNavigation = () => {
  const { data: userRole } = roleApi.endpoints.getRole.useQueryState('');
  return (
    <nav className="nav-menu">
      <NavLinkCustom icon={dashboardIcon} label="My Dashboard" path="/" />
      <NavLinkCustom
        icon={ordersIcon}
        hoverIcon={ordersHoverIcon}
        label="Orders"
        path="/orders"
      />
      <NavLinkCustom
        icon={contactsIcon}
        hoverIcon={contactsHoverIcon}
        label="Contacts"
        path="/contacts"
      />
      <NavLinkCustom
        icon={orgsIcon}
        hoverIcon={orgsHoverIcon}
        label="Organizations"
        path="/organizations"
      />
      {userRole &&
        [AdminTypes.super, AdminTypes.accountant].includes(userRole.role) && (
          <NavLinkCustom
            icon={billingIcon}
            hoverIcon={billingHoverIcon}
            label="Billing"
            path="/billing"
          />
        )}

      <NavLinkCustom
        icon={customerCareIcon}
        label="Customer Care"
        path="/customer-care"
      />
      <NavLinkCustom icon={orgsIcon} label="Products prices" path="/sku" />
    </nav>
  );
};

export default BodyNavigation;
