import React, { useCallback } from 'react';
import NotificationsOff from '@mui/icons-material/NotificationsOff';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import { IconButton, Menu, MenuItem } from '@mui/material';
import styles from './Notification.module.scss';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from './useNotifications';
import { getRouteByNotificationType, Body } from './notificationToRoute';

export const Notification = () => {
  const { notifications, onClear } = useNotifications();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (notifications && notifications.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNotification = useCallback(
    (body: Body) => async () => {
      const route = getRouteByNotificationType(body);
      if (route) {
        navigate(route);
      }
    },
    [handleClose],
  );

  const handleClear = () => {
    onClear();
  };

  return (
    <div className={styles.base}>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        {notifications.length > 0 ? (
          <NotificationsActive color="warning" fontSize="large" />
        ) : (
          <NotificationsOff fontSize="large" />
        )}
      </IconButton>
      <Menu
        hidden={notifications.length === 0}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {notifications.map((notification) => {
          return (
            <MenuItem
              onClick={handleClickNotification(notification.body)}
              key={notification.title}>
              {notification.title}
            </MenuItem>
          );
        })}
        <MenuItem onClick={handleClear} key={'clear'}>
          Clear notifications
        </MenuItem>
      </Menu>
    </div>
  );
};
