import React from 'react';
import { IConfig } from '@/entities/Config/config.model';
import { useGetConfigQuery } from '@/entities/Config/configApi';

interface ConfigProps {
  config: IConfig;
}

export const withConfig = <T extends ConfigProps>(
  WrappedComponent: React.FC<T>,
): React.FC<Omit<T, 'config'>> => {
  return function SummaryComponent(props: Omit<T, 'config'>) {
    const { data: configData } = useGetConfigQuery('');

    const enhancedProps = {
      ...props,
      config: configData,
    };

    return <WrappedComponent {...(enhancedProps as T)} />;
  };
};
