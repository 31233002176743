import React, { useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './ContactTable.module.scss';
import arrowRight from '@/assets/icons/chevron-right.svg';
import arrowBack from '@/assets/icons/chevron-left.svg';
import SectionButton from '@/shared/components/Button/SectionButton/SectionButton';
import { useGetContactByIdQuery } from '@/entities/Contact/api/contactApi';
import contactIcon from '@/assets/icons/contact.svg';
import orgIcon from '@/assets/icons/organization.svg';
import branchIcon from '@/assets/icons/branch.svg';
import phoneIcon from '@/assets/icons/phone.svg';
import emailIcon from '@/assets/icons/email.svg';
import ordersIcon from '@/assets/icons/order-blue.svg';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  IOrder,
  IOrderWithSkuRequestContactBranchOrganization,
  ISKURequest,
  OrderStatuses,
} from '@/entities/Order/order.models';
import moment from 'moment';
import { BasicSelect } from '@/shared/components/Select/BasicSelect/BasicSelect';
import { useForm } from 'react-hook-form';
import { statusDisplayName } from '@/pages/Dashboard/components/Stats/components/OrdersTable/OrdersTable';
import { formatNumber } from '@/shared/helpers/formatNumber';
import userIcon from '@/assets/icons/user.svg';

export const statusOption = Object.entries(statusDisplayName)
  .slice(0, 2)
  .map(([value, label]) => ({ label, value }));

export const ContactTable = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: contactData,
    isLoading,
    isSuccess,
  } = useGetContactByIdQuery({
    id: id!,
    relations: [
      'branch.organization.relationshipManager',
      'orders.skuRequests',
      'skuRequests',
    ],
  });

  const hasActiveOrders =
    isSuccess &&
    contactData.orders.some(
      (order: IOrder) =>
        order.orderStatus === OrderStatuses.PENDING_CONFIRMATION ||
        order.orderStatus === OrderStatuses.PENDING_PAYMENT ||
        (order.orderStatus === OrderStatuses.PENDING_FULFILLMENT &&
          moment().diff(moment(order.createdAt), 'days') <= 45),
    );

  const activeOrders = useMemo(() => {
    if (isSuccess) {
      return contactData.orders.filter(
        (order: IOrder) =>
          order.orderStatus === OrderStatuses.PENDING_CONFIRMATION ||
          order.orderStatus === OrderStatuses.PENDING_PAYMENT ||
          (order.orderStatus === OrderStatuses.PENDING_FULFILLMENT &&
            moment().diff(moment(order.createdAt), 'days') <= 45),
      );
    }
    return [];
  }, [contactData, isSuccess]);

  const { control, watch } = useForm();

  const selectedStatus = watch('status');

  const filteredOrders = useMemo(() => {
    if (selectedStatus) {
      return activeOrders.filter(
        (order: IOrder) => order.orderStatus === selectedStatus,
      );
    }
    return activeOrders;
  }, [activeOrders, selectedStatus]);

  const totalCrewsNumber = useMemo(() => {
    return activeOrders.reduce(
      (acc: number, order: IOrderWithSkuRequestContactBranchOrganization) => {
        const crewsNumber = order.skuRequests?.reduce(
          (skuAcc: number, skuRequest) => skuAcc + skuRequest.crewsNumber,
          0,
        );
        return acc + (crewsNumber || 0);
      },
      0,
    );
  }, [activeOrders]);

  const totalValue = useMemo(() => {
    return activeOrders.reduce(
      (acc: number, order: IOrder) => acc + order.total,
      0,
    );
  }, [activeOrders]);

  return (
    <div>
      <div className={styles.nav}>
        <Link className={styles.navLinkBack} to={'/contacts'}>
          <img src={arrowBack} alt="back" />
        </Link>
        <div className={styles.navLink}>Contact list</div>
        <img src={arrowRight} alt="arrow right" />
        <div className={styles.navLinkActive}>Contact detail</div>
      </div>

      {isSuccess && (
        <div className={styles.contactScreen}>
          <div className={styles.contactInfo}>
            <div className={styles.contactInfoTop}>
              <img src={contactIcon} alt="org" />
              <Link key={id} to={`/edit-contact/${id}`}>
                <SectionButton
                  className={styles.contactInfoEdit}
                  label="Edit"
                />
              </Link>
            </div>

            <div className={styles.contactInfoName}>
              {`${contactData.firstName} ${contactData.lastName}`}
            </div>

            <div className={styles.contactDetails}>
              <div className={styles.contactDetailsItem}>
                <img src={orgIcon} alt="icon" />
                <div className={styles.contactDetailsText}>
                  <p className={styles.contactDetailsTitle}>Organisation</p>
                  <p className={styles.contactDetailsValue}>
                    {contactData.branch.organization.name}
                  </p>
                </div>
              </div>

              <div className={styles.contactDetailsItem}>
                <img src={branchIcon} alt="icon" />
                <div className={styles.contactDetailsText}>
                  <p className={styles.contactDetailsTitle}>Branch</p>
                  <p className={styles.contactDetailsValue}>
                    {contactData.branch.name}
                  </p>
                </div>
              </div>

              <div className={styles.contactDetailsItem}>
                <img src={userIcon} alt="icon" />
                <div className={styles.contactDetailsText}>
                  <p className={styles.contactDetailsTitle}>
                    Relationship Manager
                  </p>
                  <p className={styles.contactDetailsValue}>
                    {contactData.branch.organization.relationshipManager.name}
                  </p>
                </div>
              </div>

              <div className={styles.contactDetailsItem}>
                <img src={phoneIcon} alt="icon" />
                <div className={styles.contactDetailsText}>
                  <p className={styles.contactDetailsTitle}>Mobile #</p>
                  <p className={styles.contactDetailsValue}>
                    {contactData.phone}
                  </p>
                </div>
              </div>

              <div className={styles.contactDetailsItem}>
                <img src={emailIcon} alt="icon" />
                <div className={styles.contactDetailsText}>
                  <p className={styles.contactDetailsTitle}>Email Address</p>
                  <p className={styles.contactDetailsValue}>
                    {contactData.email}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.orders}>
            <div className={styles.ordersTop}>
              <div className={styles.ordersTitle}>
                <img src={ordersIcon} alt="icon" />
                <p className={styles.ordersTitle}>Orders</p>
              </div>
              <div className={styles.filter}>
                <BasicSelect
                  control={control}
                  name="status"
                  selectOptions={statusOption}
                  label="All statuses"
                />
              </div>
            </div>

            <TableContainer className={styles.tableContainer}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Requested</TableCell>
                    <TableCell>Order #</TableCell>
                    <TableCell>RM</TableCell>
                    <TableCell>Positions</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading &&
                    Array(5)
                      .fill(0)
                      .map((_, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Skeleton variant="rounded" />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="rounded" />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="rounded" />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="rounded" />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="rounded" />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="rounded" />
                          </TableCell>
                        </TableRow>
                      ))}

                  {hasActiveOrders
                    ? filteredOrders.map((order: IOrder, index: number) => (
                        <TableRow
                          key={order.id}
                          onClick={() => navigate(`/view-order/${order.id}`)}
                          className={`${styles.row} ${
                            index % 2 === 0 ? styles.colorRow : styles.whiteRow
                          }`}>
                          <TableCell
                            sx={{
                              fontSize: '14px',
                              color: '#141A7F',
                            }}>
                            {moment(order.createdAt).format('DD MMM')}
                          </TableCell>
                          <TableCell>{order.id}</TableCell>
                          <TableCell
                            sx={{
                              fontSize: '11px',
                              color: '#797B8C',
                            }}>
                            {
                              contactData.branch.organization
                                .relationshipManager.name
                            }
                          </TableCell>
                          <TableCell>
                            {order.skuRequests?.reduce(
                              (acc: number, item: ISKURequest) =>
                                acc + item.crewsNumber,
                              0,
                            )}
                          </TableCell>
                          <TableCell>SAR {formatNumber(order.total)}</TableCell>

                          <TableCell>
                            {statusDisplayName[order.orderStatus]}
                          </TableCell>
                        </TableRow>
                      ))
                    : !hasActiveOrders &&
                      !isLoading && (
                        <TableRow>
                          <TableCell
                            colSpan={8}
                            sx={{
                              borderBottom: 'none',
                            }}>
                            <div className={styles.noDataContainer}>
                              <p className={styles.noDataMainText}>
                                There&apos;s no active orders
                              </p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                </TableBody>
              </Table>
            </TableContainer>
            {hasActiveOrders && (
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell
                        sx={{
                          fontSize: '14px',
                          fontWeight: '600',
                          color: '#141A7F',
                        }}>
                        {totalCrewsNumber}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '14px',
                          fontWeight: '600',
                          color: '#141A7F',
                        }}>
                        SAR {formatNumber(totalValue)}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
