import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-input-2';
import SectionButton from '../../../../../../shared/components/Button/SectionButton/SectionButton';
import ContactInput from '../../../../../../shared/components/Input/ContactInput/ContactInput';
import styles from './AddContactForm.module.scss';
import { Modal } from '@mui/material';

const yupSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email(),
  phone: yup.string().phone('SA').required(),
});

export type YupSchemaType = yup.InferType<typeof yupSchema>;

type AddContactFormProps = {
  onSubmit: (form: YupSchemaType) => Promise<boolean>;
  onClose: () => void;
};

export const AddContactForm = ({ onSubmit, onClose }: AddContactFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema),
  });
  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (formData) => {
    const isDone = await onSubmit({
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
    });
    if (isDone) {
      reset();
      onClose();
    }
  };

  return (
    <Modal className={styles.newContact} open onClose={onClose}>
      <form
        className={styles.newContactForm}
        onSubmit={handleSubmit(onSubmitHandler)}>
        <div className={styles.newContactInputs}>
          <div className={styles.newContactInputsTitle}>Contact data</div>
          <ContactInput
            error={errors.firstName?.message}
            label="First name"
            {...register('firstName')}
          />
          <ContactInput
            error={errors.lastName?.message}
            label="Last name"
            {...register('lastName')}
          />
          <ContactInput
            error={errors.email?.message}
            label="Email"
            {...register('email')}
          />
          <div className={styles.newContacInputsPhone}>
            <label className={styles.contactLabel}>Phone</label>
            <Controller
              control={control}
              name="phone"
              rules={{ required: true }}
              render={({
                field: { ref, ...field },
                formState: {
                  errors: { phone },
                },
              }) => (
                <>
                  <PhoneInput
                    {...field}
                    onChange={(e) => {
                      field.onChange('+' + e);
                    }}
                    inputProps={{
                      ref,
                      required: true,
                      autoFocus: true,
                    }}
                    country={'sa'}
                  />
                  {phone && <div className={styles.error}>{phone.message}</div>}
                </>
              )}
            />
          </div>
        </div>
        <SectionButton type="submit" label="Confirm" />
      </form>
    </Modal>
  );
};
