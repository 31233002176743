import React from 'react';
import edit from '../../../assets/icons/orderPage/edit.svg';
import SummaryBrochure from '../../../entities/Order/SummaryBrochure/SummaryBrochure';
import styles from './ContractSummary.module.scss';

interface PropTypes {
  contractType: string;
  maxSalary: number;
  isHousing: boolean;
  femalesPercentage?: number;
  onEditClick: () => void;
}

const ContractSummary = (props: PropTypes) => {
  const { contractType, maxSalary, isHousing, femalesPercentage, onEditClick } =
    props;
  return (
    <div className={styles.base}>
      <SummaryBrochure
        contractType={contractType}
        maxSalary={maxSalary}
        isHousing={isHousing}
        femalesPerc={femalesPercentage}
      />
      <div className={styles.edit} onClick={() => onEditClick()}>
        <img src={edit} alt="edit" />
      </div>
    </div>
  );
};

export default ContractSummary;
