import { signOut } from 'firebase/auth';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';

export const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
) => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);
};

export const useSignOut = () => {
  const navigate = useNavigate();

  const signOutUser = useCallback(() => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        localStorage.removeItem('token');
        navigate('/login');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Sign out error:', error);
      });
  }, [navigate]);

  return signOutUser;
};
