import React, { useEffect, useRef, useState } from 'react';
import order from '../../../../assets/icons/orderPage/order.svg';
import './SelectProduct.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  IFiltersTypes,
  IOrderCard,
} from '../../../../entities/Order/order.models';
import { ISkuResponse, ITag } from '../../../../entities/SKU/SKUModel.models';
import TemplatePage from '../../../TemplatePage/TemplatePage';
import OrderTitle from '../../../../shared/components/Title/OrderTitle/OrderTitle';
import BasicOrderButton from '../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import NextOrderButton from '../../../../shared/components/Button/OrderButtons/NextOrderButton/NextOrderButton';
import OrderStagesPanel, {
  StageData,
} from '../../../../widgets/Order/OrderStagesPanel/OrderStagesPanel';
import SelectProductFilters from '../../../../features/Filters/SelectProductFilters/SelectProductFilters';
import { useGetSkuQuery } from '../../../../entities/SKU/api/SKUApi';
import ProductCard from './components/ProductCard/ProductCard';

const SelectProduct = () => {
  const location = useLocation();
  const { contactData, orderProductCards, id } = location.state || {};
  const { freePosition } = contactData || {};

  const [stagesData, setStagesData] = useState<StageData[]>([
    {
      label: 'Crew',
      active: true,
      bold: false,
      secondLine: '',
      state: { id, freePosition },
      link: '/select-product',
    },
    {
      label: 'Contract & Schedule',
      active: false,
      state: { id },
      link: '/contract-schedule',
    },
    {
      label: 'Review Summary Order',
      active: false,
      state: { id },
      link: '/summary-order',
    },
  ]);
  const [selectedFilters, setSelectedFilters] = useState({
    jobGroup: 'All job groups',
    product: 'All jobs',
    legalType: 'All legal types',
  });
  const [selectedCards, setSelectedCards] = useState<IOrderCard[]>([]);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const navigate = useNavigate();

  const totalCrewQuantityRef = useRef<number>(0);
  const { data: skus, isSuccess } = useGetSkuQuery({});

  useEffect(() => {
    if (totalCrewQuantityRef.current < 6) {
      setAlertMessage(
        'Please select at least six crew member before proceeding.',
      );
    } else if (
      freePosition &&
      totalCrewQuantityRef.current > contactData.positionNumber
    ) {
      setAlertMessage(
        'The number of positions exceeds the available free positions.',
      );
    } else {
      setAlertMessage('');
    }
  }, [totalCrewQuantityRef.current, totalCrewQuantityRef.current]);

  useEffect(() => {
    if (orderProductCards) {
      setSelectedCards(orderProductCards);
    }
  }, [orderProductCards]);

  useEffect(() => {
    setStagesData((prevStagesData) => {
      totalCrewQuantityRef.current = selectedCards.reduce(
        (sum, card) => sum + card.quantity,
        0,
      );

      const newStagesData = [...prevStagesData];
      return newStagesData;
    });
  }, [selectedCards]);

  const handleFiltersChange = (filters: IFiltersTypes) => {
    setSelectedFilters(filters);
  };
  const handleSelectCard = (data: {
    quantity: number;
    id: number;
    tags: ITag[];
    sku: ISkuResponse;
  }) => {
    setSelectedCards((prevSelectedCards) => {
      const existingCardIndex = prevSelectedCards.findIndex(
        (card) => card.id === data.id,
      );

      const cardData: IOrderCard = {
        tags: data.tags,
        id: data.id,
        quantity: data.quantity,
        product: data.sku.productName,
        price: data.sku.pricePerUnit,
        grade: data.sku.qualityGrade,
        salaryMinimum: data.sku.salaryMinimum,
        interviewShifts: [],
        ordInv: data.sku.orderableInventory,
        deliveryDate: data.sku.deliveryDate,
      };

      if (existingCardIndex !== -1) {
        const updatedSelectedCards = [...prevSelectedCards];
        updatedSelectedCards[existingCardIndex].quantity = cardData.quantity;
        updatedSelectedCards[existingCardIndex].tags = cardData.tags;
        return updatedSelectedCards;
      } else {
        return [...prevSelectedCards, cardData];
      }
    });
  };

  const handleDeleteCard = (id: number) => {
    setSelectedCards((prevSelectedCards) =>
      prevSelectedCards.filter((card) => card.id !== id),
    );
  };
  const handleNavigateToNextPage = () => {
    setAlertVisible(true);
    if (!alertMessage) {
      navigate('/contract-schedule', {
        state: {
          contactData,
          orderProductCards: selectedCards,
          totalCrewQuantityRef,
          id,
        },
      });
    }
  };
  if (!isSuccess) return <div>Loading...</div>;
  const filteredSkus = skus
    .filter((sku) => {
      if (selectedFilters.jobGroup === 'All job groups') return true;
      return sku.productGroupName === selectedFilters.jobGroup;
    })
    .filter((sku) => {
      if (selectedFilters.product === 'All jobs') return true;
      return sku.productName === selectedFilters.product;
    })
    .filter((sku) => {
      if (selectedFilters.legalType === 'All legal types') return true;
      return sku.legalType === selectedFilters.legalType;
    });

  return (
    <div className="select-product-container">
      <TemplatePage>
        <div className="select-product">
          <div className="select-product-header">
            <OrderTitle title="Select Product" icon={order} />
            <div className="select-product-btns">
              <Link to={'/orders'}>
                <BasicOrderButton label="Cancel" />
              </Link>
              <div
                onClick={handleNavigateToNextPage}
                style={{ cursor: 'pointer' }}>
                <NextOrderButton label="Next" />
              </div>
            </div>
          </div>
          <OrderStagesPanel data={stagesData} />
          <div className="hr"></div>
          <div className="select-product-filters-validation">
            {isSuccess && (
              <SelectProductFilters
                skus={filteredSkus}
                selectedFilters={selectedFilters}
                onFiltersChange={handleFiltersChange}
              />
            )}
            {alertVisible && (
              <div className="validation-message">{alertMessage}</div>
            )}
          </div>
          <div className="select-product-cards">
            {isSuccess &&
              filteredSkus.map((sku) => {
                const addedCard = selectedCards.find((el) => el.id === sku.id);
                return (
                  <ProductCard
                    key={sku.id}
                    qualityName={sku.qualityName}
                    grade={sku.qualityId}
                    id={sku.id}
                    pricePerUnit={sku.pricePerUnit}
                    productName={sku.productName}
                    onSelectCard={handleSelectCard}
                    onDeleteCard={handleDeleteCard}
                    orderableInventory={sku.orderableInventory}
                    tags={sku.tags}
                    isAdded={!!addedCard}
                    quantity={addedCard?.quantity}
                    sku={sku}
                  />
                );
              })}
          </div>
        </div>
      </TemplatePage>
    </div>
  );
};

export default SelectProduct;
