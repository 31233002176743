export type Candidate = {
  name: string;
  positionRefId: string;
  refId: string;
  candidateStatus: CandidateStatus;
};

export type OfferResponse = {
  shiftId: number;
  shiftRefId: string;
  orderStartDate: string;
  orderEndDate: string;
  candidates: Candidate[];
};

export type SendOffer = {
  candidateRefId: string;
  positionRefId: string;
  clientResponse: string;
  salaryOffered: number;
  isHousing: boolean;
};

export enum CandidateStatus {
  NOT_JOIN = 'NoShow',
  JOINED = 'Joined',
  PENDING_FOR_DECISION = 'PendingForDecision',
  REJECTED = 'Rejected',
  OFFERED = 'Offered',
  OFFER_ACCEPTED = 'OfferAccepted',
  OFFER_REJECTED = 'OfferRejected',
  OFFER_EXPIRED = 'OfferExpired',
}
