import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../shared/api/api';
import { AdminTypes } from '../../Admin/admin.models';
import { RootState } from '../../../store/store';

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: fetchBaseQuery({
    ...baseQueryConfig,
    prepareHeaders: async (headers: Headers, { getState }) => {
      // If we have a token set in state, let's assume that we should be passing it. Only case for roles and orders
      const token = await (getState() as RootState).user.user?.getIdToken();
      token && headers.set('Authorization', token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getRole: builder.query<{ role: AdminTypes }, string>({
      query: () => ({
        url: '/admin/type',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetRoleQuery } = roleApi;
