export enum NotificationType {
  invoice = 'invoice',
  review = 'review',
  payment = 'payment',
  refund = 'refund',
}

export type Body = {
  type: NotificationType;
  id: number;
};

export type RouteWithPayload = {
  route: string;
  payload: object;
};

export const getRouteByNotificationType = (body: Body) => {
  switch (body.type) {
    case NotificationType.invoice:
      return '/invoice-form/' + body.id;
    case NotificationType.review:
      return '/customer-details/' + body.id;
    case NotificationType.payment:
      return '/payment-form/' + body.id;
    case NotificationType.refund:
      return '/refund-form/' + body.id;
  }
};
