import React from 'react';
import './BranchInput.scss';

interface PropTypes {
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  error?: string;
}

const BranchInput = (props: PropTypes) => {
  const { placeholder, onChange, value, error } = props;
  return (
    <div className="branch-control">
      <input
        className="branch-input"
        id="branch-input"
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value || ''}
      />
      {error && <div className="branch-error">{error}</div>}
    </div>
  );
};

export default BranchInput;
