import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '../../../shared/components/Button/IconButton/IconButton';
import googleIcon from '../../../assets/icons/google-login.svg';
import './LoginForm.scss';
import FormWrapper from '../FormWrapper/FormWrapper';
import { provider } from '../../../services/firebase';
import { getAuth, signInWithPopup } from 'firebase/auth';
import { useSignOut } from '../../../services/customHooks';
import { useTriggerAdminMutation } from '@/entities/Admin/api/adminApi';

const auth = getAuth();

const LoginForm = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const [triggerAdmin] = useTriggerAdminMutation();

  const handleSubmitForm = async () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        await triggerAdmin({ email: result.user.email!, uid: result.user.uid });
        navigate('/');
      })
      .catch(() => {
        signOut();
        navigate('/login');
      });
  };

  return (
    <FormWrapper pageTitle="Sign In">
      <IconButton
        icon={googleIcon}
        label="Sign in with Google"
        onClick={handleSubmitForm}
      />
    </FormWrapper>
  );
};

export default LoginForm;
