import React from 'react';
import './OrgEdit.scss';
import { useParams } from 'react-router-dom';
import { useGetOrganizationByIdQuery } from '../../../entities/Organization/api/organizationApi';
import { Skeleton } from '@mui/material';
import { EditForm } from './EditForm/EditForm';
import TemplatePage from '@/pages/TemplatePage/TemplatePage';

const OrgEdit = () => {
  const { id } = useParams();
  if (!id) {
    return null;
  }

  const { data, isLoading, isSuccess } = useGetOrganizationByIdQuery(id);

  return (
    <TemplatePage>
      {isLoading && <Skeleton variant="rectangular" height={400} />}
      {isSuccess && <EditForm data={data} />}
    </TemplatePage>
  );
};

export default OrgEdit;
