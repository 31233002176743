import RegOrgSection from '@/features/RegOrgSection/RegOrgSection';
import React, { useCallback } from 'react';
import {
  FieldValues,
  SubmitHandler,
  UseFormRegister,
  useForm,
} from 'react-hook-form';
import * as yup from 'yup';
import { useUpdateOrganizationMutation } from '@/entities/Organization/api/organizationApi';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import SectionButton from '@/shared/components/Button/SectionButton/SectionButton';
import { IOrganization } from '@/entities/Organization/org.models';
import 'yup-phone-lite';

import { orgInputs } from '../../RegisterOrg/regOrgInputs';
import styles from './EditForm.module.scss';

const yupSchema = yup
  .object({
    name: yup.string().required('Required field'),
    relationshipManager: yup
      .object({ id: yup.number().required(), label: yup.string() })
      .required('Required field'),
    employeesAmount: yup.number().required(),
    taxReg: yup.string().test('len', 'Must be exactly 15 characters', (val) => {
      if (!val?.length) return true;
      return val.length === 15;
    }),
    industry: yup.string().required('Required field'),
  })
  .required();

type YupSchemaType = yup.InferType<typeof yupSchema>;

export type Props = {
  data: IOrganization;
};

export const EditForm = ({ data }: Props) => {
  const [updateOrganization] = useUpdateOrganizationMutation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      name: data.name,
      taxReg: data.taxReg !== null ? data.taxReg.toString() : '',
      employeesAmount: data.employeesAmount,
      industry: data.industry,
      relationshipManager: {
        ...data.relationshipManager,
        id: data.relationshipManager.id,
        label: data.relationshipManager.name,
      },
    },
  });

  const onSubmitHandler: SubmitHandler<YupSchemaType> = useCallback(
    async (formData) => {
      updateOrganization({
        id: data.id,
        name: formData.name,
        taxReg: formData.taxReg ? Number(formData.taxReg) : undefined,
        relationshipManager: formData.relationshipManager.id,
        employeesAmount: formData.employeesAmount,
        industry: formData.industry,
      })
        .unwrap()
        .then(() => {
          navigate(-1);
        });
    },
    [getValues, data, navigate, updateOrganization],
  );

  return (
    <form
      className={styles.registerOrg}
      onSubmit={handleSubmit(onSubmitHandler)}>
      <div className={styles.centreForm}>
        <RegOrgSection
          control={control}
          title="Edit Organization"
          inputEntries={orgInputs}
          register={register as unknown as UseFormRegister<FieldValues>}
          errors={errors}
        />
      </div>
      <div className={styles.registerOrgButton}>
        <SectionButton label="Save" type="submit" isFilled={true} />
      </div>
    </form>
  );
};
