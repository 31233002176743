import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Modal from '../../../../../../../shared/components/Modal/Modal';
import SectionButton from '../../../../../../../shared/components/Button/SectionButton/SectionButton';
import { ITag } from '../../../../../../../entities/SKU/SKUModel.models';
import Input from '../../../../../../../shared/components/Input/Input/Input';
import { useGetSingleSkuQuery } from '../../../../../../../entities/SKU/api/SKUApi';
import './ProductCardModal.scss';
import CancelIcon from '@mui/icons-material/Cancel';

import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@mui/material';

export type ProductCardFormData = {
  quantity: number;
  tags: ITag[];
};

export type Props = {
  onClose: () => void;
  skuId: number;
  onSubmit: (data: ProductCardFormData) => void;
};

export const ProductCardModal = ({ onClose, skuId, onSubmit }: Props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<ProductCardFormData>({
    defaultValues: {
      quantity: 1,
      tags: [],
    },
  });

  const { data: skuData, isSuccess } = useGetSingleSkuQuery({
    id: skuId,
  });

  if (!isSuccess) return null;
  return (
    <Modal onClose={onClose}>
      <div className="modal-branch-label productName">
        {skuData.productName}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-control">
          {skuData.tags.length > 0 && (
            <FormControl sx={{ m: 1, width: 500 }}>
              <InputLabel>Tags</InputLabel>
              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    input={<OutlinedInput label="Tags" />}
                    renderValue={(selected) => (
                      <Stack gap={1} direction="row" flexWrap="wrap">
                        {selected.map((value) => (
                          <Chip
                            key={value.id}
                            label={value.name}
                            onDelete={() => {
                              const newSelected = field.value.filter(
                                (tag) => tag.id !== value.id,
                              );
                              field.onChange(newSelected);
                            }}
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                      </Stack>
                    )}>
                    {skuData.tags.map((tag) => (
                      <MenuItem key={tag.id} value={tag.name}>
                        {tag.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          )}
        </div>
        <Input
          label={'Quantity: '}
          type="number"
          error={errors.quantity?.message}
          {...register('quantity', {
            required: { message: 'This field is required', value: true },
            valueAsNumber: true,
            // max: {
            //   message:
            //     'Max allowed: ' +
            //     Math.floor(skuData.orderableInventory / ORDER_DIVIDER),
            //   value: skuData.orderableInventory / ORDER_DIVIDER,
            // },
            min: { message: 'Must be more than 1', value: 1 },
          })}
        />
        <SectionButton
          type="submit"
          className="add-product-submit"
          label="Add Product"
        />
      </form>
    </Modal>
  );
};
