import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import { IContact } from '../../../entities/Contact/contact.models';
import {
  useDeleteBranchMutation,
  useGetBranchByIdQuery,
} from '../../../entities/Branch/api/branchApi';
import TemplatePage from '@/pages/TemplatePage/TemplatePage';
import { Contacts } from './components/Contacts/Contacts';
import branchIcon from '@/assets/icons/branch-pink.svg';
import userIcon from '@/assets/icons/user.svg';
import contactIcon from '@/assets/icons/contacts.svg';
import cityIcon from '@/assets/icons/pin-map.svg';
import districtIcon from '@/assets/icons/store.svg';
import workplaceIcon from '@/assets/icons/workplace.svg';
import registeredIcon from '@/assets/icons/tax.svg';
import style from './ContactBranch.module.scss';
import styles from './ModalDeleteStyles.module.scss';

const ContactBranch = () => {
  const { branchId } = useParams<{ branchId: string }>();
  if (!branchId) {
    return null;
  }
  const { data: branchData, isSuccess } = useGetBranchByIdQuery(branchId!);

  const [deleteBranchById] = useDeleteBranchMutation();
  const navigate = useNavigate();
  const [isModalVisible, setModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      branch: '',
    },
  });

  const branchNameInput = watch('branch');

  useEffect(() => {
    if (branchNameInput === branchData?.name) {
      clearErrors('branch');
    } else if (branchNameInput && branchNameInput !== branchData?.name) {
      setError('branch', {
        type: 'manual',
        message: 'Branch doesnt match',
      });
    } else {
      clearErrors('branch');
    }
  }, [branchNameInput, setError, clearErrors, branchData?.name]);

  const toggleDeleteModal = () => {
    setIsDeleteModalVisible(
      (prevIsDeleteModalVisible) => !prevIsDeleteModalVisible,
    );
  };

  const isDeleteDisabled = !!errors.branch;

  const deleteBranch = useCallback(
    (id: number, isPrimaryBranch: boolean) => async () => {
      if (isPrimaryBranch) {
        setErrorBranch(true);
        return;
      }
      deleteBranchById(id.toString()).then(() => {
        navigate(-1);
      });
    },
    [deleteBranchById, navigate],
  );

  const handleDeleteBranch = () => {
    if (branchData?.name === branchNameInput) {
      deleteBranch(branchData?.id, branchData?.isPrimary ?? false)();
    }
  };

  const primaryContact = useMemo(
    () => branchData?.contacts?.find((contact: IContact) => contact.isPrimary),
    [branchData],
  );
  const creationDate = useMemo(
    () => branchData && new Date(branchData.createdAt).toLocaleString(),
    [branchData],
  );

  const [errorBranch, setErrorBranch] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  if (!isSuccess) return null;
  const { city, district, name, organization, workplaceType, id } = branchData;

  return (
    <TemplatePage>
      <div className={style.branchScreen}>
        <div className={style.branchInfo}>
          <div className={style.branchInfoTop}>
            <img src={branchIcon} alt="org" />
            <Link to={`/org-screen/`} state={{ orgId: organization?.id }}>
              <SectionButton
                className={style.branchInfoEdit}
                label="Organization page"
              />
            </Link>
          </div>
          <div>
            <div className={style.branchInfoName}>{name}</div>
          </div>

          <div className={style.branchDetails}>
            <div className={style.branchDetailsItem}>
              <img src={userIcon} alt="icon" />
              <div className={style.branchDetailsText}>
                <p className={style.branchDetailsTitle}>Supervisor</p>
                <p className={style.branchDetailsValue}>
                  {`${primaryContact?.firstName} ${primaryContact?.lastName}`}
                </p>
              </div>
            </div>

            <div className={style.branchDetailsItem}>
              <img src={cityIcon} alt="icon" />
              <div className={style.branchDetailsText}>
                <p className={style.branchDetailsTitle}>City</p>
                <p className={style.branchDetailsValue}>{city}</p>
              </div>
            </div>

            <div className={style.branchDetailsItem}>
              <img src={districtIcon} alt="icon" />
              <div className={style.branchDetailsText}>
                <p className={style.branchDetailsTitle}>District</p>
                <p className={style.branchDetailsValue}>{district}</p>
              </div>
            </div>

            <div className={style.branchDetailsItem}>
              <img src={workplaceIcon} alt="icon" />
              <div className={style.branchDetailsText}>
                <p className={style.branchDetailsTitle}>Workplace type</p>
                <p className={style.branchDetailsValue}>{workplaceType}</p>
              </div>
            </div>

            <div className={style.branchDetailsItem}>
              <img src={registeredIcon} alt="icon" />
              <div className={style.branchDetailsText}>
                <p className={style.branchDetailsTitle}>Registered</p>
                <p className={style.branchDetailsValue}>{creationDate}</p>
              </div>
            </div>

            <div className={style.branchDetailsItem}>
              <img src={cityIcon} alt="icon" />
              <div className={style.branchDetailsText}>
                <a href={branchData?.googleMapsLink} className={style.link}>
                  Google Maps Link
                </a>
              </div>
            </div>

            <div className={style.branchDetailsButtons}>
              <Link key={branchId} to={`edit`}>
                <SectionButton
                  className={style.branchDetailsButton}
                  label="Update branch"
                  onClick={toggleModal}
                />
              </Link>
              <SectionButton
                className={style.branchDetailsButton}
                label="Delete branch"
                onClick={toggleDeleteModal}
              />
            </div>
          </div>
        </div>
        <div className={style.contact}>
          <div className={style.contactTop}>
            <div className={style.contactTitle}>
              <img src={contactIcon} alt="icon" />
              <p className={style.contactTitle}>Contacts</p>
            </div>
          </div>
          <Contacts branchId={id} />
          {isDeleteModalVisible && (
            <Modal
              className={styles.modal}
              open={isDeleteModalVisible}
              onClose={toggleDeleteModal}>
              <form onSubmit={handleSubmit(handleDeleteBranch)}>
                <div className={styles.modalDelete}>
                  <div className={styles.modalDeleteHeader}>
                    <p
                      className={styles.modalDeleteHeaderText}
                      id="confirmation-form-modal-title">
                      {`Delete ${branchData.name}`}
                    </p>
                  </div>
                  <div className={styles.modalDeleteMainSection}>
                    <p className={styles.modalDeleteMainText}>
                      Caution! All contacts and orders related to this branch
                      will be deleted
                    </p>
                    <p className={styles.modalDeleteConfirmText}>
                      Enter branch name to proceed
                    </p>
                    <TextField
                      {...register('branch', {
                        required: 'This field is required',
                        validate: (value) =>
                          value === branchData.name || 'Name does not match.',
                      })}
                      className={styles.modalDeleteInput}
                      placeholder="Enter branch"
                      error={!!errors.branch}
                      helperText={errors.branch?.message}
                      onChange={() => clearErrors('branch')}
                      type="text"
                    />
                  </div>
                  {errorBranch && (
                    <div className={'err-isPrimary'}>
                      Cannot delete primary <b>branch</b> ⚠️
                    </div>
                  )}
                  <div className={styles.modalDeleteButtons}>
                    <button
                      className={styles.modalDeleteButton}
                      type="submit"
                      disabled={isDeleteDisabled}>
                      Delete branch
                    </button>
                    <button
                      className={styles.modalDeleteButtonCancel}
                      onClick={toggleDeleteModal}>
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </Modal>
          )}
        </div>
      </div>
    </TemplatePage>
  );
};

export default ContactBranch;
