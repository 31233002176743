import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { userAuthSlice } from '../entities/Admin/admin.slice';
import { adminApi } from '../entities/Admin/api/adminApi';
import { branchApi } from '../entities/Branch/api/branchApi';
import { contactApi } from '../entities/Contact/api/contactApi';
import { organizationApi } from '../entities/Organization/api/organizationApi';
import { invoiceApi } from '../entities/transactEntities/Invoice/api/invoiceApi';
import { paymentApi } from '../entities/transactEntities/Payment/api/paymentApi';
import { orderApi } from '../entities/Order/api/orderApi';
import { skuRequestsApi } from '../entities/SKU/api/skuRequestsApi';
import { roleApi } from '../entities/Role/api/roleApi';
import { SKUApi } from '../entities/SKU/api/SKUApi';
import { rtkQueryErrorLogger } from './rtkMiddleware';
import { errorNotificationSlice } from '../entities/ErrorNotification/errorNotificationSlice';
import { cancelationRequestApi } from '../entities/CancelationRequest/CancelationRequestApi';
import { offersApi } from '../entities/Offers/api/offersApi';
import { statisticsApi } from '@/entities/Statistics/statisticsApi';
import { customerApi } from '../entities/Customer/api/customerApi';
import { configApi } from '@/entities/Config/configApi';

export const store = configureStore({
  reducer: {
    [adminApi.reducerPath]: adminApi.reducer,
    [branchApi.reducerPath]: branchApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [skuRequestsApi.reducerPath]: skuRequestsApi.reducer,
    [offersApi.reducerPath]: offersApi.reducer,
    [SKUApi.reducerPath]: SKUApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [cancelationRequestApi.reducerPath]: cancelationRequestApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [userAuthSlice.name]: userAuthSlice.reducer,
    [errorNotificationSlice.name]: errorNotificationSlice.reducer,
    [statisticsApi.reducerPath]: statisticsApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(adminApi.middleware)
      .concat(branchApi.middleware)
      .concat(contactApi.middleware)
      .concat(organizationApi.middleware)
      .concat(orderApi.middleware)
      .concat(invoiceApi.middleware)
      .concat(paymentApi.middleware)
      .concat(skuRequestsApi.middleware)
      .concat(SKUApi.middleware)
      .concat(roleApi.middleware)
      .concat(cancelationRequestApi.middleware)
      .concat(offersApi.middleware)
      .concat(statisticsApi.middleware)
      .concat(customerApi.middleware)
      .concat(configApi.middleware)
      .concat(rtkQueryErrorLogger),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
