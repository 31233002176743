import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../shared/api/api';
import { getToken } from '../../Admin/admin.models';
import { ISkuResponse } from '../SKUModel.models';
// import { ORDER_DIVIDER } from '../../../pages/CreateOrder/components/SelectProduct/components/ProductCard/ProductCard';

const SKUConfig = {
  ...baseQueryConfig,
  baseUrl: baseQueryConfig.baseUrl + '/skus',
  prepareHeaders: (headers: Headers) => {
    const session = getToken();
    headers.set('Authorization', `${session.token}`);
    return headers;
  },
};

export const SKUApi = createApi({
  reducerPath: 'SKUApi',
  tagTypes: ['SKU'],
  baseQuery: fetchBaseQuery(SKUConfig),
  endpoints: (builder) => ({
    getSku: builder.query<ISkuResponse[], { relations?: string[] }>({
      query: ({ relations = [] }) => {
        let url = '';
        if (relations.length > 0) {
          url = `?relations=${relations.join(',')}`;
        }
        return { url };
      },
      providesTags: ['SKU'],
      transformResponse(baseQueryReturnValue: any) {
        return baseQueryReturnValue;
      },
    }),
    updateSKU: builder.mutation<any, any>({
      query: (data) => ({
        url: `/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['SKU'],
    }),
    getSingleSku: builder.query<
      ISkuResponse,
      { id: number; languages?: number[]; tags?: number[] }
    >({
      transformResponse: (response: any) => {
        return response[0];
      },
      query: ({ id, languages, tags }) => {
        let relations = '';
        if (languages) {
          relations = languages.reduce(
            (acc, el) => acc + `&languages=${el}`,
            '',
          );
        }

        if (tags) {
          relations = tags.reduce((acc, el) => acc + `&tags=${el}`, '');
        }
        return {
          url: `/?skuId=${id}${relations}`,
        };
      },
    }),
  }),
});

export const { useGetSkuQuery, useUpdateSKUMutation, useGetSingleSkuQuery } =
  SKUApi;
