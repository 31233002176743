import React, { useMemo } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { useGetInvoicesRelationsQuery } from '../../../../entities/transactEntities/Invoice/api/invoiceApi';
import styles from './TaxInvoicesTab.module.scss';
import { IInvoice } from '../../../../entities/transactEntities/Invoice/invoice.models';
import orgIcon from '../../../../assets/icons/nav-icon-organisations.svg';
import orderIcon from '../../../../assets/icons/billing/orderId.svg';
import dateIcon from '../../../../assets/icons/billing/date.svg';
import relShipIcon from '../../../../assets/icons/billing/manager.svg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '@/shared/helpers/formatNumber';

type Props = {
  searchQuery: string;
};

export const TaxInvoicesTab: React.FC<Props> = ({ searchQuery }) => {
  const navigate = useNavigate();

  const { data: invoicesData } = useGetInvoicesRelationsQuery({
    relations: ['order.contact.branch.organization.relationshipManager'],
    status: ['PendingProcessing'],
    sort: 'id:desc',
  });

  const filteredInvoices = useMemo(() => {
    if (!invoicesData || !invoicesData.data) return [];
    return invoicesData.data.filter((invoice: IInvoice) => {
      const orgName = invoice?.order?.contact?.branch?.organization?.name;
      const orderId = invoice.order?.id?.toString() || '';
      return (
        orgName!.toLowerCase().includes(searchQuery.toLowerCase()) ||
        orderId.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [invoicesData, searchQuery]);

  const handleRowClick = (invoice: IInvoice) => {
    if (invoice.id) {
      navigate(`/invoice-form/${invoice.id}`);
    }
  };

  return (
    <TableBody>
      {filteredInvoices.map((invoice: IInvoice, index: number) => (
        <TableRow
          key={invoice.id}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}
          className={index % 2 === 0 ? styles.lightRow : styles.darkRow}
          onMouseEnter={(e) =>
            e.currentTarget.classList.add(styles.highlightedRow)
          }
          onMouseLeave={(e) =>
            e.currentTarget.classList.remove(styles.highlightedRow)
          }
          onClick={() => handleRowClick({ ...invoice })}>
          <TableCell>
            <div className={styles.cellIcon}>
              <img className={styles.billingIcon} src={orgIcon} alt="icon" />
              {invoice?.order?.contact?.branch?.organization?.name}
            </div>
          </TableCell>
          <TableCell>
            <div className={styles.cellIcon}>
              <img className={styles.billingIcon} src={orderIcon} alt="icon" />
              <div className={styles.cellIcon}>{invoice.order.id}</div>
            </div>
          </TableCell>
          <TableCell>
            <div className={styles.cellIcon}>
              <img className={styles.billingIcon} src={dateIcon} alt="icon" />
              <div className={styles.cellIcon}>
                {moment(invoice.createdAt).format('MMM DD, YYYY')}
              </div>
            </div>
          </TableCell>
          <TableCell>
            <div className={styles.cellIcon}>
              <img
                className={styles.billingIcon}
                src={relShipIcon}
                alt="icon"
              />
              <div className={styles.cellIcon}>
                {invoice.order.contact?.branch?.organization
                  ?.relationshipManager?.name || '-'}
              </div>
            </div>
          </TableCell>
          <TableCell>
            <div className={styles.blueCell}>
              SAR {formatNumber(invoice.amount)}
            </div>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
