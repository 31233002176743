import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '@/shared/api/api';
import { RootState } from '@/store/store';
import {
  StatisticsPositionsResponse,
  StatisticsRequest,
  StatisticsResponse,
} from './models';

export const statisticsApi = createApi({
  reducerPath: 'statisticsApi',
  baseQuery: fetchBaseQuery({
    ...baseQueryConfig,
    baseUrl: baseQueryConfig.baseUrl + '/statistics',
    prepareHeaders: async (headers: Headers, { getState }) => {
      // If we have a token set in state, let's assume that we should be passing it.
      const token = await (getState() as RootState).user.user?.getIdToken();
      token && headers.set('Authorization', token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getStatistics: builder.query<StatisticsResponse, StatisticsRequest>({
      query: (request) => ({
        url: `/`,
        params: request,
      }),
    }),
    getStatisticsPositions: builder.query<
      StatisticsPositionsResponse,
      {
        startDate: string;
        endDate: string;
        relationshipManagerId?: number | number[];
        organization?: string | string[];
      }
    >({
      query: ({ startDate, endDate, relationshipManagerId, organization }) => {
        const organizationQuery = Array.isArray(organization)
          ? organization
              .map((org) => `&contact.branch.organization.name=${org}`)
              .join('')
          : organization
            ? `&contact.branch.organization.name=${organization}`
            : '';

        const relationshipManagerIdQuery = Array.isArray(relationshipManagerId)
          ? relationshipManagerId
              .map(
                (manager) =>
                  `&contact.branch.organization.relationshipManager.id=${manager}`,
              )
              .join('')
          : relationshipManagerId
            ? `&contact.branch.organization.relationshipManager.id=${relationshipManagerId}`
            : '';

        return (
          `/positions?` +
          `${startDate ? 'startDate=' + startDate : ''}` +
          `${endDate ? '&endDate=' + endDate : ''}` +
          `${organizationQuery}` +
          `${relationshipManagerIdQuery}`
        );
      },
    }),
  }),
});

export const { useGetStatisticsQuery, useGetStatisticsPositionsQuery } =
  statisticsApi;
