import React, { useEffect, useMemo, useState } from 'react';
import './SelectProductCard.scss';
import * as yup from 'yup';
import check from '../../../assets/icons/orderPage/check.svg';
import clear from '../../../assets/icons/orderPage/clear.svg';
import star from '../../../assets/icons/orderPage/star.svg';
import OrderSubtitle from '../../../shared/components/Title/OrderSubtitle/OrderSubtitle';
import { IOrderCard } from '../order.models';
import BasicOrderButton from '../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import { Modal } from '@mui/material';
import OrderSelect from '../../../shared/components/Select/OrderSelect/OrderSelect';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useCancelPositionsMutation } from '../api/orderApi';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatNumber } from '@/shared/helpers/formatNumber';

const CancellationReason = {
  NOT_WHAT_IM_LOOKING_FOR: "Not what I'm looking for",
  SERVICE_QUALITY: 'Service Quality',
  VALUE_FOR_MONEY: 'Value for Money',
  OTHER: 'Other',
};

interface FormData {
  cancelationAmount: number;
  cancelationReason: string;
  cancelationNotes?: string;
}

const yupSchema = yup.object({
  cancelationAmount: yup.number().required().min(1).max(9),
  cancelationReason: yup.string().required(),
  cancelationNotes: yup.string(),
});

interface PropTypes {
  orderId?: number;
  icon?: string;
  position: string;
  grade?: number;
  price: number;
  priceSingle?: number;
  id?: number;
  femalesPerc?: number;
  localsPerc?: number;
  ordInv?: number;
  finalQuantity?: number;
  cardQuantity?: number;
  isSummary?: boolean;
  isSubmitted?: boolean;
  isAdded?: boolean;
  userNotes?: string;
  onSelectCard?: (cardsData: IOrderCard) => void;
  onDeleteCard?: (cardsData: IOrderCard) => void;
  onDeleteProductCard?: (id: number) => void;
}

const SelectProductCard = (props: PropTypes) => {
  const {
    orderId,
    id,
    icon,
    position,
    grade,
    price,
    priceSingle,
    finalQuantity,
    cardQuantity: quantity,
    isSummary,
    isSubmitted,
    isAdded = false,
    femalesPerc,
    localsPerc,
    ordInv,
    userNotes,
    onSelectCard,
    onDeleteCard,
    onDeleteProductCard,
  } = props;

  const [hovered, setHovered] = useState(false);
  const [added, setAdded] = useState(isAdded);
  const [isOpenManageModal, setIsOpenManageModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string>('');

  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema),
  });

  useEffect(() => {
    if (added === true && onSelectCard) {
      const selectedCardData: IOrderCard = {
        id: id!,
        product: position,
        grade,
        quantity: quantity!,
        price,
        femalesPerc,
        localsPerc,
        ordInv: ordInv!,
        userNotes: userNotes,
        tags: [],
        interviewShifts: [],
      };
      onSelectCard({
        ...selectedCardData,
        id: id || 0,
      });
    } else if (added === false && onDeleteCard) {
      const selectedCardData: IOrderCard = {
        id: id!,
        product: position,
        grade,
        quantity: quantity!,
        price,
        femalesPerc,
        localsPerc,
        ordInv: ordInv!,
        userNotes: userNotes,
        interviewShifts: [],
        tags: [],
      };
      onDeleteCard(selectedCardData);
    }
  }, [added, quantity]);

  const [cancelPositions] = useCancelPositionsMutation();

  const shouldShowDeleteButton =
    (added || isSummary) && !isSubmitted && onDeleteCard;
  const shouldShowAddToOrder = hovered && !added && !isSummary;
  const shouldShowCardQuantity =
    (hovered || added || isSummary) && !finalQuantity;

  const shouldShowManageButton = !!orderId;

  const toggleMouseOver = () => {
    if (!added) {
      setHovered(!hovered);
    }
  };

  const handleButtonClick = () => {
    if (hovered) {
      setAdded(true);
    }
  };

  const onCancel = () => {
    setAdded(false);
    setHovered(false);
    isSummary && onDeleteProductCard && onDeleteProductCard(id || 0);
  };

  const memoizedTotalPrice = useMemo(() => {
    if (isSummary && finalQuantity && priceSingle) {
      return priceSingle * finalQuantity;
    } else {
      return price;
    }
  }, [isSummary, finalQuantity, priceSingle, price]);

  const handleClickManage = () => setIsOpenManageModal((prev) => !prev);

  const reasonOptions = useMemo(() => {
    return Object.values(CancellationReason).map((reason) => ({
      label: reason,
      value: reason,
    }));
  }, []);

  const handleReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedReason = e.target.value;
    setSelectedReason(() => {
      setValue('cancelationReason', newSelectedReason);
      clearErrors('cancelationReason');
      return newSelectedReason;
    });
  };

  const handleConfirmCancellation: SubmitHandler<FormData> = async (
    formData,
  ) => {
    if (id) {
      const cancelPositionDTO = {
        reason: selectedReason,
        notes: formData.cancelationNotes || '',
        cancelPositionsItems: [
          {
            SKU: id,
            positionsAmount: formData.cancelationAmount,
          },
        ],
      };

      cancelPositions({ id: orderId!, cancelPositionDTO });
      // eslint-disable-next-line no-console
      console.log('Cancellation successful:', cancelPositionDTO);
    }
  };

  const onSubmitManage = async (formData: FormData) => {
    await handleConfirmCancellation(formData);
    handleClickManage();

    setValue('cancelationAmount', 0);
    setValue('cancelationNotes', '');
  };

  return (
    <>
      <div
        className="select-product-card"
        onMouseEnter={toggleMouseOver}
        onMouseLeave={toggleMouseOver}>
        <div className="card-left">
          {icon && <img className="card-icon" src={icon} alt="icon" />}
          <div className="card-info">
            <div className="card-info-person">
              <OrderSubtitle title={position} />
            </div>
            <div className="card-info-details">
              <div className="card-rating">
                <img src={star} alt="*" />
                <div className="card-grade">
                  {grade == 0 || isNaN(Number(grade)) ? 'None' : grade}{' '}
                  {grade == 0 || isNaN(Number(grade))
                    ? ''
                    : grade === 1
                      ? 'Star'
                      : 'Stars'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-right">
          {!isSummary && <div className="card-price">{price} SAR</div>}
          {shouldShowCardQuantity && (
            <div className="card-quantity">{quantity}</div>
          )}
          {finalQuantity && (
            <div className="card-final-quantity">
              <div className="final-quantity-label">Qty</div>
              <div className="final-quantity-data">{finalQuantity}</div>
            </div>
          )}
          {isSummary && (
            <>
              <div className="card-prices">
                <div className="card-price">
                  SAR {formatNumber(memoizedTotalPrice)}
                </div>
                <div className="card-price-extra">
                  @ SAR {formatNumber(priceSingle ? priceSingle : 0)}
                </div>
              </div>
              {shouldShowManageButton && (
                <div className="card-manage">
                  <BasicOrderButton
                    label="Manage"
                    isRedLabel={true}
                    onClick={handleClickManage}
                  />
                </div>
              )}
            </>
          )}

          {shouldShowAddToOrder && (
            <button className="btn-add-to" onClick={handleButtonClick}>
              Add to Order
            </button>
          )}

          {added && (
            <button className="btn-added" onClick={onCancel}>
              Added
              <img src={check} alt="+" />
            </button>
          )}

          {shouldShowDeleteButton && (
            <button className="btn-delete" onClick={onCancel}>
              <img src={clear} alt="х" />
            </button>
          )}
        </div>
      </div>
      {isOpenManageModal && (
        <Modal
          className="modal-manage"
          onClose={handleClickManage}
          open={isOpenManageModal}>
          <div className="modal-manage__content">
            <div className="modal-manage__close" onClick={handleClickManage}>
              &#10005; {/* Close (X) symbol */}
            </div>
            <h3 className="modal-manage__title">Cancelation request</h3>
            <form
              className="modal-manage__form"
              onSubmit={handleSubmit(onSubmitManage)}>
              <div className="modal-manage__item">
                <input
                  className="modal-manage__amount--input"
                  type="number"
                  placeholder="Enter amount of positions to be canceled"
                  {...register('cancelationAmount')}
                />
                {errors.cancelationAmount && (
                  <span className="error-message">
                    {errors.cancelationAmount.type === 'required'
                      ? 'Amount is required'
                      : 'Amount must be greater than 0'}
                  </span>
                )}
              </div>
              <div className="modal-manage__item">
                <OrderSelect
                  options={reasonOptions}
                  placeholder="Select cancelation reason"
                  value={selectedReason}
                  handleChange={handleReasonChange}
                  {...register('cancelationReason')}
                />
                {errors.cancelationReason &&
                  errors.cancelationReason.type === 'required' && (
                    <span className="error-message">Amount is required</span>
                  )}
              </div>
              <div className="modal-manage__item">
                <textarea
                  className="modal-manage__notes--text"
                  placeholder="Enter cancelation notes"
                  {...register('cancelationNotes')}></textarea>
              </div>

              <BasicOrderButton type="submit" label="Confirm" />
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SelectProductCard;
