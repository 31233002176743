import { Modal, TextField } from '@mui/material';
import React from 'react';
import { useDeleteOrganizationMutation } from '@/entities/Organization/api/organizationApi';
import { IOrganization } from '@/entities/Organization/org.models';
import { useForm } from 'react-hook-form';
import styles from './DeleteOrganization.module.scss';
import { useNavigate } from 'react-router-dom';

export type Props = {
  toggleModal: () => void;
  orgData: IOrganization;
  isModalVisible: boolean;
};

export const DeleteOrganization = ({
  orgData,
  toggleModal,
  isModalVisible,
}: Props) => {
  const navigate = useNavigate();
  const [deleteOrganization] = useDeleteOrganizationMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      organization: '',
    },
  });

  const handleDeleteOrganization = async () => {
    await deleteOrganization(orgData.id.toString());
    toggleModal();
    navigate('/organizations');
  };

  return (
    <Modal className={styles.modal} open={isModalVisible} onClose={toggleModal}>
      <form onSubmit={handleSubmit(handleDeleteOrganization)}>
        <div className={styles.modalDelete}>
          <div className={styles.modalDeleteHeader}>
            <p
              className={styles.modalDeleteHeaderText}
              id="confirmation-form-modal-title">
              {`Delete ${orgData?.name}`}
            </p>
          </div>
          <div className={styles.modalDeleteMainSection}>
            <p className={styles.modalDeleteMainText}>
              Caution! All branches, contacts and orders related to this branch
              will be deleted
            </p>
            <p className={styles.modalDeleteConfirmText}>
              Enter organization name to proceed
            </p>
            <TextField
              {...register('organization', {
                required: 'This field is required',
                validate: (value) =>
                  value === orgData.name || 'Name does not match.',
              })}
              className={styles.modalDeleteInput}
              placeholder="Enter Organization"
              error={!!errors.organization?.message}
              helperText={errors.organization?.message}
              type="text"
            />
          </div>
          <div className={styles.modalDeleteButtons}>
            <button type="submit" className={styles.modalDeleteButton}>
              Delete organization
            </button>
            <button
              type="button"
              className={styles.modalDeleteButtonCancel}
              onClick={toggleModal}>
              Cancel
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
