import React from 'react';
import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './Autocomplete.module.scss';

export type Props = {
  onInput: (value: string) => void;
  onChange: (value: any | null) => void;
  name: string;
  options: Array<any>;
  placeholder: string;
  value: any;
  onBlur?: () => void;
  startAdornment?: React.ReactNode;
  error?: string;
  classMix?: React.CSSProperties;
};

export const Autocomplete = (props: Props) => {
  return (
    <MuiAutocomplete
      disablePortal
      options={props.options}
      isOptionEqualToValue={(option, value) => {
        return option?.label === value;
      }}
      onChange={(event, value) => props.onChange(value)}
      onInputChange={(event, value) => props.onInput(value)}
      value={typeof props.value === 'object' ? props.value?.label : props.value}
      popupIcon={<KeyboardArrowDownIcon />}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            sx: {
              borderRadius: '20px',
              '& .MuiInputBase-input::placeholder': {
                fontSize: '14px',
                color: '#000',
                opacity: 0.5,
              },
            },
            className: styles.autocompleteInput,
            startAdornment: (
              <div className={styles.startAdornment}>
                {props.startAdornment}
              </div>
            ),
          }}
          style={props.classMix}
        />
      )}
    />
  );
};
