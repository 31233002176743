import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';

export type Props = {
  control: Control<any>;
  label: string;
  name: string;
  onChange?: (date: any) => void;
};

export const DatePicker = ({ control, label, name, onChange }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <DateRangePicker
          format="MM/dd/yyyy"
          character=" – "
          size="lg"
          showOneCalendar
          placeholder={label}
          onChange={(date: any) => {
            field.onChange(date);
            if (onChange) {
              onChange(date);
            }
          }}
        />
      )}
    />
  );
};
