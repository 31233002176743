import React from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import dashboard from '@/assets/icons/dashboard.svg';
import styles from './Info.module.scss';
import { Link } from 'react-router-dom';

export const Info = () => {
  return (
    <div className={styles.base}>
      <div className={styles.title}>
        <img src={dashboard} alt="dashboard" />
        <span>Dashboard</span>
      </div>
      <Link to="/create-order">
        <Button className={styles.add} variant="outlined">
          New Order
          <Add />
        </Button>
      </Link>
    </div>
  );
};
