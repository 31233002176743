import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../../shared/api/api';
import { getToken } from '../../../Admin/admin.models';
import { IPayment } from '../payment.models';
import { buildUrlParams, createPaymentFormData } from '../helper';

const paymentConfig = {
  ...baseQueryConfig,
  baseUrl: baseQueryConfig.baseUrl + '/payments',
  prepareHeaders: (headers: Headers) => {
    const session = getToken();
    headers.set('Authorization', `${session.token}`);
    return headers;
  },
};

export const paymentApi = createApi({
  reducerPath: 'PaymentApi',
  tagTypes: ['Payment'],
  baseQuery: fetchBaseQuery(paymentConfig),
  endpoints: (builder) => ({
    getPayments: builder.query<
      any,
      { includeDetails?: boolean; organizationId?: string; take?: number }
    >({
      query: (params) => ({
        url: '',
        params: {
          take: params.take,
          'include-details': params.includeDetails,
          'organization.id': params.organizationId,
        },
      }),
      providesTags: ['Payment'],
    }),
    getOrdersRelations: builder.query<IPayment, { relations?: string[] }>({
      query: ({ relations = [] }) => {
        let url = '';
        if (relations.length > 0) {
          url += `?relations=${relations.join(',')}`;
        }
        return { url };
      },
    }),
    getPaymentRelations: builder.query<
      any,
      { relations?: string[]; status?: string[]; sort?: string }
    >({
      query: ({ relations = [], status, sort }) => {
        const urlParams = buildUrlParams(relations, status, sort);
        const url = `${urlParams}`;
        return { url };
      },
      providesTags: ['Payment'],
    }),
    getPaymentById: builder.query<
      IPayment,
      {
        id: string;
        relations?: string[];
      }
    >({
      query: ({ id, relations = [] }) => {
        const urlParams = buildUrlParams(relations);
        const url = `${id}${urlParams}`;
        return { url };
      },
      providesTags: ['Payment'],
    }),
    createPayment: builder.mutation<any, any>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Payment'],
    }),
    updatePayment: builder.mutation<
      void,
      { id: number; paymentMethod: string; actualDate: string; file: File }
    >({
      query: ({ id, paymentMethod, actualDate, file }) => {
        const formData = createPaymentFormData(paymentMethod, actualDate, file);
        return {
          url: `/${id}/process`,
          method: 'POST',
          body: formData,
          headers: {},
        };
      },
      invalidatesTags: ['Payment'],
    }),
    deletePayment: builder.mutation<any, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Payment'],
    }),
  }),
});

export const {
  useGetPaymentsQuery,
  useCreatePaymentMutation,
  useGetOrdersRelationsQuery,
  useGetPaymentRelationsQuery,
  useDeletePaymentMutation,
  useGetPaymentByIdQuery,
  useUpdatePaymentMutation,
} = paymentApi;
