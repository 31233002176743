import { useEditContactMutation } from '@/entities/Contact/api/contactApi';
import { IContact } from '@/entities/Contact/contact.models';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import 'yup-phone-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FieldValues,
  SubmitHandler,
  UseFormRegister,
  useForm,
} from 'react-hook-form';
import RegOrgSection from '@/features/RegOrgSection/RegOrgSection';
import SectionButton from '../../Button/SectionButton/SectionButton';
import { contactEditInputs } from '@/pages/Organizations/RegisterOrg/contactEditInputs';
import styles from '../../../../pages/Organizations/OrgEdit/EditForm/EditForm.module.scss';

const yupSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().nullable().email(),
  phone: yup.string().phone('SA').required(),
  isPrimary: yup.boolean().required(),
  id: yup.number().required(),
});

type YupSchemaType = yup.InferType<typeof yupSchema>;

export type Props = {
  data: IContact;
};

export const UpdateContactForm = ({ data }: Props) => {
  const [updateContact] = useEditContactMutation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<YupSchemaType>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      isPrimary: data.isPrimary,
      id: data.id,
    },
  });

  const onSubmitHandler: SubmitHandler<YupSchemaType> = useCallback(
    async (formData) => {
      updateContact({
        id: formData.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email || null,
        phone: formData.phone,
        isPrimary: formData.isPrimary,
      })
        .unwrap()
        .then(() => {
          navigate(-1);
        });
    },
    [getValues, data, navigate, updateContact],
  );

  return (
    <form
      className={styles.registerOrg}
      onSubmit={handleSubmit(onSubmitHandler)}>
      <div className={styles.centreForm}>
        <RegOrgSection
          title="Update Contact"
          inputEntries={contactEditInputs}
          register={register as unknown as UseFormRegister<FieldValues>}
          errors={errors}
          control={control}
          validate={() => {
            return trigger([
              'firstName',
              'lastName',
              'phone',
              'email',
              'isPrimary',
            ]);
          }}
        />
      </div>
      <div className={styles.registerOrgButton}>
        <SectionButton label="Save" type="submit" isFilled={true} />
      </div>
    </form>
  );
};
