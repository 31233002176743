import barista from '../../../../../../assets/icons/orderPage/barista.svg';
import chefAssist from '../../../../../../assets/icons/orderPage/chef-assist.svg';
import captainWaiter from '../../../../../../assets/icons/orderPage/waiter.svg';
import chef from '../../../../../../assets/icons/orderPage/chef.svg';
import receptionist from '../../../../../../assets/icons/orderPage/receptionist.svg';

export const mapProductNameToIcon: Record<string, string> = {
  Barista: barista,
  'Captain Waiter': captainWaiter,
  Chef: chef,
  Receptionist: receptionist,
  'Chef Assist': chefAssist,
};

export const defaultIcon = chefAssist;
export const ORDER_DIVIDER = 1;
