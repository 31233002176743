import React, { useContext, useEffect, useState } from 'react';
import './OrderProductCard.scss';
import SelectProductCard from '../SelectProductCard/SelectProductCard';
import ActionOrderButton from '../../../shared/components/Button/OrderButtons/ActionOrderButton/ActionOrderButton';
import money from '../../../assets/icons/orderPage/money-gray.svg';
import user from '../../../assets/icons/orderPage/user-gray.svg';
import ContractDetails from '../../../widgets/Order/ContractDetails/ContractDetails';
import ContractSummary from '../../../widgets/Order/ContractSummary/ContractSummary';

import {
  OrderContactLocationStateContext,
  OrderIdContext,
} from '../../../pages/CreateOrder/components/ContractSchedule/context';
import { useGetContactsByBranchIdQuery } from '../../Contact/api/contactApi';
import { TextField } from '@mui/material';
import { InterviewShiftForm } from '../../../pages/CreateOrder/components/ContractSchedule/components/ScheduleForm/InterviewShiftForm';
import { IOrderSchedule } from '../order.models';

interface PropTypes {
  id?: number;
  icon?: string;
  position: string;
  grade?: number;
  price: number;
  priceSingle?: number;
  quantity: number;
  deliveryDate?: number | null;
  interviewShift: IOrderSchedule;
  ordInv?: number;
  femalesPerc?: number;
  localsPerc?: number;
  salaryMinimum?: number;
  userNotes?: string;
  handleOpenContactModal?: () => void;
  handleQuantityChange?: (id: number, quantity: number) => void;
  handleDeleteProductCard?: (id: number) => void;
  handleNotesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OrderProductCard = (props: PropTypes) => {
  const {
    id,
    icon,
    position,
    grade,
    price,
    priceSingle,
    quantity,
    deliveryDate,
    interviewShift,
    ordInv,
    femalesPerc,
    localsPerc,
    salaryMinimum,
    userNotes,
    handleNotesChange,
  } = props;
  const { productCard, handleChangeCard } = useContext(OrderIdContext);
  const { contract } = productCard;
  const [contactData, setContactData] = useState<string | null>(null);
  const [isContractTabOpen, setContractTabOpen] = useState(false);
  const { branchId } = useContext(OrderContactLocationStateContext);

  const { data: contactsData } = useGetContactsByBranchIdQuery({
    take: 100,
    page: 1,
    id: branchId,
  });

  const handleChangeNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleNotesChange(event);
  };

  useEffect(() => {
    if (contactsData) {
      const contact = `${contactsData.data[0].firstName} ${contactsData.data[0].lastName}`;
      setContactData(contact);
    }
  }, [contactsData]);

  const handleContractData = (
    contractType: string,
    maxSalary: number,
    isHousing: boolean,
    femalesPercentage: number,
    localsPercentage: number,
  ) => {
    const newContractData = {
      contractType,
      maxSalary,
      isHousing,
      femalesPercentage,
      localsPercentage,
    };

    handleChangeCard({
      ...productCard,
      contract: newContractData,
      femalesPerc: femalesPercentage,
      localsPerc: localsPercentage,
    });
  };

  const toggleContractButtonClick = () => {
    setContractTabOpen(!isContractTabOpen);
  };

  const handleEditContract = () => {
    toggleContractButtonClick();
  };

  return (
    <div className="order-product-card">
      <div className="order-card-summary">
        <SelectProductCard
          id={id}
          icon={icon}
          position={position}
          grade={grade}
          price={price}
          priceSingle={priceSingle}
          cardQuantity={quantity}
          isSummary={true}
          ordInv={ordInv}
          femalesPerc={femalesPerc}
          localsPerc={localsPerc}
          userNotes={userNotes}
        />
      </div>
      <div className="hr"></div>
      <InterviewShiftForm
        crewQuantity={quantity}
        data={interviewShift}
        deliveryDate={deliveryDate}
      />
      <div className="hr"></div>
      <div className="order-card-actions">
        <div className="action-contract">
          {isContractTabOpen && (
            <ContractDetails
              handleCloseTab={toggleContractButtonClick}
              handleData={handleContractData}
              orderListContract={contract}
              femalesPerc={femalesPerc}
              localsPerc={localsPerc}
              ordInv={ordInv}
              prevContractData={contract}
              salaryMinimum={salaryMinimum}
            />
          )}

          {!isContractTabOpen &&
            contract?.contractType &&
            contract?.maxSalary && (
              <ContractSummary
                contractType={contract.contractType}
                maxSalary={contract.maxSalary}
                isHousing={contract.isHousing || false}
                femalesPercentage={femalesPerc}
                onEditClick={handleEditContract}
              />
            )}
          {!isContractTabOpen && !contract && (
            <div className="action">
              <div className="action-label">Setup the contract</div>
              <ActionOrderButton
                label="Contract"
                icon={money}
                handleOpenTab={toggleContractButtonClick}
              />
            </div>
          )}
        </div>

        <div className="hr"></div>

        <div className="order-card-notes">
          <TextField
            id="outlined-multiline-static"
            label="Notes"
            multiline
            value={userNotes}
            onChange={handleChangeNotes}
            variant="filled"
            style={{ width: '100%', color: '#2c2c3a', fontSize: '14px' }}
          />
        </div>

        <div className="action-add-contact action hide-contact">
          <div className="action-label">Contact</div>
          <div className="action-contact">
            {contactData}
            <img className="action-contact-user" src={user} alt="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderProductCard;
