import React from 'react';
import styles from './RegOrgSection.module.scss';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { InputTypes } from '@/pages/Organizations/RegisterOrg/regOrgInputs';
import { AdminAutocompleteControl } from './components/AdminAutocomplete/AdminAutocomplete';
import { Input } from './components/Input/Input';
import { InputPhone } from './components/InputPhone/InputPhone';
import { EnumsAutocomplete } from './components/EnumsAutocomplete/EnumsAutocomplete';
import { SearchAutocompleteOrg } from '@/pages/Contacts/SearchAutocompleteOrg/SearchAutocompleteOrg';

export interface Input {
  label: string;
  name: string;
  selectOptions?: Array<{ label: string; value: any }>;
  type?: InputTypes;
  icon?: string;
  maxLength?: number;
}

export interface Options {
  label: string;
  value: string;
}

interface PropTypes {
  title: string;
  inputEntries: Input[];
  register: UseFormRegister<any>;
  control: Control<any>;
  errors?: any;
  handleNext?: () => void;
  handlePrevious?: () => void;
  validate?: () => Promise<boolean>;
  confirmButtonTitle?: string;
  ButtonIcon?: any;
}

const RegOrgSection = (props: PropTypes) => {
  const {
    title,
    inputEntries,
    control,
    handleNext,
    validate,
    handlePrevious,
    confirmButtonTitle,
    ButtonIcon,
  } = props;

  return (
    <div className={styles.base}>
      <div className={styles.title}>{title}</div>
      <div className={styles.desc}>Fields below are mandatory</div>
      <div className={styles.fields}>
        {inputEntries.map((entry) => {
          return (
            <div key={entry.name}>
              {entry.type === InputTypes.phone && (
                <Controller
                  name={entry.name}
                  control={control}
                  render={({
                    field: { name, onBlur, onChange, value },
                    formState: { errors },
                  }) => (
                    <InputPhone
                      label={entry.label}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={errors[entry.name]?.message?.toString()}
                    />
                  )}
                />
              )}

              {entry.type === InputTypes.autocomplete && (
                <AdminAutocompleteControl
                  control={control}
                  name={entry.name}
                  placeholder={entry.label}
                />
              )}

              {entry.type === InputTypes.autocompleteFromEnum &&
                entry.selectOptions && (
                  <EnumsAutocomplete
                    control={control}
                    name={entry.name}
                    placeholder={entry.label}
                    selectOptions={entry.selectOptions}
                    icon={entry.icon}
                  />
                )}

              {entry.type === InputTypes.text && (
                <>
                  <Controller
                    control={control}
                    name={entry.name}
                    render={({
                      field: { value, name, onChange, onBlur },
                      formState: { errors },
                    }) => (
                      <Input
                        name={name}
                        label={entry.label}
                        onChange={onChange}
                        value={value}
                        icon={entry.icon}
                        onBlur={onBlur}
                        error={errors[entry.name]?.message?.toString()}
                        maxLength={entry.maxLength}
                      />
                    )}
                  />
                </>
              )}

              {entry.type === InputTypes.searchAutocomplete && (
                <SearchAutocompleteOrg
                  control={control}
                  name={entry.name}
                  placeholder={entry.label}
                />
              )}
            </div>
          );
        })}
      </div>

      {handleNext && (
        <>
          <div className={styles.hr} />
          <div className={styles.buttons}>
            <button
              className={styles.cancel}
              type="button"
              onClick={handlePrevious}>
              Back
            </button>
            <button
              className={styles.confirm}
              type="button"
              onClick={async () => {
                if (validate) {
                  validate().then((res) => {
                    if (res) handleNext?.();
                  });
                }
              }}>
              {confirmButtonTitle || 'Confirm'}
              {ButtonIcon && <ButtonIcon />}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default RegOrgSection;
