import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { logout } from '../services/logout';
import { AppDispatch } from './store';

interface ErrorPayload {
  statusCode: number;
  message: string | string[];
  error: string;
}

const isRejectedOrUnauthorized = (payload: ErrorPayload): boolean => {
  return [403, 401].includes(payload?.statusCode);
};

export const rtkQueryErrorLogger: Middleware =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (next) =>
  (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (
      isRejectedWithValue(action) &&
      isRejectedOrUnauthorized(action.payload.data)
    ) {
      // eslint-disable-next-line no-console
      console.error('A 403 error occured. Signing out...');
      dispatch(logout());
    }

    return next(action);
  };
