import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import styles from './OrgOrders.module.scss';
import {
  IOrderWithSkuRequestContactBranchOrganization,
  IOrdersWithPagination,
  OrderStatuses,
} from '@/entities/Order/order.models';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BasicSelect } from '@/shared/components/Select/BasicSelect/BasicSelect';
import ordersIcon from '@/assets/icons/order-blue.svg';
import { statusOption } from '@/pages/Contacts/ContactDetails/ContactTable/ContactTable';
import { statusDisplayName } from '@/pages/Dashboard/components/Stats/components/OrdersTable/OrdersTable';
import { formatNumber } from '@/shared/helpers/formatNumber';

type Props = {
  ordersData: IOrdersWithPagination;
  isSuccess: boolean;
};

export const OrgOrders = ({ ordersData, isSuccess }: Props) => {
  const navigate = useNavigate();
  const hasActiveOrders =
    isSuccess &&
    ordersData.data.some(
      (order: IOrderWithSkuRequestContactBranchOrganization) =>
        order.orderStatus === OrderStatuses.PENDING_CONFIRMATION ||
        order.orderStatus === OrderStatuses.PENDING_PAYMENT ||
        (order.orderStatus === OrderStatuses.PENDING_FULFILLMENT &&
          moment().diff(moment(order.createdAt), 'days') <= 45),
    );

  const activeOrders = isSuccess
    ? ordersData.data.filter(
        (order: IOrderWithSkuRequestContactBranchOrganization) =>
          order.orderStatus === OrderStatuses.PENDING_CONFIRMATION ||
          order.orderStatus === OrderStatuses.PENDING_PAYMENT ||
          (order.orderStatus === OrderStatuses.PENDING_FULFILLMENT &&
            moment().diff(moment(order.createdAt), 'days') <= 45),
      )
    : [];

  const { control, watch } = useForm();

  const selectedStatus = watch('status');

  const filteredOrders =
    activeOrders &&
    (selectedStatus
      ? activeOrders.filter(
          (order: IOrderWithSkuRequestContactBranchOrganization) =>
            order.orderStatus === selectedStatus,
        )
      : activeOrders);

  return (
    <div className={styles.orders}>
      <div className={styles.ordersTop}>
        <div className={styles.ordersTitle}>
          <img src={ordersIcon} alt="icon" />
          <p className={styles.ordersTitle}>Orders</p>
        </div>
        <div className={styles.filter}>
          <BasicSelect
            control={control}
            name="status"
            selectOptions={statusOption}
            label="All statuses"
          />
        </div>
      </div>
      <TableContainer className={styles.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Requested</TableCell>
              <TableCell>Order #</TableCell>
              <TableCell>RM</TableCell>
              <TableCell>Positions</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hasActiveOrders
              ? filteredOrders.map(
                  (
                    order: IOrderWithSkuRequestContactBranchOrganization,
                    index: number,
                  ) => (
                    <TableRow
                      key={order.id}
                      onClick={() => navigate(`/view-order/${order.id}`)}
                      className={`${styles.row} ${
                        index % 2 === 0 ? styles.colorRow : styles.whiteRow
                      }`}>
                      <TableCell
                        sx={{
                          fontSize: '14px',
                          color: '#141A7F',
                        }}>
                        {moment(order.createdAt).format('DD MMM')}
                      </TableCell>
                      <TableCell>{order.id}</TableCell>
                      <TableCell
                        sx={{
                          fontSize: '11px',
                          color: '#797B8C',
                        }}>
                        {
                          order.contact?.branch?.organization
                            ?.relationshipManager.name
                        }
                      </TableCell>
                      <TableCell>
                        {order.skuRequests?.reduce(
                          (acc: number, item) => acc + item.crewsNumber,
                          0,
                        )}
                      </TableCell>
                      <TableCell>SAR {formatNumber(order.total)}</TableCell>

                      <TableCell>
                        {
                          statusDisplayName[
                            order.orderStatus as keyof typeof statusDisplayName
                          ]
                        }
                      </TableCell>
                    </TableRow>
                  ),
                )
              : !hasActiveOrders && (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      sx={{
                        borderBottom: 'none',
                      }}>
                      <div className={styles.noDataContainer}>
                        <p className={styles.noDataMainText}>
                          There&apos;s no active orders
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>
      {hasActiveOrders && (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>Total</TableCell>
                <TableCell
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#141A7F',
                  }}>
                  {activeOrders.reduce((acc: number, order) => {
                    const totalCrewsNumber = order.skuRequests?.reduce(
                      (skuAcc: number, skuRequest) => {
                        return skuAcc + skuRequest.crewsNumber;
                      },
                      0,
                    );
                    return acc + (totalCrewsNumber || 0);
                  }, 0)}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#141A7F',
                  }}>
                  SAR{' '}
                  {formatNumber(
                    activeOrders.reduce(
                      (acc: number, order) => acc + order.total,
                      0,
                    ),
                  )}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
