import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../../shared/api/api';
import { getToken } from '../../../Admin/admin.models';
import { buildUrlParams } from '../../Payment/helper';
import { IInvoice } from '../invoice.models';

const invoiceConfig = {
  ...baseQueryConfig,
  baseUrl: baseQueryConfig.baseUrl + '/invoices',
  prepareHeaders: (headers: Headers) => {
    const session = getToken();
    headers.set('Authorization', `${session.token}`);
    return headers;
  },
};

export const invoiceApi = createApi({
  reducerPath: 'InvoiceApi',
  tagTypes: ['Invoice'],
  baseQuery: fetchBaseQuery(invoiceConfig),
  endpoints: (builder) => ({
    getInvoices: builder.query<
      any,
      { includeDetails?: boolean; organizationId?: string }
    >({
      query: (params) => ({
        url: '',
        params: {
          'include-details': params.includeDetails,
          'organization-id': params.organizationId,
        },
      }),
      providesTags: ['Invoice'],
    }),

    getInvoicesRelations: builder.query<
      any,
      { relations?: string[]; status?: string[]; sort: string }
    >({
      query: ({ relations = [], status, sort }) => {
        const urlParams = buildUrlParams(relations, status, sort);
        const url = `${urlParams}`;
        return { url };
      },
    }),

    getInvoiceById: builder.query<
      IInvoice,
      {
        id: string;
        relations?: string[];
      }
    >({
      query: ({ id, relations = [] }) => {
        const urlParams = buildUrlParams(relations);
        const url = `${id}${urlParams}`;
        return { url };
      },
      providesTags: ['Invoice'],
    }),
    createInvoice: builder.mutation<any, any>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Invoice'],
    }),
    updateInvoice: builder.mutation<any, any>({
      query: (data) => ({
        url: `/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Invoice'],
    }),
    deleteInvoice: builder.mutation<any, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Invoice'],
    }),
    addReceiptInvoice: builder.mutation<void, { id: number; file: File }>({
      query: ({ id, file }) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `/${id}/upload-doc`,
          method: 'POST',
          body: formData,
          headers: {},
        };
      },
      invalidatesTags: ['Invoice'],
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoicesRelationsQuery,
  useCreateInvoiceMutation,
  useDeleteInvoiceMutation,
  useGetInvoiceByIdQuery,
  useUpdateInvoiceMutation,
  useAddReceiptInvoiceMutation,
} = invoiceApi;
