import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Modal,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import money from '../../../../assets/icons/money.svg';
import house from '../../../../assets/icons/orderPage/house.svg';

import styles from './ChooseOption.module.scss';
import { Candidate } from '../../../../entities/Offers/offer.models';
import { useSendOfferMutation } from '../../../../entities/Offers/api/offersApi';

type Props = {
  candidate: Candidate;
};

export const ChooseOption: React.FC<Props> = ({ candidate }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);

  const [sendOffer] = useSendOfferMutation();

  useEffect(() => {
    setValue('salary', '');
    setValue('housing', 'yes');
  }, [setValue]);

  const handleOfferClick = () => {
    setIsOfferModalOpen(true);
  };

  const handleCloseOfferModal = () => {
    setIsOfferModalOpen(false);
  };

  const onSubmit = async () => {
    handleCloseOfferModal();

    await sendOffer({
      candidateRefId: candidate.refId,
      positionRefId: candidate.positionRefId,
      clientResponse: 'clientOffered',
      salaryOffered: Number(getValues('salary')),
      isHousing: getValues('housing') == 'yes',
    });

    setValue('salary', '');
    setValue('housing', 'yes');
  };

  const handleSalaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('salary', event.target.value);
  };

  const handleHousingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('housing', event.target.value);
  };

  return (
    <>
      <button className={styles.button} onClick={handleOfferClick}>
        Offer
      </button>
      {isOfferModalOpen && (
        <Modal
          className={styles.modalOffer}
          open={isOfferModalOpen}
          onClose={handleCloseOfferModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={styles.modalOfferContent}>
              <Box className={styles.modalOfferHeader}>
                <h2>Offer</h2>
                <IconButton onClick={handleCloseOfferModal}>
                  <CloseIcon className={styles.closeIcon} />
                </IconButton>
              </Box>
              <Box className={styles.offerCandidate}>
                <AccountCircleIcon
                  fontSize="large"
                  className={styles.accountIcon}
                />
                <Typography className={styles.offerCandidateName}>
                  {candidate.name}
                </Typography>
              </Box>
              <FormControl error={!!errors.salary} fullWidth>
                <TextField
                  {...register('salary', { required: true })}
                  sx={{
                    marginTop: '20px',
                  }}
                  id="input-salary"
                  defaultValue={getValues('salary')}
                  label="Salary Maximum"
                  onChange={handleSalaryChange}
                  InputProps={{
                    className: styles.input,

                    endAdornment: (
                      <Box className={styles.salaryInputLabels}>
                        <Typography
                          className={styles.salaryInputLabelBottom}
                          variant="subtitle1">
                          SAR
                        </Typography>
                      </Box>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          className={styles.salaryInputIcon}
                          src={money}
                          alt="*"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText
                  sx={{
                    textAlign: 'right',
                  }}>
                  {errors.salary && 'Salary is required'}
                </FormHelperText>
              </FormControl>
              <Typography
                sx={{
                  fontSize: '11px',
                }}
                className={styles.modalText}>
                Crew will work to bring the best workers at the lowest cost
              </Typography>
              <RadioGroup
                {...register('housing')}
                className={styles.radioButtons}
                row
                defaultValue={getValues('housing')}
                onChange={handleHousingChange}>
                <Box className={styles.housing}>
                  <img className={styles.housingIcon} src={house} alt="*" />
                  <Typography className={styles.housingText}>
                    Housing
                  </Typography>
                </Box>
                <Box>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </Box>
              </RadioGroup>
              <div className={styles.buttons}>
                <Button
                  className={styles.cancel}
                  variant="outlined"
                  onClick={handleCloseOfferModal}>
                  Cancel
                </Button>
                <Button
                  className={styles.confirm}
                  variant="contained"
                  type="submit">
                  Confirm
                </Button>
              </div>
            </Box>
          </form>
        </Modal>
      )}
    </>
  );
};
