import RegOrgSection from '@/features/RegOrgSection/RegOrgSection';
import { createContactInputs } from '@/pages/Organizations/RegisterOrg/regOrgInputs';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import {
  FieldValues,
  SubmitHandler,
  UseFormRegister,
  useForm,
} from 'react-hook-form';
import * as yup from 'yup';
import styles from './CreateContactForm.module.scss';
import BasicOrderButton from '@/shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import { useCreateContactMutation } from '@/entities/Contact/api/contactApi';
import { useNavigate } from 'react-router-dom';

const yupSchema = yup.object({
  organization: yup
    .object({
      id: yup.number().required('Required field'),
      label: yup.string(),
    })
    .required('Required field'),
  branch: yup
    .object({
      id: yup.number().required('Required field'),
      label: yup.string(),
    })
    .required('Required field'),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email(),
  phone: yup.string().phone('SA').required(),
});

export type YupSchemaType = yup.InferType<typeof yupSchema>;

export const CreateContactForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema),
  });

  const [createContact] = useCreateContactMutation();

  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (formData) => {
    await createContact({
      organization: formData.organization.label,
      branch: formData.branch.id,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email || null,
      phone: formData.phone,
    })
      .unwrap()
      .then(() => {
        navigate('/contacts');
      });
  };

  return (
    <form className={styles.base} onSubmit={handleSubmit(onSubmitHandler)}>
      <RegOrgSection
        title="Add new contact"
        inputEntries={createContactInputs}
        register={register as unknown as UseFormRegister<FieldValues>}
        errors={errors}
        control={control}
      />
      <div className={styles.btnContainer}>
        <BasicOrderButton
          label="Cancel"
          onClick={() => navigate('/contacts')}
        />
        <BasicOrderButton label="Save" classMix={styles.btn} type="submit" />
      </div>
    </form>
  );
};
