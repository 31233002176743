import React from 'react';
import styles from './InterviewShiftForm.module.scss';
import { IOrderSchedule } from '../../../../../../entities/Order/order.models';

export type Props = {
  data: IOrderSchedule;
  crewQuantity: number;
  deliveryDate?: number | null;
};

export const InterviewShiftForm = ({
  // eslint-disable-next-line
  data,
  crewQuantity,
  deliveryDate,
}: Props) => {
  return (
    <div className={styles.base}>
      <div className={styles.schedule}>
        Estimated delivery: {deliveryDate} days after payment
      </div>
      <div className={styles.contentRight}>
        <div className={styles.qty}>
          <div className={styles.qtyLabel}>Qty</div>
          <div className={styles.qtyValue}>{crewQuantity}</div>
        </div>
      </div>
    </div>
  );
};
