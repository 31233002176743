import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type NotificationSliceState = {
  opened: boolean;
  message: string;
};

const initialState: NotificationSliceState = {
  opened: false,
  message: '',
};

export const errorNotificationSlice = createSlice({
  name: 'errorNotification',
  initialState,
  reducers: {
    toggleOpen: (
      state,
      action: PayloadAction<{ opened: boolean; message: string }>,
    ) => {
      state.opened = action.payload.opened;
      state.message = action.payload.message;
      return state;
    },
    toggleClose: (state) => {
      state = { ...initialState };
      return state;
    },
  },
});

export const { toggleClose, toggleOpen } = errorNotificationSlice.actions;

export default errorNotificationSlice.reducer;
