export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) return str;

  const words = str.split(' ');
  let truncatedString = '';
  let i = 0;

  while (truncatedString.length + words[i].length + 1 <= maxLength) {
    truncatedString += (i === 0 ? '' : ' ') + words[i];
    i++;
  }

  return truncatedString + '...';
};

export const getOrganizationName = <
  T extends {
    order?: { contact?: { branch?: { organization?: { name?: string } } } };
  },
>(
  obj: T,
): string => {
  return obj.order?.contact?.branch?.organization?.name || '';
};
