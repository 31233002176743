import React, { useCallback, useState } from 'react';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import './SKUCard.scss';
import { ISkuResponse } from '../SKUModel.models';
import { useUpdateSKUMutation } from '../api/SKUApi';
import { formatNumber } from '@/shared/helpers/formatNumber';

export type SkuCardProps = {
  sku: ISkuResponse;
};

const SKUCard = ({ sku }: SkuCardProps) => {
  const { id, name, pricePerUnit } = sku;

  const [isEditing, setIsEditing] = useState(false);
  const [newPrice, setNewPrice] = useState(pricePerUnit);

  const [editSKU] = useUpdateSKUMutation();

  const handleEditClick = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);
  const handleSaveClick = useCallback(async () => {
    editSKU({ id, price: newPrice }).then(() => {
      setIsEditing(false);
    });
  }, [editSKU, id, newPrice, setIsEditing]);
  return (
    <div>
      <div className="org-card">
        <div className="org-name">{name}</div>
        <div className="org-content"></div>
        <div className="sar-button-container">
          {isEditing ? (
            <input
              className={`input-style-change-price`}
              type="number"
              value={newPrice}
              onKeyPress={(e) => {
                const isDigit = /^\d$/;
                if (!isDigit.test(e.key)) {
                  e.preventDefault();
                }
              }}
              placeholder={'New price'}
              onChange={(e) => setNewPrice(e.target.valueAsNumber)}
            />
          ) : (
            <div>{formatNumber(pricePerUnit) + ' SAR'}</div>
          )}
          {isEditing ? (
            <SectionButton
              label="Save"
              isFilled={true}
              onClick={handleSaveClick}
            />
          ) : (
            <SectionButton
              label="Change price"
              isFilled={false}
              onClick={handleEditClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SKUCard;
