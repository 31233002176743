import React, { useEffect, useState } from 'react';
import './CreateOrderForm.scss';
import org from '../../assets/icons/orderPage/org.svg';
import user from '../../assets/icons/orderPage/user-gray.svg';
import { useGetOrganizationsQuery } from '../../entities/Organization/api/organizationApi';
import { IOrganization } from '../../entities/Organization/org.models';
import { IBranch } from '../../entities/Branch/branch.models';
import BasicOrderButton from '../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import NextOrderButton from '../../shared/components/Button/OrderButtons/NextOrderButton/NextOrderButton';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IContact } from '../../entities/Contact/contact.models';
import OrderRadioInput from '../../shared/components/Input/OrderRadioInput/OrderRadioInput';
import { Autocomplete } from '../../shared/components/Autocomplete/Autocomplete';
import { EnumsAutocomplete } from '@/features/RegOrgSection/components/EnumsAutocomplete/EnumsAutocomplete';

const yupSchema = yup.object({
  contact: yup.string().required(),
  branch: yup.string().required(),
  organization: yup.object().required(),
  freePosition: yup.boolean().default(false).required(),
});

type YupSchemaType = yup.InferType<typeof yupSchema>;

const CreateOrderForm = () => {
  const navigate = useNavigate();
  const [organizationInput, setOrganizationInput] = useState<string>('');
  const { handleSubmit, control, setValue, watch } = useForm<YupSchemaType>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      organization: '',
      contact: '',
      freePosition: false,
    },
  });
  const { data: orgDataRaw } = useGetOrganizationsQuery({
    relations: ['branches.contacts'],
    searchByName: organizationInput,
    take: 10,
    page: 1,
  });

  const [orgData, setOrgData] = useState<IOrganization[] | undefined>(
    undefined,
  );

  const [branchOptions, setBranchOptions] = useState<IBranch[]>([]);

  useEffect(() => {
    if (orgDataRaw) {
      setOrgData(orgDataRaw.data);
    }
  }, [orgDataRaw]);

  const orgOptions = orgData ? orgData.map((org: IOrganization) => org) : [];
  const [contactOptions, setContactOptions] = useState<IContact[]>([]);

  const [availablePositions, setAvailablePositions] = useState<number>(0);

  const handleOrgChange = (organization: IOrganization) => {
    if (organization) {
      setBranchOptions(organization.branches);
      const orgFreePositions = organization.freePositions;
      setAvailablePositions(orgFreePositions);
    } else {
      setBranchOptions([]);
      setContactOptions([]);
      setValue('contact', '');
      setValue('branch', '');
    }
  };

  const branch = watch('branch');

  useEffect(() => {
    if (branch) {
      const branchData = JSON.parse(branch) as IBranch;
      setContactOptions(branchData.contacts || []);
    }
  }, [branch]);

  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (data) => {
    try {
      const contactData = JSON.parse(data.contact) as IContact;
      const branchData = JSON.parse(data.branch) as IBranch;
      const organization = data.organization as IOrganization;
      navigate('/select-product', {
        state: {
          contactData: {
            selectedOrg: organization.name,
            selectedBranch: branchData.name,
            selectedContact: contactData.firstName + ' ' + contactData.lastName,
            contactId: contactData.id,
            orgId: organization.id,
            branchId: branchData.id,
            freePosition: data.freePosition,
            positionNumber: availablePositions,
            contact: contactData,
          },
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleCancel = () => {
    navigate('/orders');
  };

  return (
    <form
      className="create-order-form"
      onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="create-order-instr">
        Please select organization, branch and contact.
      </div>
      <div className="create-order-inputs">
        <Controller
          control={control}
          name="organization"
          render={({
            field: { value, onChange, name },
            fieldState: { error },
          }) => (
            <Autocomplete
              name={name}
              onInput={(val) => {
                setOrganizationInput(val);
              }}
              error={error?.message}
              startAdornment={<img src={org} alt="org" />}
              placeholder="Select organization"
              onChange={(val: IOrganization) => {
                handleOrgChange(val);
                onChange(val);
              }}
              value={value}
              options={orgOptions.map((org) => ({
                label: org.name,
                ...org,
              }))}
            />
          )}
        />
        <EnumsAutocomplete
          control={control}
          name="branch"
          placeholder="Select branch"
          selectOptions={branchOptions.map((branch) => ({
            label: branch.name,
            value: JSON.stringify(branch),
          }))}
          icon={user}
        />

        <EnumsAutocomplete
          control={control}
          name="contact"
          placeholder="Select contact"
          selectOptions={contactOptions.map((contact) => ({
            label: contact.firstName + ' ' + contact.lastName,
            value: JSON.stringify(contact),
          }))}
          icon={user}
        />

        {/* <Controller
          control={control}
          name="contact"
          render={({
            field: { value, onChange, name, onBlur },
            fieldState: { error },
          }) => (
            <Select
              error={error?.message}
              value={value}
              options={contactOptions.map((contact) => ({
                label: `${contact.firstName} ${contact.lastName}`,
                value: JSON.stringify(contact),
              }))}
              onBlur={onBlur}
              name={name}
              label="Select contact"
              icon={user}
              onChange={(val) => {
                onChange(val);
              }}
            />
          )}
        /> */}
      </div>
      <div className="hr"></div>
      {availablePositions === 0 && (
        <div className="free-positions">
          <p className="free-positions-text">
            You don&apos;t have free positions
          </p>
        </div>
      )}
      {availablePositions > 0 && (
        <div className="free-positions">
          <p className="free-positions-text">{`Free position: ${availablePositions}`}</p>
          <div className="free-positions-radio">
            <div className="free-positions-title">Use free positions?</div>

            <div className="radio-input">
              <Controller
                control={control}
                name="freePosition"
                render={({ field: { value, onChange } }) => (
                  <>
                    <OrderRadioInput
                      value="Yes"
                      name="freePosition"
                      handleCollectValue={() => {
                        onChange(true);
                      }}
                      isChecked={value === true || undefined}
                    />
                    <OrderRadioInput
                      value="No"
                      name="freePosition"
                      handleCollectValue={() => {
                        onChange(false);
                      }}
                      isChecked={value === false || undefined}
                    />
                  </>
                )}
              />
            </div>
          </div>
        </div>
      )}
      <div className="create-order-btns">
        <BasicOrderButton label="Cancel" onClick={handleCancel} type="button" />
        <NextOrderButton label="Next" type="submit" />
      </div>
    </form>
  );
};

export default CreateOrderForm;
