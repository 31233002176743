export type StatisticsResponse = {
  clients: number;
  hiringPositions: number;
  filledPositions: number;
};

export type StatisticsPositionsResponse = {
  positionsQuoted: number;
  positionsConfirmed: number;
  positionsFulfillment: number;
  totalValueQuoted: number;
  totalValueConfirmed: number;
  totalValueFulfillment: number;
};

export enum StatisticsTime {
  ALL_TIME = 'All time',
  THIS_WEEK = 'This Week',
  LAST_WEEK = 'Last Week',
  THIS_MONTH = 'This Month',
  LAST_MONTH = 'Last Month',
  LAST_14_DAYS = 'Last 14 Days',
  LAST_28_DAYS = 'Last 28 Days',
  LAST_90_DAYS = 'Last 90 Days',
}

export type StatisticsRequest = {
  time: StatisticsTime;
};
