import React from 'react';
import { IAdminFireBase } from '@/entities/Admin/admin.models';
import { useGetAdminByUuIdQuery } from '@/entities/Admin/api/adminApi';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';

export function withAdmin<T extends { admin: IAdminFireBase }>(
  WrappedComponent: React.ComponentType<T>,
): React.FC<Omit<T, 'admin'>> {
  const WithAdmin: React.FC<Omit<T, 'admin'>> = (props) => {
    const user = useSelector((state: RootState) => state.user.user);
    const { data, isSuccess } = useGetAdminByUuIdQuery(user!.uid);

    if (!isSuccess) {
      return null;
    }

    return isSuccess ? (
      <WrappedComponent {...(props as T)} admin={data} />
    ) : (
      <div></div>
    );
  };

  return WithAdmin;
}
