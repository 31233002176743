import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';

import Avatar from '../../../../shared/components/Avatar/Avatar';
import './UserAvatar.scss';

const UserAvatar = () => {
  return (
    <div className="user-avatar">
      <Link to="/settings">
        <IconButton>
          <Avatar />
        </IconButton>
      </Link>
    </div>
  );
};

export default UserAvatar;
