import React, { useState } from 'react';
import {
  Box,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@mui/material';
import TemplatePage from '../TemplatePage/TemplatePage';
import { PaymentsTab } from './components/PaymentsTab/PaymentsTab';
import OrgFilters from '../../features/Filters/OrgFilters/OrgFilters';
import { TaxInvoicesTab } from './components/TaxInvoicesTab/TaxInvoicesTab';
import { RefundTab } from './components/RefundTab/RefundTab';
import styles from './BillingContainer.module.scss';

export const BillingContainer = () => {
  const [activeTab, setActiveTab] = useState(() => {
    const storedValue = localStorage.getItem('activeTab');
    return storedValue !== null ? parseInt(storedValue) : 0;
  });
  const [searchQuery, setSearchQuery] = useState('');

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    localStorage.setItem('activeTab', newValue.toString());
  };

  const handleInputChange = (value: string): void => {
    setSearchQuery(value);
  };

  return (
    <div className={styles.orgContainer}>
      <TemplatePage title="Billing">
        <div className={styles.billingHeader}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Payments" />
            <Tab label="Tax Invoices" />
            <Tab label="Refund" />
          </Tabs>

          <OrgFilters
            placeholder="Search clients or order number"
            searchQuery={searchQuery}
            handleInputChange={handleInputChange}
          />
        </div>

        <Box
          p={3}
          sx={{
            marginInline: '20px',
            borderRadius: '15px',
            backgroundColor: '#fff',
          }}>
          <div className={styles.billingInvoices}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className={styles.tableTitle}>Client</div>
                    </TableCell>
                    <TableCell>
                      <div className={styles.tableTitle}>Order #</div>
                    </TableCell>
                    <TableCell>
                      <div className={styles.tableTitle}>Order date</div>
                    </TableCell>
                    <TableCell>
                      <div className={styles.tableTitle}>
                        Relationship Manager
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={styles.tableTitle}>Payment Amount</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {activeTab === 0 && <PaymentsTab searchQuery={searchQuery} />}
                {activeTab === 1 && (
                  <TaxInvoicesTab searchQuery={searchQuery} />
                )}
                {activeTab === 2 && <RefundTab searchQuery={searchQuery} />}
              </Table>
            </TableContainer>
          </div>
        </Box>
      </TemplatePage>
    </div>
  );
};
