import { Input } from '@/features/RegOrgSection/RegOrgSection';
import user from '@/assets/icons/user.svg';
import email from '@/assets/icons/email.svg';
import primary from '@/assets/icons/orderPage/contract.svg';

export enum InputTypes {
  phone = 'phone',
  autocomplete = 'autocomplete',
  text = 'text',
  select = 'select',
}

export const contactEditInputs: Input[] = [
  {
    label: 'First name',
    name: 'firstName',
    type: InputTypes.text,
    icon: user,
  },
  {
    label: 'Last name',
    name: 'lastName',
    type: InputTypes.text,
    icon: user,
  },
  {
    label: 'Phone',
    name: 'phone',
    type: InputTypes.phone,
  },
  {
    label: 'Email',
    name: 'email',
    type: InputTypes.text,
    icon: email,
  },
  {
    label: 'Is Primary',
    name: 'isPrimary',
    type: InputTypes.select,
    selectOptions: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    icon: primary,
  },
];
