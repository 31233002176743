import React, { useState } from 'react';
import styles from './PaymentForm.module.scss';
import BillingClientSummary from '../BillingClientSummary/BillingClientSummary';
import { useNavigate, useParams } from 'react-router-dom';
import TemplatePage from '../../../TemplatePage/TemplatePage';
import FormTitle from '../FormTitle/FormTitle';
import backIcon from '../../../../assets/icons/arrow-left-grey.svg';
import arrowDownIcon from '../../../../assets/icons/chevron-down.svg';
import receiptIcon from '../../../../assets/icons/billing/re.png';
import { PaymentMethod } from '../../../../entities/transactEntities/Payment/payment.models';
import BasicOrderButton from '../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  useGetPaymentByIdQuery,
  useUpdatePaymentMutation,
} from '../../../../entities/transactEntities/Payment/api/paymentApi';
import { CircularProgress, Snackbar } from '@mui/material';
import { getOrganizationName } from '../../helpers';
import { formatNumber } from '@/shared/helpers/formatNumber';

export const PaymentForm = () => {
  const { id } = useParams();
  if (!id) {
    return null;
  }
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const navigate = useNavigate();

  const {
    data: paymentData,
    isSuccess,
    isLoading,
  } = useGetPaymentByIdQuery({
    id: `${id}`,
    relations: ['order.contact.branch.organization.relationshipManager'],
  });

  const [updatePayment, { isLoading: updateLoading }] =
    useUpdatePaymentMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      actualDate: '',
      paymentMethod: '',
      file: null,
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
  };

  const handleSubmitUpdate: SubmitHandler<{
    actualDate: string;
    paymentMethod: string;
    file: File | null;
  }> = async (formData) => {
    const formattedDate = new Date(formData.actualDate).toISOString();
    updatePayment({
      id: +id,
      paymentMethod: formData.paymentMethod,
      actualDate: formattedDate,
      file: selectedFile!,
    })
      .then(() => {
        // navigate('/billing');
        setIsShowSnackbar(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error while updating payment:', error);
      });
  };
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);
  return (
    <TemplatePage>
      <>
        <Snackbar
          open={isShowSnackbar}
          autoHideDuration={6000}
          onClose={() => setIsShowSnackbar(false)}
          message="Successfully uploaded receipt"
        />
        <div>
          {isLoading && (
            <div className={styles.loader}>
              <CircularProgress />
            </div>
          )}
          {isSuccess && paymentData.order.contact?.branch && (
            <>
              <FormTitle title="Payment form" icon={backIcon} />
              <div className={styles.paymentFormContent}>
                <BillingClientSummary
                  orgName={getOrganizationName(paymentData)}
                  orgType={paymentData.order.contact.branch.locationType}
                  orderId={paymentData.order.id}
                  orderDate={paymentData.order.createdAt!}
                  manager={
                    paymentData.order.contact?.branch?.organization
                      ?.relationshipManager.name || '-'
                  }
                  amount={paymentData.amount}
                />
                <div className={styles.paymentReceipt}>
                  <div className={styles.paymentTitleContainer}>
                    <img
                      className={styles.paymentIcon}
                      src={receiptIcon}
                      alt="icon"
                    />
                    <div className={styles.paymentTitle}>Upload receipt</div>
                  </div>

                  <div className={styles.paymentInputs}>
                    <div className={styles.paymentInput}>
                      <label
                        className={styles.paymentInputLabel}
                        htmlFor="receipt">
                        Receipt Number
                      </label>
                      <input
                        id="receipt"
                        className={styles.paymentInputReceipt}
                        type="text"
                        defaultValue={paymentData.id}
                        readOnly
                      />
                    </div>
                    <div className={styles.paymentInput}>
                      <label
                        className={styles.paymentInputLabel}
                        htmlFor="amount">
                        Amount Paid
                      </label>
                      <label
                        className={styles.paymentInputLabelSAR}
                        htmlFor="amount">
                        SAR
                      </label>
                      <input
                        className={styles.paymentInputAmount}
                        type="text"
                        defaultValue={formatNumber(paymentData.amount)}
                        readOnly
                      />
                    </div>

                    <div>
                      <input
                        className={styles.paymentInputDate}
                        type="date"
                        placeholder="Payment day"
                        {...register('actualDate', { required: true })}
                      />
                      <div className={styles.errorBlock}>
                        {errors.actualDate && (
                          <p className={styles.error}>
                            {errors.actualDate.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className={styles.paymentInput}>
                      <select
                        defaultValue=""
                        className={styles.paymentInputMethod}
                        {...register('paymentMethod', { required: true })}>
                        <option value="" disabled hidden>
                          Payment Method
                        </option>
                        {Object.values(PaymentMethod).map((method, index) => (
                          <option key={index} value={method}>
                            {method}
                          </option>
                        ))}
                      </select>
                      <img
                        className={styles.selectIcon}
                        src={arrowDownIcon}
                        alt="arrow"
                      />
                      <div className={styles.errorBlock}>
                        {errors.paymentMethod &&
                          errors.paymentMethod.type === 'required' && (
                            <p className={styles.error}>
                              This field is required
                            </p>
                          )}
                      </div>
                    </div>

                    <div className={styles.paymentAddReceipt}>
                      <div className={styles.paymentAddReceiptLabel}>
                        Upload receipt
                      </div>
                      <div>
                        <label
                          htmlFor="receiptInput"
                          className={styles.customFileUpload}>
                          <input
                            type="file"
                            id="receiptInput"
                            accept=".pdf"
                            required
                            className={styles.paymentAddReceiptButton}
                            onChange={handleFileChange}
                          />
                          Upload
                        </label>
                        <div className={styles.errorBlockReceipt}>
                          {selectedFile && (
                            <span className={styles.paymentAddReceiptFile}>
                              {selectedFile.name}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <hr className={styles.hr} />
                    <div className={styles.paymentButtonsWrap}>
                      <BasicOrderButton
                        label="Cancel"
                        onClick={() => navigate(-1)}
                      />
                      {updateLoading ? (
                        <CircularProgress />
                      ) : (
                        <BasicOrderButton
                          label="Save"
                          isPink={true}
                          type="submit"
                          onClick={handleSubmit(handleSubmitUpdate)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </TemplatePage>
  );
};
