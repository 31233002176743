import React, { PropsWithChildren, ReactElement } from 'react';

export type Props = PropsWithChildren<{ children: ReactElement[] }> & {
  onComplete: () => void;
  onCancel: () => void;
};

export const Steps = ({ children, onComplete, onCancel }: Props) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const length = children.length;

  const handleNext = () => {
    if (currentStep === length - 1) {
      return onComplete();
    }
    setCurrentStep(currentStep + 1);
  };
  const handlePrevious = () => {
    if (currentStep === 0) {
      return onCancel();
    }
    setCurrentStep(currentStep - 1);
  };
  const childWithProps = React.cloneElement(children[currentStep], {
    handleNext,
    handlePrevious,
  });

  return <div>{childWithProps}</div>;
};
