import React from 'react';
import contactsIcon from '@/assets/icons/contacts-pink.svg';
import styles from './Contacts.module.scss';
import { ContactsTable } from '@/pages/Contacts/ContactsTable/ContactsTable';

const Contacts = () => {
  return (
    <div className={styles.contactCards}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <img src={contactsIcon} alt="icon" />
          <div className={styles.headerTextContainer}>
            <span className={styles.headerTitle}>Contacts</span>
            <span className={styles.headerSecondaryText}>
              Manage easily your team
            </span>
          </div>
        </div>
      </div>
      <ContactsTable />
    </div>
  );
};

export default Contacts;
