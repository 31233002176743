import { IAdmin } from '@/entities/Admin/admin.models';
import { useGetAdminsQuery } from '@/entities/Admin/api/adminApi';
import { Autocomplete } from '@/shared/components/Autocomplete/Autocomplete';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import user from '@/assets/icons/user.svg';

export type Props = {
  control: Control<any>;
  name: string;
  placeholder?: string;
  classMix?: React.CSSProperties;
};

export const AdminAutocompleteControl = ({
  control,
  name,
  placeholder,
  classMix,
}: Props) => {
  const [relationshipManagerInput, setRelationshipManagerInput] =
    useState<string>('');

  const { data } = useGetAdminsQuery({
    searchByName: relationshipManagerInput,
    take: 10,
    page: 1,
  });

  const adminOptions: IAdmin[] =
    data?.data.map((adm) => ({ label: adm.name, ...adm })) || [];

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange, onBlur, name },
        formState: { errors },
      }) => (
        <Autocomplete
          error={errors[name]?.message?.toString()}
          name={name}
          onBlur={onBlur}
          onInput={(val) => {
            setRelationshipManagerInput(val);
          }}
          placeholder={placeholder ?? ''}
          onChange={(val: IAdmin | null) => {
            if (val) {
              onChange(val);
            } else {
              onChange(null);
            }
          }}
          value={value}
          options={adminOptions}
          startAdornment={<img src={user} width={24} height={24} />}
          classMix={classMix}
        />
      )}
    />
  );
};
