import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useCallback } from 'react';
import axios from 'axios';
import { baseQueryConfig } from '../../shared/api/api';

const TOPIC = 'general';

export const useSendRegisterToken = () => {
  const userToken = useSelector((state: RootState) => state.user.user);

  const sendRegisterToken = useCallback(
    async (registrationToken: string) => {
      if (userToken && registrationToken) {
        const token = await userToken.getIdToken();
        axios.post(
          baseQueryConfig.baseUrl + '/fb-messaging/subscribe',
          {
            registrationToken,
            topic: TOPIC,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        );
      }
    },
    [userToken],
  );

  return { sendRegisterToken };
};
