import React, { useEffect, useMemo } from 'react';
import styles from './Stats.module.scss';
import { StatisticsTime } from '@/entities/Statistics/models';
import { Select } from './components/Select/Select';
import classNames from 'classnames';
import { useGetStatisticsPositionsQuery } from '@/entities/Statistics/statisticsApi';
import { Skeleton } from '@mui/material';
import { AdminAutocompleteControl } from '@/features/RegOrgSection/components/AdminAutocomplete/AdminAutocomplete';
import { useForm } from 'react-hook-form';
import { IAdminFireBase } from '@/entities/Admin/admin.models';

type Props = {
  time: StatisticsTime;
  setTime: React.Dispatch<React.SetStateAction<StatisticsTime>>;
  startDate: string;
  endDate: string;
  relationshipManager: IAdminFireBase | null;
  setRelationshipManager: React.Dispatch<
    React.SetStateAction<IAdminFireBase | null>
  >;
};

export const Stats = ({
  time,
  setTime,
  startDate,
  endDate,
  relationshipManager,
  setRelationshipManager,
}: Props) => {
  const { control, watch } = useForm({
    defaultValues: {
      relationshipManager: relationshipManager
        ? {
            ...relationshipManager,
            id: relationshipManager.id,
            label: relationshipManager.name,
          }
        : null,
    },
  });

  const selectedRM = watch('relationshipManager');

  useEffect(() => {
    setRelationshipManager(selectedRM ? selectedRM : null);
  }, [selectedRM]);

  const { data, isSuccess, isLoading } = useGetStatisticsPositionsQuery({
    startDate: startDate,
    endDate: endDate,
    relationshipManagerId:
      selectedRM && selectedRM.id !== null ? selectedRM.id : undefined,
  });

  const paidPercent = useMemo(() => {
    return data
      ? Math.round((data.positionsFulfillment * 100) / data.positionsQuoted) ||
          0
      : 0;
  }, [data]);

  const confirmedPercent = useMemo(() => {
    return data
      ? Math.round((data.positionsConfirmed * 100) / data.positionsQuoted) || 0
      : 0;
  }, [data]);

  return (
    <div className={styles.base}>
      <div className={styles.stat}>
        <span className={styles.label}>Quoted</span>
        <div className={styles.value}>
          {isLoading && <Skeleton variant="rectangular" />}
          {isSuccess && data.positionsQuoted}
          <span className={styles.persent}>100%</span>
        </div>
      </div>
      <div className={styles.stat}>
        <span className={styles.label}>Confirmed</span>
        <div className={styles.value}>
          {isLoading && <Skeleton variant="rectangular" />}
          {isSuccess && data.positionsConfirmed}
          <span className={styles.persent}>{`${confirmedPercent}%`}</span>
        </div>
      </div>
      <div className={classNames(styles.stat, styles.stat__last)}>
        <span className={styles.label}>Paid</span>
        <div className={styles.value}>
          {isSuccess && data.positionsFulfillment}
          {isLoading && <Skeleton variant="rectangular" />}
          <span className={styles.persent}>{`${paidPercent}%`}</span>
        </div>
      </div>
      <div className={styles.filters}>
        <div className={styles.select}>
          <Select
            options={Object.values(StatisticsTime).map((el) => ({
              label: el,
              value: el,
            }))}
            value={time}
            onChange={(time) => setTime(time)}
          />
        </div>
        <div className={styles.input}>
          <AdminAutocompleteControl
            control={control}
            name="relationshipManager"
            placeholder="All"
            classMix={{
              width: '250px',
            }}
          />
        </div>
      </div>
    </div>
  );
};
