import React from 'react';
import {
  IOrder,
  IOrderWithSKUResponse,
} from '../../../../entities/Order/order.models';
import SummaryOrderCard from '../../../../entities/Order/SummaryOrderCard/SummaryOrderCard';
import styles from './SummaryOrderList.module.scss';
import { DEFAULT_DELIVERY_DATE } from '../../../../pages/CreateOrder/components/SummaryOrder/deliveryDate.const';

interface PropTypes {
  currOrderSku?: IOrder;
  isSubmitted?: boolean;
  skuRequests: IOrderWithSKUResponse[];
  orderStatus?: string;
  handleDeleteProductCard?: (id: number) => void;
  orderData: any;
}

const SummaryOrderCards = (props: PropTypes) => {
  const {
    skuRequests,
    isSubmitted,
    orderStatus,
    handleDeleteProductCard,
    orderData,
  } = props;

  return (
    <div className={styles.summaryOrderList}>
      {skuRequests?.map((skuReq) => {
        const currentSku = skuReq.sku;
        const qualityGrade = parseInt(currentSku?.qualityName, 10);

        return (
          <SummaryOrderCard
            orderData={orderData}
            id={skuReq.id || 0}
            key={skuReq.id}
            position={currentSku?.productName}
            grade={qualityGrade}
            price={currentSku?.pricePerUnit * skuReq?.crewsNumber}
            deliveryDate={
              currentSku.deliveryDate
                ? currentSku.deliveryDate
                : DEFAULT_DELIVERY_DATE
            }
            priceSingle={currentSku?.pricePerUnit}
            finalQuantity={skuReq.crewsNumber}
            contract={skuReq.contract}
            femalesPerc={skuReq.femalesPercentage}
            interviewShifts={skuReq.interviewShifts}
            contact={skuReq.contact}
            userNotes={skuReq.userNotes}
            isSubmitted={isSubmitted}
            tags={skuReq?.tags}
            orderStatus={orderStatus}
            handleDeleteProductCard={handleDeleteProductCard}
          />
        );
      })}
    </div>
  );
};

export default SummaryOrderCards;
