import React from 'react';
import './NavFooter.scss';
import NavLink from '../../../../shared/components/Link/NavLink/NavLink';
import Logout from '../../../../assets/icons/logout.svg';
import { useSignOut } from '../../../../services/customHooks';

const FooterNavigation = () => {
  const signOut = useSignOut();

  return (
    <div className="nav-footer">
      <NavLink onClick={signOut} icon={Logout} label="Log out" path="/" />
    </div>
  );
};

export default FooterNavigation;
