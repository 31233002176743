import React, { useCallback, useState } from 'react';
import {
  useCreateContactMutation,
  useDeleteContactMutation,
  useGetContactsByBranchIdQuery,
} from '../../../../../entities/Contact/api/contactApi';
import { AddContactForm, YupSchemaType } from './AddContactForm/AddContactForm';
import ContactCard from '../../../ContactCard/ContactCard';
import { Pagination } from '@mui/material';
import style from '../../ContactBranch.module.scss';
import SectionButton from '@/shared/components/Button/SectionButton/SectionButton';

export type ContactsProps = {
  branchId: number;
};

const TAKE_CONTACTS = 5;

export const Contacts = ({ branchId }: ContactsProps) => {
  const [page, setPage] = useState(1);
  const [isModalVisible, setModalVisible] = useState(false);
  const {
    data: contacts,
    refetch,
    isLoading,
    isSuccess,
  } = useGetContactsByBranchIdQuery({
    id: branchId,
    take: TAKE_CONTACTS,
    page,
  });

  const [deleteContactById] = useDeleteContactMutation();
  const [createContact] = useCreateContactMutation();

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const deleteContact = useCallback(
    (id: number) => async () => {
      await deleteContactById(id);
      refetch();
    },
    [deleteContactById],
  );
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) =>
    setPage(page);
  const handleAddContact = useCallback(
    async (data: YupSchemaType) => {
      try {
        await createContact({
          ...data,
          email: data.email || null,
          branch: branchId,
        });
        return true;
      } catch (err) {
        return false;
      }
    },
    [createContact],
  );

  return (
    <>
      {isLoading && <div>Loading...</div>}
      {isSuccess && (
        <>
          <div className={style.contactItems}>
            <div className={style.contactItem}>
              {contacts.data.map((contact) => (
                <ContactCard
                  key={contact.id}
                  contact={contact}
                  deleteContact={deleteContact(contact.id)}
                />
              ))}
            </div>
            <SectionButton
              className={style.contactBtn}
              label="Add contact"
              onClick={toggleModal}
            />
          </div>

          <Pagination
            page={page}
            onChange={handlePageChange}
            count={contacts.meta.pageCount}
            hidden={contacts.meta.pageCount <= 1}
          />
        </>
      )}

      {isModalVisible && (
        <AddContactForm onClose={toggleModal} onSubmit={handleAddContact} />
      )}
    </>
  );
};
