import { contactApi } from '@/entities/Contact/api/contactApi';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import emptyFolder from '@/assets/images/empty-folder.png';
import React, { useEffect, useState } from 'react';
import styles from './ContactsTable.module.scss';
import { useNavigate } from 'react-router-dom';
import avatarIcon from '@/assets/images/empty-avatar.png';
import userIcon from '@/assets/icons/user.svg';
import phoneIcon from '@/assets/icons/phone.svg';
import orgIcon from '@/assets/icons/org-grey.svg';
import branchIcon from '@/assets/icons/branch.svg';
import {
  ContactsFilter,
  FiltersFormData,
  initialSearchQuery,
} from '../ContactsFilter/ContactsFilter';
import { IContact } from '@/entities/Contact/contact.models';
import { Pagination } from '@/shared/components/Pagination/Pagination';
import { getEmployeesAmountText } from '@/shared/helpers/helperOrgSize';

export const ContactsTable = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState<FiltersFormData>(initialSearchQuery);
  const navigate = useNavigate();

  const [trigger, result] = contactApi.endpoints.getContacts.useLazyQuery();

  const { data: contactsData, isLoading, isSuccess } = result;

  useEffect(() => {
    trigger({
      relations: [
        'branch.organization',
        'branch.organization.relationshipManager',
      ],
      take: rowsPerPage,
      page,
      firstName: filters.contact,
      relationshipManager: filters.relationshipManager,
      organizationSize: filters.orgSize,
    });
  }, [page, rowsPerPage, filters, trigger]);

  const handleInputChange = (value: string): void => {
    setFilters((prevFilters) => ({ ...prevFilters, contact: value }));
  };

  const handleRelationshipManagerChange = (values: number[]): void => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      relationshipManager: values,
    }));
  };

  const handleOrgSizeChange = (values: string[]): void => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      orgSize: values,
    }));
  };

  return (
    <>
      <ContactsFilter
        handleInputChange={handleInputChange}
        handleRelationshipManagerChange={handleRelationshipManagerChange}
        handleOrgSizeChange={handleOrgSizeChange}
      />
      <div className={styles.Contacts}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Contact name</TableCell>
                <TableCell>Organisation</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Branch</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>RM</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading &&
                Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" />
                      </TableCell>
                    </TableRow>
                  ))}

              {isSuccess && contactsData.data.length > 0
                ? contactsData.data.map((contact: IContact) => (
                    <TableRow
                      key={contact.id}
                      onClick={() => navigate(`/contact-details/${contact.id}`)}
                      className={styles.ContactsRow}>
                      <TableCell>
                        <div className={styles.ContactsCell}>
                          <img src={avatarIcon} alt="photo" />
                          <span
                            className={
                              styles.ContactsText
                            }>{`${contact.firstName} ${contact.lastName}`}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={styles.ContactsTextPrimary}>
                          <img src={orgIcon} alt="icon" />
                          <span>{contact.branch?.organization?.name}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className={styles.ContactsTextPrimary}>
                          {contact.branch?.organization?.employeesAmount !==
                            undefined &&
                            getEmployeesAmountText(
                              contact.branch.organization.employeesAmount,
                            )}
                        </span>
                      </TableCell>
                      <TableCell>
                        <div className={styles.ContactsTextPrimary}>
                          <img src={branchIcon} alt="icon" />
                          <span>{contact.branch?.name}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={styles.ContactsTextPrimary}>
                          <img src={phoneIcon} alt="icon" />
                          <span>{contact.phone}</span>
                        </div>
                      </TableCell>

                      <TableCell>
                        <div className={styles.ContactsTextPrimary}>
                          <img src={userIcon} alt="icon" />

                          <span>
                            {
                              contact.branch?.organization?.relationshipManager
                                .name
                            }
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : !isLoading && (
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        sx={{
                          borderBottom: 'none',
                        }}>
                        <div className={styles.noDataContainer}>
                          <img
                            src={emptyFolder}
                            alt="image"
                            className={styles.noDataImg}
                          />
                          <p className={styles.noDataMainText}>
                            There&apos;s no user
                          </p>
                          <p className={styles.noDataSecondaryText}>
                            No user at this moment
                          </p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>
        {isSuccess && (
          <Pagination
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalItems={contactsData.meta.itemCount}
          />
        )}
      </div>
    </>
  );
};
