import {
  MessagePayload,
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import { useCallback } from 'react';
import app from '../../services/firebase';
import { useSendRegisterToken } from './useSendRegisterToken';
import { addNotification } from '@/services/IndexedDb/notificationDb';

export const useFirebaseNotificationProvider = () => {
  const { sendRegisterToken } = useSendRegisterToken();

  const initFCM = useCallback(async () => {
    const messaging = getMessaging(app);
    const onMessageHandler = (payload: MessagePayload) => {
      if (payload.notification) {
        try {
          // JSON.parse(payload.notification.body!);
          addNotification({
            body: payload.notification.body!,
            title: payload.notification.title!,
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error parsing notification body', err);
        }
      }
    };
    Notification.requestPermission().then(async (permission) => {
      if (permission === 'granted') {
        getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        }).then(async (token) => {
          onMessage(messaging, onMessageHandler);
          sendRegisterToken(token);
        });
      }
    });
  }, []);
  return { initFCM };
};
