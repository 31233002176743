import React, { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useCancelOrderWithRefundPolicyMutation,
  useConfirmOrderByAdminMutation,
  useDeleteOrderMutation,
  useGetOrderByIdWithRelationsQuery,
} from '../../../../entities/Order/api/orderApi';
import { useGetSkuQuery } from '../../../../entities/SKU/api/SKUApi';

import TemplatePage from '../../../TemplatePage/TemplatePage';
import OrderTitle from '../../../../shared/components/Title/OrderTitle/OrderTitle';
import BasicOrderButton from '../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import ClientSummary from '../../../../widgets/Order/ClientSummary/ClientSummary';
import TotalQuantity from '../../../../widgets/Order/TotalQuantity/TotalQuantity';
import SummaryTotal from '../../../../widgets/Order/SummaryTotal/SummaryTotal';
import SummaryOrderCard from '../../../../entities/Order/SummaryOrderCard/SummaryOrderCard';

import money from '../../../../assets/icons/orderPage/money.svg';
import {
  defaultIcon,
  mapProductNameToIcon,
} from '../SelectProduct/components/ProductCard/helper';

import { CancelModal } from '../CancelModal/CancelModal';
import { RepeatOrderButton } from '../../../../shared/components/RepeatOrderButton/RepeatOrderButton';

import './ViewOrder.scss';
import { IOrderCard, OrderStatuses } from '@/entities/Order/order.models';
import { DEFAULT_DELIVERY_DATE } from '../SummaryOrder/deliveryDate.const';

const ViewOrder = () => {
  const { id } = useParams();

  const orderId = parseInt(id!, 10);

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const [confirmOrderByAdmin] = useConfirmOrderByAdminMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const [cancelOrder] = useCancelOrderWithRefundPolicyMutation();

  const { isLoading: isSkuLoading } = useGetSkuQuery({});

  const navigate = useNavigate();
  const { data: orderData, isSuccess } = useGetOrderByIdWithRelationsQuery({
    id: id!,
  });

  const orderContact = orderData?.contact;

  const listContactName = `${orderContact?.firstName} ${orderContact?.lastName}`;
  const listBranchName = orderContact?.branch?.name;
  const listOrgName = orderContact?.branch?.organization?.name;
  const listTaxReg = orderContact?.branch?.organization?.taxReg;
  const currentFulfillRate = orderData?.fulfillmentRatePercent;
  const orderStatus = orderData?.orderStatus;

  const currentTaxReg = orderData?.contact?.branch?.organization?.taxReg;

  const orderProductCards =
    orderData?.skuRequests.map<IOrderCard>((skuReq) => ({
      id: skuReq.sku.id,
      price: skuReq.sku.pricePerUnit,
      deliveryDate: skuReq.sku.deliveryDate,
      product: skuReq.sku.productName,
      quantity: skuReq.crewsNumber,
      contact: skuReq.contact,
      ordInv: skuReq.sku.orderableInventory,
      contract: {
        ...skuReq.contract,
        femalesPercentage: skuReq.femalesPercentage,
        localsPercentage: skuReq.localsPercentage,
      },
      femalesPerc: skuReq.femalesPercentage,
      grade: skuReq.sku.qualityGrade,
      tags: skuReq.tags,
      interviewShifts: skuReq.interviewShifts,
      userNotes: skuReq.userNotes,
      icon: mapProductNameToIcon[skuReq.sku.productName],
    })) || [];
  const totalCost = orderProductCards.reduce(
    (total, product) => total + product.price * product.quantity,
    0,
  );

  const totalQuantity = orderProductCards?.reduce(
    (total, card) => total + card.quantity,
    0,
  );

  const subtotalPrice = orderData?.price ?? totalCost;

  const handleDeleteOrder = useCallback(
    (orderId: number) => {
      deleteOrder(orderId)
        .unwrap()
        .then(() => navigate('/orders'));
    },
    [deleteOrder],
  );

  const toggleCancelModal = () => {
    setIsCancelModalVisible((isCancelModalVisible) => !isCancelModalVisible);
  };

  const handleCancelOrder = useCallback(
    async (refundPolicy: string) => {
      await cancelOrder({ id, refundPolicy });
      toggleCancelModal();
    },
    [deleteOrder, toggleCancelModal, orderId],
  );
  const taxReg = currentTaxReg || listTaxReg || 0;

  const handleConfirmOrder = useCallback(() => {
    confirmOrderByAdmin({ id });
    navigate('/orders');
  }, [id]);

  if (isSkuLoading || !isSuccess) return <div></div>;
  return (
    <div className="summary-order-container">
      <TemplatePage>
        <div className="summary-order">
          <div className="summary-order-header">
            <OrderTitle icon={money} title="Summary Order" />
            {currentFulfillRate !== undefined && (
              <div className="summary-order-rate">{`Fulfillment rate: ${currentFulfillRate}%`}</div>
            )}
            <div className="summary-btns">
              {!orderStatus && (
                <BasicOrderButton label="Cancel" onClick={toggleCancelModal} />
              )}
            </div>
          </div>
          <div className="hr"></div>
          <div className="summary-order-content">
            <div className="summary-client-status">
              <ClientSummary
                contact={listContactName}
                org={listOrgName!}
                branch={listBranchName!}
                taxReg={taxReg}
              />
              {!orderProductCards && (
                <div className="summary-status">
                  Status: <span className="status-data">{orderStatus}</span>
                </div>
              )}
              <div className="pay-order-button">
                {orderStatus === OrderStatuses.PENDING_PAYMENT && (
                  <BasicOrderButton
                    label="Confirm order"
                    isFilled
                    type="button"
                    onClick={handleConfirmOrder}
                  />
                )}
              </div>
              {orderId && (
                <div className="confirm-order-button">
                  <RepeatOrderButton orderId={orderId} />
                </div>
              )}
              <div className="delete-order-button">
                <BasicOrderButton
                  label="Delete Order"
                  isFilled
                  onClick={() => handleDeleteOrder(parseInt(id!))}
                />
              </div>
            </div>
            <div className="summary-total-cards">
              <div className="summary-order-cards">
                {orderProductCards?.map((card) => (
                  <SummaryOrderCard
                    orderData={orderData}
                    orderId={orderId}
                    id={card.id || 0}
                    key={card.id}
                    icon={mapProductNameToIcon[card.product] || defaultIcon}
                    position={card.product}
                    grade={card.grade}
                    price={card.price * card.quantity}
                    deliveryDate={
                      card.deliveryDate
                        ? card.deliveryDate
                        : DEFAULT_DELIVERY_DATE
                    }
                    priceSingle={card.price}
                    finalQuantity={card.quantity}
                    contract={card.contract}
                    femalesPerc={card.femalesPerc}
                    tags={card.tags || []}
                    interviewShifts={card.interviewShifts}
                    contact={orderData.contact}
                    userNotes={card.userNotes}
                    orderStatus={orderStatus}
                  />
                ))}
              </div>
              <TotalQuantity quantity={totalQuantity} />
            </div>
          </div>
          <div className="summary-order-footer">
            <SummaryTotal
              subtotal={subtotalPrice}
              tax={orderData?.tax}
              taxPercentage={orderData.taxPercentage}
              total={orderData.total}
              freePosition={orderData.useFreePositions}
            />
          </div>
        </div>
      </TemplatePage>
      {isCancelModalVisible && (
        <CancelModal
          isCancelModalVisible={isCancelModalVisible}
          toggleModal={toggleCancelModal}
          handleCancelOrder={handleCancelOrder}
        />
      )}
    </div>
  );
};

export default ViewOrder;
