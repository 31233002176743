import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '@/shared/api/api';
import { RootState } from '@/store/store';
import { IConfig } from './config.model';

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: fetchBaseQuery({
    ...baseQueryConfig,
    baseUrl: baseQueryConfig.baseUrl,
    prepareHeaders: async (headers: Headers, { getState }) => {
      // If we have a token set in state, let's assume that we should be passing it.
      const token = await (getState() as RootState).user.user?.getIdToken();
      token && headers.set('Authorization', token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getConfig: builder.query<IConfig, string>({
      query: () => ({
        url: `/config`,
      }),
    }),
  }),
});

export const { useGetConfigQuery } = configApi;
