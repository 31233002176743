import {
  IEmployeesAmountNumbersTypes,
  IndustryEnum,
} from '@/entities/Organization/org.models';
import {
  CountryTypes,
  CitiesTypes,
  LocationTypes,
  WorkplaceTypes,
} from '../../../entities/Branch/branch.models';
import { Input } from '@/features/RegOrgSection/RegOrgSection';
import tax from '@/assets/icons/tax.svg';
import user from '@/assets/icons/user.svg';
import team from '@/assets/icons/team.svg';
import org from '@/assets/icons/org.svg';
import email from '@/assets/icons/email.svg';
import district from '@/assets/icons/pin-map.svg';
import workplace from '@/assets/icons/workplace.svg';
import location from '@/assets/icons/store.svg';
import city from '@/assets/icons/city.svg';
import branch from '@/assets/icons/branch.svg';

export enum InputTypes {
  phone = 'phone',
  autocomplete = 'autocomplete',
  text = 'text',
  select = 'select',
  autocompleteFromEnum = 'autocompleteFromEnum',
  searchAutocomplete = 'searchAutocomplete',
}

export const contactInputs: Input[] = [
  {
    label: 'First name',
    name: 'firstName',
    type: InputTypes.text,
    icon: user,
  },
  {
    label: 'Last name',
    name: 'lastName',
    type: InputTypes.text,
    icon: user,
  },
  {
    label: 'Phone',
    name: 'phone',
    type: InputTypes.phone,
  },
  {
    label: 'Email (optional)',
    name: 'email',
    type: InputTypes.text,
    icon: email,
  },
];

export const orgInputs: Input[] = [
  {
    label: 'Organization name',
    name: 'name',
    type: InputTypes.text,
    icon: org,
  },
  {
    label: 'Industry',
    name: 'industry',
    type: InputTypes.autocompleteFromEnum,
    selectOptions: Object.values(IndustryEnum).map((value) => ({
      label: value,
      value,
    })),
    icon: org,
  },
  {
    label: 'Employees amount',
    name: 'employeesAmount',
    type: InputTypes.autocompleteFromEnum,
    selectOptions: IEmployeesAmountNumbersTypes,
    icon: team,
  },
  {
    label: 'Relationship Manager',
    name: 'relationshipManager',
    type: InputTypes.autocomplete,
    icon: user,
  },
  {
    label: 'Tax Registration (optional)',
    name: 'taxReg',
    type: InputTypes.text,
    icon: tax,
    maxLength: 15,
  },
];

export const branchInputs: Input[] = [
  {
    label: 'Branch',
    name: 'branchName',
    type: InputTypes.text,
    icon: branch,
  },
  {
    label: 'Country',
    name: 'country',
    selectOptions: Object.values(CountryTypes).map((value) => ({
      label: value,
      value,
    })),
    type: InputTypes.autocompleteFromEnum,
    icon: city,
  },
  {
    label: 'City',
    name: 'city',
    selectOptions: Object.values(CitiesTypes).map((value) => ({
      label: value,
      value,
    })),
    type: InputTypes.autocompleteFromEnum,
    icon: city,
  },
  {
    label: 'District',
    name: 'district',
    type: InputTypes.text,
    icon: district,
  },
  {
    label: 'Location type',
    name: 'locationType',
    selectOptions: Object.values(LocationTypes).map((value) => ({
      label: value,
      value,
    })),
    type: InputTypes.autocompleteFromEnum,
    icon: location,
  },
  {
    label: 'Workplace type',
    name: 'workplaceType',
    selectOptions: Object.values(WorkplaceTypes).map((value) => ({
      label: value,
      value,
    })),
    type: InputTypes.autocompleteFromEnum,
    icon: workplace,
  },
  {
    label: 'Google maps Link',
    name: 'googleMapsLink',
    type: InputTypes.text,
    icon: district,
  },
];

export const createContactInputs: Input[] = [
  {
    label: 'Organisation name',
    name: 'organization',
    type: InputTypes.searchAutocomplete,
  },
  {
    label: 'First name',
    name: 'firstName',
    type: InputTypes.text,
    icon: user,
  },
  {
    label: 'Last name',
    name: 'lastName',
    type: InputTypes.text,
    icon: user,
  },
  {
    label: 'Phone',
    name: 'phone',
    type: InputTypes.phone,
  },
  {
    label: 'Email (optional)',
    name: 'email',
    type: InputTypes.text,
    icon: email,
  },
];
