import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import { Button, TableCell } from '@mui/material';
import moment from 'moment';
import star from '../../../assets/icons/star.svg';
import { CustomerCareStatuses } from '../api/customerApi';
import './CustomerCard.scss';

interface PropTypes {
  id: number;
  orgName?: string;
  rewievValue: number;
  createdDate: Date;
  status: CustomerCareStatuses;
}

const getStatusWithSpaces = (status: string) => {
  return status.replace(/([a-z])([A-Z])/g, '$1 $2');
};

const CustomerCard: React.FC<PropTypes> = ({
  id,
  status,
  orgName,
  rewievValue,
  createdDate,
}) => {
  const navigate = useNavigate();

  const finalStatus = useMemo(() => {
    return getStatusWithSpaces(status);
  }, [status]);

  const statusColorMap: { [key: string]: string } = {
    Processed: 'green',
    PendingProcessing: 'crimson',
  };

  const handleDetailsClick = () => {
    navigate(`/customer-details/${id}`);
  };

  const date = moment(createdDate).format('DD/MM/YYYY');
  const time = moment.utc(createdDate).format('HH:mm:ss');

  return (
    <>
      <TableCell style={{ color: statusColorMap[status] }}>
        {finalStatus}
      </TableCell>
      <TableCell>{orgName}</TableCell>
      <TableCell>
        <Rating
          className="card-rating"
          value={rewievValue}
          readOnly
          emptyIcon={<img src={star} alt="star" style={{ display: 'none' }} />}
        />
      </TableCell>
      <TableCell>{date}</TableCell>
      <TableCell>{time}</TableCell>
      <TableCell>
        <Button onClick={handleDetailsClick} variant="contained">
          Details
        </Button>
      </TableCell>
    </>
  );
};

export default CustomerCard;
