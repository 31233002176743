import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Modal, TableCell } from '@mui/material';
import BasicOrderButton from '../../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import {
  RefundItem as CancelationItem,
  RefundPolicy,
  RequestStatuses,
  useApproveCancelationRequestMutation,
  useDeclineCancelationRequestMutation,
} from '../../../../../entities/CancelationRequest/CancelationRequestApi';

import styles from './RefundItem.module.scss';
import { ApproveCancelationRequestPayload } from '../RefundDetails/RefundDetails';

type Props = {
  refundItems: CancelationItem[];
  createdAt: string;
  updatedUp: string;
  id: number;
  refundPolicy: RefundPolicy;
  refundableAmount: number;
  status: RequestStatuses;
  reason: string;
};

export const RefundItem: React.FC<Props> = ({
  createdAt,
  id,
  refundableAmount,
  status,
  refundPolicy,
  reason,
}) => {
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [approveCancelationRequest] = useApproveCancelationRequestMutation();
  const [declineCancelationRequest] = useDeclineCancelationRequestMutation();

  const handleDeclineRequest = async (requestId: number) => {
    declineCancelationRequest({ id: requestId })
      .unwrap()
      .then(() => {
        setIsDeclineModalOpen(false);
      });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
  };

  const handleApproveRequest = useCallback(() => {
    const payload: ApproveCancelationRequestPayload = { id, refundPolicy };
    if (refundPolicy === 'Payout' && selectedFile) {
      payload.file = selectedFile;
    }
    approveCancelationRequest(payload).then(() => {
      setIsApproveModalOpen(false);
    });
  }, [approveCancelationRequest, id, refundPolicy, selectedFile]);

  const handleToggleApproveModal = () => {
    setIsApproveModalOpen(!isApproveModalOpen);
  };

  const handleToggleDeclineModal = () => {
    setIsDeclineModalOpen(!isDeclineModalOpen);
  };

  const navigate = useNavigate();

  const handleDetailsClick = () => {
    navigate(`/refund-details/${id}`, {
      state: {
        id,
        refundPolicy,
        status,
      },
    });
  };

  const date = moment(createdAt).format('DD/MM/YYYY');
  const time = moment.utc(createdAt).format('HH:mm:ss');

  const isFinalizedStatus =
    status === RequestStatuses.APPROVED || status === RequestStatuses.DECLINED;

  return (
    <>
      <TableCell>{id}</TableCell>
      <TableCell>{date}</TableCell>
      <TableCell>{time}</TableCell>
      <TableCell>{refundableAmount} Sar</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>{reason}</TableCell>
      <TableCell>
        {refundPolicy === 'Payout' ? 'Pay out' : 'Hold on balance'}
      </TableCell>
      {!isFinalizedStatus && (
        <TableCell>
          <Button color="error" onClick={handleToggleDeclineModal}>
            Decline
          </Button>
        </TableCell>
      )}
      {!isFinalizedStatus && (
        <TableCell>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleToggleApproveModal}>
            Approve
          </Button>
        </TableCell>
      )}

      <TableCell>
        <Button onClick={handleDetailsClick} variant="contained">
          Details
        </Button>
      </TableCell>

      {isApproveModalOpen && (
        <Modal
          className={styles.modal}
          open={isApproveModalOpen}
          onClose={handleToggleApproveModal}>
          <div className={styles.modalContent}>
            <span className={styles.modalHeader}>
              Are you sure you want to approve this request?
            </span>
            {refundPolicy === 'Payout' && (
              <input
                className={styles.modalInput}
                type="file"
                accept=".pdf"
                required
                onChange={handleFileChange}
              />
            )}
            <div className={styles.modalSummaryBtns}>
              <BasicOrderButton
                onClick={handleApproveRequest}
                label="Approve"
              />
              <BasicOrderButton
                onClick={handleToggleApproveModal}
                label="Cancel"
              />
            </div>
          </div>
        </Modal>
      )}
      {isDeclineModalOpen && (
        <Modal
          className={styles.modal}
          open={isDeclineModalOpen}
          onClose={handleToggleDeclineModal}>
          <div className={styles.modalContent}>
            <span className={styles.modalHeader}>
              Are you sure you want to decline this request?
            </span>
            <div className={styles.modalSummaryBtns}>
              <BasicOrderButton
                onClick={() => {
                  handleDeclineRequest(id);
                }}
                label="Decline"
              />
              <BasicOrderButton
                onClick={handleToggleDeclineModal}
                label="Cancel"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
